import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, FormControl, InputLabel, Select, MenuItem, Button, Box, Typography } from '@mui/material';
import { baseUrl } from '../../../utils/baseURL';

const AddFAQ = () => {
  const [section, setSection] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const handleSubmit = async () => {
    const faqData = {
      section,
      question,
      answer,
    };
    const token = window.localStorage.getItem('access_token');
    try {
      const response = await fetch(`${baseUrl}/faq/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "x-api-key":token
        },
        body: JSON.stringify(faqData),
      });

      if (response.ok) {
        const responseData = await response.json()
        // console.log('FAQ added successfully');
        toast.success(responseData.message || 'FAQ added successfully');

        setSection('');
        setQuestion('');
        setAnswer('');
      } else {
        // Handle error
        console.error('Failed to add FAQ');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        maxWidth: 600,
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
        bgcolor: 'background.paper',
        margin: "20px auto"
      }}
    >
      <ToastContainer />
      <Typography variant="h5" component="h2" gutterBottom>
        Add New FAQ
      </Typography>

      <FormControl fullWidth>
        <InputLabel id="section-label">Section</InputLabel>
        <Select
          labelId="section-label"
          id="section"
          value={section}
          label="Section"
          onChange={(e) => setSection(e.target.value)}
        >
          <MenuItem value="Home">Home</MenuItem>
          <MenuItem value="hs code finder">HS Code Finder</MenuItem>
          <MenuItem value="Duty Calculation">Duty Calculator</MenuItem>
          <MenuItem value="duty saver pro">DutySaver Pro</MenuItem>
        </Select>
      </FormControl>

      <TextField
        label="Question"
        variant="outlined"
        fullWidth
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
      />

      <TextField
        label="Answer"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
      />

      <Button variant="contained" color="primary" onClick={handleSubmit}
        sx={{
          backgroundColor: "var(--intgrow-bg)",
          "&:hover": {
            backgroundColor: "var(--intgrow-bg)"
          }
        }}>
        Add FAQ
      </Button>
    </Box>
  );
};

export default AddFAQ;
