import React, { useEffect,useRef, useState } from 'react';
import { withUserAuthGuard } from '../../withGaurd/withUserGaurd';
import { Box, Typography, useTheme,Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import styles from '../pages/Pricing.module.scss'
import CurrentPlan from '../../adminPanel/CurrentPlan/CurrentPlan';
import CurrentPlanCard from '../../adminPanel/CurrentPlan/CurrentPlanCard';
import { baseUrl } from '../../utils/baseURL';


const CurrentPlanType = withUserAuthGuard(() => {
  const theme = useTheme();
  const pricingRef = useRef(null);
  const [userOrderHistory,setUserOrderHistory]=useState()
  const scrollToPricing = () => {
    if (pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const fetchUserOrderList = async () => {
    try {
        const token = window.localStorage.getItem("access_token")
        const response = await fetch(`${baseUrl}/order/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "x-api-key":token
            },
        })
        const data = await response.json();
        if (!response.ok) {
            // console.log('Error in fetch search duty list');
            throw new Error('Error in fetch search duty list');
        }
        setUserOrderHistory(data?.result[0])
    } catch (error) {
        console.error('An error occurred:', error);
    }
}

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(()=>{
    fetchUserOrderList()
  },)


  return (
    <>
      <Helmet>
        <title>Current Plan | IntGrow Corp.</title>
        <meta property="og:title" content="Dashboard" />
        <meta property="og:url" content="https://alpha.intgrow.co/dashboard" />
        <meta property="og:type" content="information" />
        <meta property="og:description" content="what we serve" />
        <meta name="keywords" content="HS Code, duty calculator, duty saver " />
      </Helmet>
      <Box m="20px">
        {/* <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" sx={{ marginBottom: "10px", fontWeight: "700" }}>
            My Current Plan
          </Typography>
        </Box>
        <section>
          <CurrentPlanCard currentPlan="Premium Plan" onRenewClick={scrollToPricing} userOrderHistory={userOrderHistory}/>
        </section> */}
        <section ref={pricingRef}>
          {/* <CurrentPlan/> */}
          <Grid className={styles.pricing}>
            <stripe-pricing-table pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
              publishable-key={process.env.REACT_APP_PUBLISHIABL_KEY}>
            </stripe-pricing-table>
          </Grid>
        </section>


      </Box>
    </>
  );
});

export default CurrentPlanType;
