import React, { useState, useEffect } from 'react';
import {
    Box, useTheme, Typography, Table, TableBody, TableCell, TableHead, TableRow,
    Tooltip, Grid
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import styled from '@emotion/styled';

import { tokens } from '../../theme'
import { roundToNearestInteger, currencyConvert, igToLocale, formatPrice, formattedDate } from '../../utils/utils';
import '../DutyCalculator/dutycalculator.style.css'
import { baseUrl } from '../../utils/baseURL';
import FootNotesTab from '../DutyCalculator/FootNotesTab';
import FormatedCalcValue from '../DutyCalculator/FormateCalcValue';

const getNotesLabelsUrl = `${baseUrl}/dutyCalculator/getNotesLabels?import_country=`;

const BoldCell = styled(TableCell)`
 font-size: 16px;
  font-weight: 500;
  color:#000
`;

const BoldGrayCell = styled(TableCell)`
 font-size: 16px;
  font-weight: 700;
  color: #797d8c
`;

const LightLeftBorderCell = styled(TableCell)`
  font-size: 16px;
  font-weight: 400;
  border-left: 1px solid #dadada;
  color:#797d8c
`;

const LighCell = styled(TableCell)`
  font-size: 16px;
  font-weight: 400;
  color:#797d8c
`;

const DutyCalCalculationTable = ({ getDutyResponse }) => {
    const theme = useTheme()
    const mode = theme.palette.mode
    const colors = tokens(mode)
    const [totalDuty, setTotalDuty] = useState(0);
    const [footnoteData, setFootnoteData] = useState("");
    const [notesLabelData, setNotesLabelData] = useState("");

    const cyn = getDutyResponse?.cyn
    let impCurrency = getDutyResponse.imp_cyn
    let getdutyTotal = roundToNearestInteger(getDutyResponse.total + getDutyResponse.CIFVALUE);



    const fetchFootNotesData = async () => {
        const token = window.localStorage.getItem("access_token")
        const _imp = (localStorage.getItem('imp') || getDutyResponse.import_country).toLowerCase();
        const _exp = (localStorage.getItem('imp') || getDutyResponse.export_country).toLowerCase();

        const footnoteRes = await axios.get(`${baseUrl}/footnote?hs=${getDutyResponse?.hs}&imp=${_imp}&exp=${_exp}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }

        });

        setFootnoteData(footnoteRes?.data);
    };

    useEffect(() => {

        const fetchNotesLabelsData = async () => {
            const _imp = (localStorage.getItem('imp') || getDutyResponse.import_country).toLowerCase();
            const notesLabelsRes = await axios.get(`${getNotesLabelsUrl}${_imp}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }

            });
            const notesData = {};
            notesLabelsRes.data.forEach((n) => {
                notesData[n.duty_code] = n.duty_short_name;
            });
            const footnotes = Object.keys(getDutyResponse).reduce((acc, key) => {
                if (key.endsWith('_f')) {
                    let prefix = (key.split('_f')[0] + "").replace(new RegExp(getDutyResponse.import_country, 'img'), '').replace(new RegExp(getDutyResponse.export_country, 'img'), '').replace(/^_+|_+$/g, '');
                    let value = getDutyResponse[key].trim();
                    if (value) {

                        acc += `<li class="footnotes-list"><strong>${notesData[prefix]}</strong><br/>${value}</li>`;
                    }
                }
                return acc;
            }, '');

            setNotesLabelData(footnotes);
        };

        fetchNotesLabelsData();
        fetchFootNotesData()

        let dutyTotal = roundToNearestInteger(getDutyResponse.total + getDutyResponse.CIFVALUE);
        setTotalDuty(currencyConvert(dutyTotal, getDutyResponse.cyn_rate));

    }, []);



    return (
        <Box sx={{
            margin: "25px", padding: "10px",
        }}>
            <div className="tnc-note-3">
                This total landed cost calculation is applicable as of {formattedDate()}. Foreign exchange rates are revised in accordance with notifications from the importing country.
            </div>

            <Grid container spacing={2} >
                <Grid item sm={12} md={12} my={4}>
                    <Box sx={{
                        backgroundColor: colors.primary[400],
                        borderRadius: "7px"
                    }}>
                        <Typography className='BreakDownDutyTax'>Breakdown of Duties and Taxes</Typography>
                        <Table>
                            <TableHead>
                                <TableRow
                                    sx={{
                                        backgroundColor: colors.primary[400]
                                    }}>
                                    <BoldCell>Duty Details</BoldCell>
                                    <BoldCell sx={{ borderLeft: "1px solid #888" }}>Duty Rate <br />(in %)</BoldCell>
                                    <BoldCell sx={{ borderLeft: "1px solid #888" }}>Duty Amount <br /> (in {(getDutyResponse.imp_cyn || "").toUpperCase()})</BoldCell>
                                    {getDutyResponse.imp_cyn !== getDutyResponse.cyn && (
                                        <BoldCell sx={{ borderLeft: "1px solid #888" }}>Duty Amount <br /> (in {getDutyResponse.cyn})</BoldCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getDutyResponse.dutyDetails.map((duty, index) => {
                                    const dutyKey = Object.keys(duty).find(key => key.endsWith('_dd'));
                                    const prefix = dutyKey ? dutyKey.split('_dd')[0] : '';
                                    if (!prefix) return null;

                                    return (
                                        <TableRow key={index} className={prefix && duty[`${prefix}_d`]}>
                                            <LighCell >{duty[`${prefix}_dd`]}
                                                <Tooltip title={duty[`${prefix}_define`]}>
                                                    <InfoIcon />
                                                </Tooltip>
                                            </LighCell>
                                            <LightLeftBorderCell >{duty[`${prefix}_d`]}</LightLeftBorderCell>
                                            <LightLeftBorderCell >{duty[`${prefix}_cl`] && roundToNearestInteger(duty[`${prefix}_cl`])}</LightLeftBorderCell>
                                            {getDutyResponse.imp_cyn !== getDutyResponse.cyn && (
                                                <LightLeftBorderCell >{duty[`${prefix}_cl`] && currencyConvert(duty[`${prefix}_cl`], getDutyResponse.cyn_rate)}</LightLeftBorderCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                                <TableRow>
                                    <LighCell colSpan={2}>Total payable duties and taxes</LighCell>
                                    <BoldGrayCell>
                                        <FormatedCalcValue value={igToLocale(roundToNearestInteger(getDutyResponse.total))}
                                            cssStyle={{
                                                fontWeight: '700',
                                                fontSize: '16px',
                                                fontSize2: "12px",
                                                color: colors.primaryColor[200]
                                            }} />
                                        {/* {roundToNearestInteger(getDutyResponse.total)} */}
                                    </BoldGrayCell>
                                    {getDutyResponse.imp_cyn !== getDutyResponse.cyn && (
                                        <BoldGrayCell >
                                            <FormatedCalcValue value={igToLocale(currencyConvert(getDutyResponse.total, getDutyResponse.cyn_rate))}
                                                cssStyle={{
                                                    fontWeight: '700',
                                                    fontSize: '16px',
                                                    fontSize2: "12px",
                                                    color: colors.primaryColor[200]
                                                }} />
                                            {/* {currencyConvert(getDutyResponse.total, getDutyResponse.cyn_rate)} */}
                                        </BoldGrayCell>
                                    )}

                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>


                    <Box sx={{
                        backgroundColor: colors.primary[400],
                        margin: "20px 0px",
                        padding: "25px 10px",
                        borderRadius: "7px"
                    }}>
                        <Box sx={{ display: "flex",alignItems:"center", gap: "5px" }} className="landed-cost-main">
                            Landed Cost:
                                <FormatedCalcValue value={igToLocale(formatPrice(getdutyTotal), impCurrency, impCurrency)}
                                    cssStyle={{
                                        fontWeight: '700',
                                        fontSize: '16px',
                                        fontSize2: "12px",
                                        color: colors.primaryColor[200]
                                    }} />
                            {impCurrency !== getDutyResponse.cyn && (
                                 <FormatedCalcValue value={igToLocale(totalDuty, getDutyResponse.cyn, getDutyResponse.cyn)}
                                 cssStyle={{
                                     fontWeight: '700',
                                     fontSize: '16px',
                                     fontSize2: "12px",
                                     color: colors.primaryColor[200]
                                 }} />
                                // <Typography className="landed-cost-main-2">({totalDuty} {getDutyResponse.cyn})</Typography>
                            )}
                        </Box>
                        <Typography className="landed-cost-sub" >Landed Cost = Assessable value + Total Duty</Typography>
                        <Typography className="landed-cost-body-1">*Excluding destination freight, destination charges and intermediaries margin (importer, wholesaler, etc.)</Typography>
                    </Box>

                    {notesLabelData && (
                        <Box sx={{
                            backgroundColor: colors.primary[400],
                            margin: "20px 0px",
                            padding: "10px",
                            borderRadius: "7px"
                        }}>
                            <Typography variant="h6" className='footnotes'>Note:</Typography>
                            <ul dangerouslySetInnerHTML={{ __html: notesLabelData }} className='order' />
                        </Box>
                    )}

                    {footnoteData &&
                        <FootNotesTab footNoteData={footnoteData} />
                    }
                </Grid>
            </Grid>

        </Box>
    );
};

export default DutyCalCalculationTable;
