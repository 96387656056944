import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { baseUrl } from '../utils/baseURL';
import { useNavigate } from 'react-router-dom';
import { fetchCountry } from '../utils/Api';
const LoginWithGoogle = () => {
    const navigate = useNavigate()



    const login = useGoogleLogin({
        onSuccess: async tokenResponse => {
            try {
                window.localStorage.setItem("Provider", "GOOGLE");
                const credential = tokenResponse.access_token;
                const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${credential}`
                    }
                });

                const data = await response.json();
                // console.log("User Data:", data);

                if (response.ok) {
                    try {
                        let provider = window.localStorage.getItem("Provider") || "GOOGLE";
                        const userData = {
                            email: data.email,
                            first_name: data.given_name,
                            last_name: data.family_name,
                            provider,
                            photo_url: data?.picture
                        };

                        const response = await fetch(`${baseUrl}/auth/loginIn/withSocialMedia2`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(userData),
                        });

                        const responseData = await response.json();
                        if (!response.ok) {
                            console.error("Error in login", responseData);
                        }
                        // console.log("responseData", responseData)
                        window.localStorage.setItem("isAuthenticated", true)
                        window.localStorage.setItem('access_token', responseData.result.token);
                        window.localStorage.setItem("userInfo", JSON.stringify(responseData?.result))
                        fetchCountry()
                        const continueUrl = localStorage.getItem("continueUrl");
                        if (continueUrl) {
                          localStorage.removeItem("continueUrl");
                          navigate(continueUrl);
                        } else {
                          navigate("/dashboard");
                        }

                    } catch (error) {
                        console.error("Error in login", error);
                    }
                } else {
                    console.error('Error fetching user info:', data);
                }
            } catch (error) {
                console.error('Network error:', error);
            }
        },
        onError: error => {
            console.error('Login Failed:', error);
        },
    });

    return (
        <button className="google-login-btn" onClick={() => login()}>
            <img src="/assets/logos/free-google.png" alt="google-login" />
        </button>
    );
};

export default LoginWithGoogle;
