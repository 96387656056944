import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import './select.style.css';
import { baseUrl } from '../../utils/baseURL';

const SelectCurrency = ({ placeholder, setSelectedCurrency, selectedCurrency, imp ,inputPadding}) => {
    const [countries, setCountries] = useState([]);
    const [isSearchable, setIsSearchable] = useState(false);
    const selectRef = useRef(null);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                // console.log(imp)
                if (!imp) {
                    return
                }
                const response = await axios.get(`${baseUrl}/country/currency?imp=${imp}`,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                // console.log("response", response);
                const countryData = response?.data?.map(country => ({
                    country: country?.country,
                    currency: country?.currency,
                    unit: country?.unit,
                    value: country?.value
                }));
                setCountries(countryData);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, [imp]);

    const handleChange = (selectedOption) => {
        setSelectedCurrency(selectedOption);
        // console.log("selectedOption", selectedOption);
        if (selectRef.current) {
            selectRef.current.blur();
        }
    };

    const customSingleValue = ({ data }) => (
        <div className="custom-single-value">
            {data.country} (<span> {data.currency}</span>)
        </div>
    );

    const customOption = (props) => {
        const { data, innerRef, innerProps, isFocused, isSelected } = props;
        return (
            <div
                ref={innerRef}
                {...innerProps}
                className={`custom-option ${isFocused ? 'focused' : ''} ${isSelected ? 'selected' : ''}`}
            >
                {data.country} (<span> {data.currency}</span>)
            </div>
        );
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#e6f3ff' : state.isSelected ? '#cce6ff' : 'white',
            color: state.isFocused || state.isSelected ? '#000' : '#333',
            padding: 10,
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            color: '#000',
        }),
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            borderColor: '#ccc',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#888',
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'center',
            padding: inputPadding || "10px 5px"
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'white',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#888',
        }),
    };

    return (
        <div className='select-country-container'>
            <Select
                ref={selectRef}
                options={countries}
                components={{ SingleValue: customSingleValue, Option: customOption }}
                styles={customStyles}
                isSearchable={isSearchable}
                onChange={handleChange}
                onFocus={() => setIsSearchable(true)}
                onBlur={() => setIsSearchable(false)}
                value={selectedCurrency}
                placeholder={placeholder}
                getOptionLabel={(e) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        (<span>{e.currency}</span>)-
                        {e.country}
                    </div>
                )}
                getOptionValue={(e) => e.country}
                menuPlacement="auto"
                menuPosition="fixed"
            />
        </div>
    );
};

export default SelectCurrency;
