import React, { useState, useEffect } from 'react';
import '../outletform/supportview.css';
import { formattedDate } from '../../../../../utils/utils';
import { Modal, Box, Button, Typography, Select, MenuItem } from '@mui/material';
import { baseUrl } from '../../../../../utils/baseURL';

const SupportView = () => {
  const [supportsList, setSupportsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const token = window.localStorage.getItem('access_token');
      try {
        const response = await fetch((process.env.REACT_APP_API_KEY || "https://intgrow.co")+'/api/v1/supports', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': token
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSupportsList(data?.supportRequests);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleOpenModal = (ticket) => {
    setSelectedTicket(ticket);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedTicket(null);
  };

  const handleStatusChange = async (newStatus) => {
    if (!selectedTicket) return;

    try {
      const token = window.localStorage.getItem('access_token');
      const response = await fetch(`${baseUrl}/change/support-status/${selectedTicket.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': token
        },
        body: JSON.stringify({ status: newStatus })
      });

      if (response.ok) {
        setSupportsList((prev) =>
          prev.map((item) => (item.id === selectedTicket.id ? { ...item, ticket_status: newStatus } : item))
        );
        handleCloseModal();
      } else {
        console.error('Failed to update status');
      }
    } catch (error) {
      console.error('Failed to update status', error);
    }
  };

  const handleChangePage = (newPage) => setCurrentPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const currentItems = supportsList.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="view-section-container">
      <h1>Tickets</h1>
      <div className="support-container">
        <div className="support-table-wrapper">
          <table className="support-table">
            <thead>
              <tr>
                <th>User</th>
                <th>Issue</th>
                <th>Raised Date</th>
                <th>Category</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={item.id} style={{ borderBottom: '1px solid #dadada' }}>
                  {/* <td>{(currentPage - 1) * rowsPerPage + index + 1}</td> */}
                  <td>{item.username}</td>
                  <td>{item.message}</td>
                  <td>{formattedDate(item.createdAt)}</td>
                  <td>{item.category}</td>
                  <td>{item.ticket_status}</td>
                  <td>
                    <button className="view-button" onClick={() => handleOpenModal(item)}>
                      View Ticket
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="pagination">
        <div className="rows-per-page">
          <span>
            Rows per page:
            <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
            </select>
          </span>
        </div>
        <div className="pagination-buttons">
          <button
            className="previous-button"
            onClick={() => handleChangePage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className="next-button"
            onClick={() => handleChangePage(currentPage + 1)}
            disabled={currentPage * rowsPerPage >= supportsList.length}
          >
            Next
          </button>
        </div>
      </div>
      <Modal open={openModal} onClose={handleCloseModal}
        sx={{
          p: 4, backgroundColor:"#fff", borderRadius: 2, maxWidth: 800,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          height: "450px"
        }}>
        <Box className="modal-box">
          <Typography variant="h6" component="h2">
            Ticket Details
          </Typography>
          {selectedTicket && (
            <div>
              <p><strong>User Name</strong> {selectedTicket.username}</p>
              <p><strong>Email</strong> {selectedTicket.email}</p>
              <p><strong>Issue:</strong> {selectedTicket.message}</p>
              <p><strong>Raised Date:</strong> {formattedDate(selectedTicket.createdAt)}</p>
              <p><strong>Category:</strong> {selectedTicket.category}</p>
              <p><strong>Status:</strong> {selectedTicket.ticket_status}</p>
              <Select
                value={selectedTicket.ticket_status}
                onChange={(e) => handleStatusChange(e.target.value)}
                fullWidth
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="In Progress">In Progress</MenuItem>
                <MenuItem value="Resolved">Resolved</MenuItem>
              </Select>
              <Button variant="contained" color="primary" onClick={handleCloseModal} style={{ marginTop: '20px' }}>
                Close
              </Button>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default SupportView;
