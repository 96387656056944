import React, {useState, useEffect} from 'react';
import {Box, Typography, Grid, TextField, Button, MenuItem} from '@mui/material';
import './dutycalculator.style.css'

const DutyCalculationShipingDetails = ({setIncoTerm, incoTerm, productValue, setPayloadData, payloadData}) => {
    const {originCharges, originFreight, exwIntFreight, exwInsuranceCharges, cifIntFreight, cifInsuranceCharges, fobIntFreight, fobInsuranceCharges, mode} = payloadData;

    const handleIncoTermChange = (event) => {
        setIncoTerm(event.target.value);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        const numericValue = name === 'mode' ? value : Number(value);
        // console.log("name", name, value);
        setPayloadData(prev => ({
            ...prev,
            [name]: numericValue
        }));
    };

    const renderFields = () => {
        switch (incoTerm) {
            case 'EXW':
                return (<>
                    <Grid item xs={12} md={4}>
                        <Typography className="duty-calc-label">Origin Charges</Typography>
                        <TextField placeholder="Numbers Only" type="number" variant="outlined" required fullWidth name="originCharges" value={originCharges} onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography className="duty-calc-label">Origin Freight</Typography>
                        <TextField placeholder="Numbers Only" type="number" variant="outlined" required fullWidth name="originFreight" value={originFreight} onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography className="duty-calc-label">International Freight</Typography>
                        <TextField placeholder="Numbers Only" type="number" variant="outlined" required fullWidth name="exwIntFreight" value={exwIntFreight} onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography className="duty-calc-label">Insurance Charges</Typography>
                        <TextField placeholder="Numbers Only" type="number" variant="outlined" required fullWidth name="exwInsuranceCharges" value={exwInsuranceCharges} onChange={handleChange}/>
                    </Grid>
                </>);
            case 'CIF':
                return (<>
                    <Grid item xs={12} md={4}>
                        <Typography className="duty-calc-label">International Freight</Typography>
                        <TextField placeholder="Numbers Only" type="number" variant="outlined" required fullWidth name="cifIntFreight" value={cifIntFreight} onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography className="duty-calc-label">Insurance Charges</Typography>
                        <TextField placeholder="Numbers Only" type="number" variant="outlined" required fullWidth name="cifInsuranceCharges" value={cifInsuranceCharges} onChange={handleChange}/>
                    </Grid>
                </>);
            case 'FOB':
                return (<>
                    <Grid item xs={12} md={4}>
                        <Typography className="duty-calc-label">International Freight</Typography>
                        <TextField placeholder="Numbers Only" type="number" variant="outlined" required fullWidth name="fobIntFreight" value={fobIntFreight} onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography className="duty-calc-label">Insurance Charges</Typography>
                        <TextField placeholder="Numbers Only" type="number" variant="outlined" required fullWidth name="fobInsuranceCharges" value={fobInsuranceCharges} onChange={handleChange}/>
                    </Grid>
                </>);
            default:
                return null;
        }
    };

    return (<>
        <Grid item xs={12} md={4}>
            <Typography className="duty-calc-label">INCO Term</Typography>
            <TextField select variant="outlined" value={incoTerm} onChange={handleIncoTermChange} required fullWidth InputLabelProps={{shrink: false}} SelectProps={{displayEmpty: true}}>
                <MenuItem value="" disabled><em style={{fontStyle: "normal", color: "#888"}}>Select Incoterm</em></MenuItem>
                <MenuItem value="EXW">EXW</MenuItem>
                <MenuItem value="CIF">CIF</MenuItem>
                <MenuItem value="FOB">FOB</MenuItem>
            </TextField>
        </Grid>
        {renderFields()}
        <Grid item xs={12} md={4}>
            <Typography className="duty-calc-label">Mode of Transport</Typography>
            <TextField select variant="outlined" value={mode} onChange={handleChange} name="mode" required fullWidth InputLabelProps={{shrink: false}} SelectProps={{displayEmpty: true}}>
                <MenuItem value="" disabled><em style={{fontStyle: "normal", color: "#888"}}>Select Transport Mode</em></MenuItem>
                <MenuItem value="Land">Land</MenuItem>
                <MenuItem value="Sea">Sea</MenuItem>
                <MenuItem value="Air">Air</MenuItem>
            </TextField>
        </Grid>
    </>);
};

export default DutyCalculationShipingDetails;
