import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    Grid, useTheme, Box, Typography, Container, TextField, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Switch, Modal
} from '@mui/material';
import Button from '@mui/material/Button';
import CountrySelect from '../Select/SelectCountry';
import { HsCodeContext } from '../../context/HSCodeContext';
import { tokens } from '../../theme'
import BrowseHsCode2 from '../DutyCalculator/BrowseHsCode2';
import SelectCurrency from '../Select/SelectCurrency';
import DutyCalculationShipingDetails from '../DutyCalculator/ShipingDetails';
import DisplaySaveDuty from '../DutyCalculator/DutyCalcDetailsTable';
import { baseUrl } from '../../utils/baseURL';
import '../DutyCalculator/dutycalculator.style.css';
import DutySaverStripe from './DutySaverStripe';
import { currencyConvert } from '../../utils/utils';
import CustomModal from '../DutyCalculator/CustomModel';

const formatValue = (value = 4000.98) => {
    const [integerPart, decimalPart] = value.toString().split('.');
    const formateNumber = new Intl.NumberFormat('en-US').format(integerPart)

    return (
        <>
            <span style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{formateNumber}</span>
            {decimalPart && (
                <span style={{ fontWeight: 'normal', fontSize: '0.9rem' }}>{'.' + decimalPart.slice(0, 3).padEnd(3, '0')}</span>
            )}
        </>
    );
}

function debounce(fn, delay) {
    let timer;
    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn(...args);
        }, delay);
    };
}

function getCurrencyValue(currencyResponse) {
    const currency = currencyResponse.find(c => c.unit == c.value);
    if (currency) {
        // console.log("imp_cyn", currency.currency);
        return currency.currency;
    }
    return null;
}

const DutySaverPro = () => {
    const theme = useTheme()
    const mode = theme.palette.mode
    const colors = tokens(mode)
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [incoTerm, setIncoTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [saveUpto,setSaveUpto]=useState()

    // const { formData, otherData } = useSelector((state) => state.dutyCalculator);

    const [currentAttr, setCurrentAttr] = useState(null);
    const [currentSelectedValue, setCurrentSelectedValue] = useState('');

    const { hsCodeDetails, setHsCodeDetails } = useContext(HsCodeContext);
    const [importingCountry, setImportingCountry] = useState("");
    const [exportingCountry, setExportingCountry] = useState("");
    const [productValue, setProductValue] = useState("")
    const [selectedCurrency, setSelectedCurrency] = useState("null");
    const [urlInputResponse, setUrlInputResponse] = useState('');
    const [tradeFlow, setTradeFlow] = useState('select');

    const [currencyResponse, setCurrencyResponse] = useState([])
    const [saverProResponse, setSaverProResponse] = useState([])
    const [showDutyCalResult, setShowDutyCalcResult] = useState(true)
    const [showDutyCalcForm, setShowDutyCalcForm] = useState(false)
    const [showDutySaverProResult, setshowDutySaverProResult] = useState(false)
    const [openConfirmationModel, setOpenConfirmationModel] = useState(false);
    const [ftaPoint, setFtaPoint] = useState(null);
    const [openDutyPointDeductionModel, setOpenDutyPointDeductionModel] = useState(false)
    const [openErrorModel, setOpenErrorModel] = useState(false)
    const [tradflowModelModel, setTradflowModelModel] = useState(false)
    const [fields, setFields] = useState([]);


    const [payloadData, setPayloadData] = useState({
        cifValue: "",
        fobValue: "",
        exwValue: "",
        mode: "",
        originCharges: "",
        exwIntFreight: "",
        originFreight: "",
        exwInsuranceCharges: "",
        cifIntFreight: "",
        cifInsuranceCharges: "",
        fobIntFreight: "",
        fobInsuranceCharges: ""
    })

    const cyn = selectedCurrency?.currency;
    const cynRate = selectedCurrency?.value;

  
    const InputData = {
        selectedCurrency,
        importingCountry,
        exportingCountry,
        productValue
    }


    const handleDynamicInputChange = (attr, value) => {
        const label = attr?.values?.find(item => item.value === value) || { label: '', value: '' };
        setFields(prevFields => {
            const fieldIndex = prevFields.findIndex(item => item.key === attr.field);
            if (fieldIndex !== -1) {
                const updatedFields = [...prevFields];
                updatedFields[fieldIndex] = {
                    key: attr.field,
                    lbl: attr.label,
                    selected: true,
                    txt: label.label,
                    val: value
                };
                return updatedFields;
            } else {
                return [
                    ...prevFields,
                    {
                        key: attr.field,
                        lbl: attr.label,
                        selected: true,
                        txt: label.label,
                        val: value
                    }
                ];
            }
        });
    };

    const handleSelectChange = (attr, value) => {
        const label = attr?.values?.find(item => item.value === value) || { label: '', value: '' };
        setFields(prevFields => {
            const fieldIndex = prevFields.findIndex(item => item.key === attr.field);
            if (fieldIndex !== -1) {
                const updatedFields = [...prevFields];
                updatedFields[fieldIndex] = {
                    key: attr.field,
                    lbl: attr.label,
                    selected: true,
                    txt: label.label,
                    val: value
                };
                return updatedFields;
            } else {
                return [
                    ...prevFields,
                    {
                        key: attr.field,
                        lbl: attr.label,
                        selected: true,
                        txt: label.label,
                        val: value
                    }
                ];
            }
        });
    };


    const openDynamicModal = (attr, selectedValue) => {
        // const selectedLabel = attr.values.find(v => v.value === selectedValue)?.label || '';
        setCurrentAttr(attr);
        setCurrentSelectedValue(selectedValue);
    };

    const closeDynamicModal = () => {
        setCurrentAttr(null);
        setCurrentSelectedValue('');
    };

    const toParseInt = (jsonString = {} || '0') => {
        //iterate through the object and parse int
        if (jsonString && typeof jsonString === 'object') {
            for (let key in jsonString) {
                if (jsonString.hasOwnProperty(key) && jsonString[key] && !isNaN(jsonString[key])) {
                    jsonString[key] = parseFloat(jsonString[key]);
                }
            }
        } else if (jsonString && typeof jsonString === 'string' && !isNaN(jsonString)) {
            jsonString = parseFloat(jsonString);
        }
        return jsonString;
    }


    // useEffect(() => {
    //     if (formData && otherData) {
    //         const { exportingCountry, selectedCurrency, productValue } = otherData;
    //         setSelectedCurrency(selectedCurrency);
    //         setExportingCountry(exportingCountry);
    //         setProductValue(productValue);
    //         setSaverProResponse(formData);
    //         setshowDutySaverProResult(true);
    //         setShowDutyCalcResult(false);
    //     }
    // }, [formData, otherData]);

    useEffect(() => {
        if (hsCodeDetails.importingCountry) {
            setImportingCountry(hsCodeDetails.importingCountry)
            setExportingCountry(hsCodeDetails.exportingCountry)
            // setHashOrName(hsCodeDetails.hsCode)
            // console.log("previous sate", hsCodeDetails)
        }
    }, [hsCodeDetails]);



    useEffect(() => {
        if (productValue || incoTerm || selectedCurrency) {
            setProductValue("")
            setHsCodeDetails({
                importingCountry: '',
                exportingCountry: '',
                hsCode: '',
            });
            setExportingCountry("")
            setSelectedCurrency("")
            setUrlInputResponse("")
            setPayloadData("")
            setTradeFlow("")
            setIncoTerm("")
            setPayloadData({
                cifValue: "",
                fobValue: "",
                exwValue: "",
                mode: "",
                originCharges: "",
                exwIntFreight: "",
                originFreight: "",
                exwInsuranceCharges: "",
                cifIntFreight: "",
                cifInsuranceCharges: "",
                fobIntFreight: "",
                fobInsuranceCharges: ""
            });
            setFields([]);
        }


    }, [importingCountry.value])


    const getIGSTDesc = async (hsCode, importingCountry) => {
        try {
            if (!hsCode || !importingCountry?.value) {
                return;
            }
            const response = await fetch(`${baseUrl}/getUserInput?hs=${hsCode}&imp=${importingCountry?.value}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            if (!response.ok) {
                // console.log('Error in fetch igst description');
                throw new Error('Error in fetch igst description');
            }
            setUrlInputResponse(data);
            return data;
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const getCurrency = async () => {
        try {
            // console.log("importingCountry?.value", importingCountry?.value)
            if (!importingCountry?.value) {
                return
            }
            const response = await fetch(`${baseUrl}/country/currency?imp=${importingCountry?.value}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            );
            const data = await response.json();
            // console.log("data", data)
            if (!response.ok) {
                // console.log('Error in fetch igst description');
                throw new Error('Error in fetch igst description');
            }

            setCurrencyResponse(data)
            return data;
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    const debouncedFetch = useCallback(debounce((hsCode, importingCountry) => {
        getIGSTDesc(hsCode, importingCountry);
    }, 1000), []);

    useEffect(() => {
        if (hsCodeDetails?.hsCode && importingCountry?.value) {
            debouncedFetch(hsCodeDetails.hsCode, importingCountry);
        }
    }, [hsCodeDetails?.hsCode, importingCountry?.value]);

    useEffect(() => {
        getCurrency()
    }, [importingCountry?.value])

    const handleDutyCalculation = async () => {
        const cyn = selectedCurrency.currency;
        const cynRate = toParseInt(selectedCurrency.value);
        const impRate = 1;
        const transformedFields = fields.reduce((acc, field) => {
            acc[field.key] = field.val;
            return acc;
        }, {});


        // console.log("transformedFields", transformedFields)

        let newCifValue = 0;
        let newFobValue = 0;
        let newExwValue = 0;


        let originCharges = parseFloat(payloadData.originCharges) || 0,
            exwInsuranceCharges = parseFloat(payloadData.exwInsuranceCharges) || 0,
            cifInsuranceCharges = parseFloat(payloadData.cifInsuranceCharges) || 0,
            fobInsuranceCharges = parseFloat(payloadData.fobInsuranceCharges) || 0,

            exwIntFreight = parseFloat(payloadData.exwIntFreight) || 0,
            cifIntFreight = parseFloat(payloadData.cifIntFreight) || 0,
            fobIntFreight = parseFloat(payloadData.fobIntFreight) || 0,

            originFreight = parseFloat(payloadData.originFreight) || 0,
            productVal = parseFloat(payloadData.productValue) || 0;


        switch (incoTerm) {
            case 'EXW':
                newExwValue = productValue;
                newFobValue = newExwValue + originCharges + originFreight;
                newCifValue = newFobValue + exwIntFreight + exwInsuranceCharges;
                break;
            case 'CIF':
                newCifValue = productValue;
                newFobValue = newCifValue - (cifIntFreight + cifInsuranceCharges);
                newExwValue = newCifValue - (originCharges + originFreight + cifIntFreight + cifInsuranceCharges);
                break;
            case 'FOB':
                newFobValue = productValue;
                newCifValue = newFobValue + (fobIntFreight + fobInsuranceCharges);
                newExwValue = newFobValue - (fobIntFreight + fobInsuranceCharges);
                break;
            default:
                break;
        }

        const data = {
            hscode: hsCodeDetails?.hsCode,
            import_country: importingCountry?.value,
            export_country: exportingCountry?.value,
            CIFVALUE: (newCifValue * cynRate),
            CIF: Number(productValue),
            FOBVALUE: (newFobValue * cynRate),
            cyn: cyn,
            cyn_rate: cynRate,
            extras: fields,
            igst_product: "type1",
            imp_cyn: getCurrencyValue(currencyResponse).toLowerCase(),
            info: {
                CIFVALUE: (newCifValue * cynRate),
                CIF: (newCifValue * cynRate),
                FOBVALUE: (newFobValue * cynRate),
                cifInsuranceCharges: cifInsuranceCharges || 0,
                cifIntFreight: cifIntFreight || 0,
                tradeFlow: tradeFlow || "Importer",
                import_country: (importingCountry?.value || "").toUpperCase(),
                export_country: (exportingCountry?.value || "").toUpperCase(),
                hscode: Number(hsCodeDetails?.hsCode),
                findHSN: "",
                cyn: selectedCurrency?.Country,
                exwInsuranceCharges: exwInsuranceCharges || 0,
                exwIntFreight: exwIntFreight || 0,
                fobInsuranceCharges: fobInsuranceCharges || 0,
                fobIntFreight: fobIntFreight || 0,
                inco_term: incoTerm || "CIF",
                mode: payloadData?.mode || "Sea",
                originCharges: originCharges || 0,
                originFreight: originFreight || 0,
                "popup-confirm-duty-calc": "",
                productValue: Number(productValue),
                ...transformedFields

            },
            mode: payloadData?.mode || "Sea",
            ...transformedFields
        };


        try {
            const url =baseUrl
            const token = window.localStorage.getItem("access_token")

            const response = await fetch(`${url}/dutyCalculator/getDuty`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "x-api-key":token
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                const responseData = await response.json()
                throw new Error('Network response was not ok ' + responseData?.message);
            }

            const result = await response.json();
            // console.log("result", result)
            setOpenDutyPointDeductionModel(false)
            setResponse(result);
            setShowDutyCalcResult(false)
            setShowDutyCalcForm(true)

        } catch (error) {
            setError(error.message);
            setOpenDutyPointDeductionModel(false)
            setOpenErrorModel(true)
        }
    }

    const handleDutySaverProResultPage = async () => {
        setLoading(true)

        let newCifValue = payloadData.cifValue;
        let newFobValue = payloadData.fobValue;
        let newExwValue = payloadData.exwValue;

        let originCharges = parseFloat(payloadData.originCharges) || 0,
            exwInsuranceCharges = parseFloat(payloadData.exwInsuranceCharges) || 0,
            cifInsuranceCharges = parseFloat(payloadData.cifInsuranceCharges) || 0,
            fobInsuranceCharges = parseFloat(payloadData.fobInsuranceCharges) || 0,

            exwIntFreight = parseFloat(payloadData.exwIntFreight) || 0,
            cifIntFreight = parseFloat(payloadData.cifIntFreight) || 0,
            fobIntFreight = parseFloat(payloadData.fobIntFreight) || 0,

            originFreight = parseFloat(payloadData.originFreight) || 0,
            productVal = parseFloat(payloadData.productValue) || 0;

        switch (incoTerm) {
            case 'EXW':
                newExwValue = productValue;
                newFobValue = newExwValue + originCharges + originFreight;
                newCifValue = newFobValue + exwIntFreight + exwInsuranceCharges;
                break;
            case 'CIF':
                newCifValue = productValue;
                newFobValue = newCifValue - (cifIntFreight + cifInsuranceCharges);
                newExwValue = newCifValue - (originCharges + originFreight + cifIntFreight + cifInsuranceCharges);
                break;
            case 'FOB':
                newFobValue = productValue;
                newCifValue = newFobValue + (fobIntFreight + fobInsuranceCharges);
                newExwValue = newFobValue - (fobIntFreight + fobInsuranceCharges);
                break;
            default:
                break;
        }


        const transformedFields = fields.reduce((acc, field) => {
            acc[field.key] = field.val;
            return acc;
        }, {});


        // console.log("transformedFields", transformedFields)
        // console.log("payloadData", payloadData)
        const cyn = selectedCurrency.currency;
        const cynRate = toParseInt(selectedCurrency.value);
        const impRate = 1;



        const data = {
            hscode: hsCodeDetails?.hsCode,
            import_country: importingCountry?.value,
            export_country: exportingCountry?.value,
            CIFVALUE: (newCifValue * cynRate),
            CIF: Number(productValue),
            FOBVALUE: (newFobValue * cynRate),
            cyn: cyn,
            cyn_rate: cynRate,
            extras: fields,
            igst_product: "type1",
            imp_cyn: getCurrencyValue(currencyResponse).toLowerCase(),
            info: {
                CIFVALUE: (newCifValue * cynRate),
                CIF: (newCifValue * cynRate),
                FOBVALUE: (newFobValue * cynRate),
                cifInsuranceCharges: cifInsuranceCharges || 0,
                cifIntFreight: cifIntFreight || 0,
                tradeFlow: tradeFlow || "Importer",
                import_country: importingCountry?.value,
                export_country: exportingCountry?.value,
                hscode: Number(hsCodeDetails?.hsCode),
                findHSN: "",
                cyn: selectedCurrency?.Country,
                exwInsuranceCharges: exwInsuranceCharges || 0,
                exwIntFreight: exwIntFreight || 0,
                fobInsuranceCharges: fobInsuranceCharges || 0,
                fobIntFreight: fobIntFreight || 0,
                inco_term: incoTerm || "CIF",
                mode: payloadData?.mode || "Sea",
                originCharges: originCharges || 0,
                originFreight: originFreight || 0,
                "popup-confirm-duty-calc": "",
                productValue: Number(productValue),
                ...transformedFields

            },
            mode: payloadData?.mode || "Sea",
            ...transformedFields

        };

        try {
            const token = window.localStorage.getItem("access_token")

            const response = await fetch(`${baseUrl}/dutyCalculator/getFTAPoints`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "x-api-key":token
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            const result = await response.json();
            // console.log("result", result)
            setFtaPoint(result);
            let save = result?.save_upto;
            let total_duty = result?.total_import_duty - result?.info?.CIFVALUE;
            let save_per = ((save / total_duty) * 100).toFixed(2);
            setSaveUpto(save_per)
            setOpenConfirmationModel(true)
            setLoading(false)
        } catch (error) {
            setError(error.message);
            setLoading(false)

        }
    }

    const handleConfirm = async () => {
        setLoading(true)
        const transformedFields = fields.reduce((acc, field) => {
            acc[field.key] = field.val;
            return acc;
        }, {});
        const cyn = selectedCurrency.currency;
        const cynRate = toParseInt(selectedCurrency.value);
        const impRate = 1;


        let newCifValue = payloadData.cifValue;
        let newFobValue = payloadData.fobValue;
        let newExwValue = payloadData.exwValue;

        let originCharges = parseFloat(payloadData.originCharges) || 0,
            exwInsuranceCharges = parseFloat(payloadData.exwInsuranceCharges) || 0,
            cifInsuranceCharges = parseFloat(payloadData.cifInsuranceCharges) || 0,
            fobInsuranceCharges = parseFloat(payloadData.fobInsuranceCharges) || 0,

            exwIntFreight = parseFloat(payloadData.exwIntFreight) || 0,
            cifIntFreight = parseFloat(payloadData.cifIntFreight) || 0,
            fobIntFreight = parseFloat(payloadData.fobIntFreight) || 0,

            originFreight = parseFloat(payloadData.originFreight) || 0,
            productVal = parseFloat(payloadData.productValue) || 0;

        switch (incoTerm) {
            case 'EXW':
                newExwValue = productValue;
                newFobValue = newExwValue + originCharges + originFreight;
                newCifValue = newFobValue + exwIntFreight + exwInsuranceCharges;
                break;
            case 'CIF':
                newCifValue = productValue;
                newFobValue = newCifValue - (cifIntFreight + cifInsuranceCharges);
                newExwValue = newCifValue - (originCharges + originFreight + cifIntFreight + cifInsuranceCharges);
                break;
            case 'FOB':
                newFobValue = productValue;
                newCifValue = newFobValue + (fobIntFreight + fobInsuranceCharges);
                newExwValue = newFobValue - (fobIntFreight + fobInsuranceCharges);
                break;
            default:
                break;
        }

        const data = {
            hscode: hsCodeDetails?.hsCode,
            import_country: importingCountry?.value,
            export_country: exportingCountry?.value,
            CIFVALUE: (newCifValue * cynRate),
            CIF: Number(productValue),
            FOBVALUE: (newFobValue * cynRate),
            cyn: cyn,
            cyn_rate: cynRate,
            extras: fields,
            igst_product: "type1",
            fta_key: ftaPoint.fta_key,
            imp_cyn: getCurrencyValue(currencyResponse).toLowerCase(),
            info: {
                CIFVALUE: (newCifValue * cynRate),
                CIF: (newCifValue * cynRate),
                FOBVALUE: (newFobValue * cynRate),
                cifInsuranceCharges: cifInsuranceCharges || 0,
                cifIntFreight: cifIntFreight || 0,
                tradeFlow: tradeFlow || "Importer",
                import_country: importingCountry?.value,
                export_country: exportingCountry?.value,
                hscode: Number(hsCodeDetails?.hsCode),
                findHSN: "",
                cyn: selectedCurrency?.Country,
                exwInsuranceCharges: exwInsuranceCharges || 0,
                exwIntFreight: exwIntFreight || 0,
                fobInsuranceCharges: fobInsuranceCharges || 0,
                fobIntFreight: fobIntFreight || 0,
                inco_term: incoTerm || "CIF",
                mode: payloadData?.mode || "Sea",
                originCharges: originCharges || 0,
                originFreight: originFreight || 0,
                "popup-confirm-duty-calc": "",
                productValue: Number(productValue),
                ...transformedFields
            },
            mode: payloadData?.mode || "Sea",
            ...transformedFields

        };
        try {
            const token = window.localStorage.getItem("access_token")

            const response = await fetch(`${baseUrl}/dutyCalculator/getFTA`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "x-api-key":token
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            const result = await response.json();
            // console.log("result", result)
            setFtaPoint(result);
            setLoading(false)
            setOpenConfirmationModel(false)
            setSelectedCurrency(selectedCurrency)
            setExportingCountry(exportingCountry)
            setProductValue(productValue)
            setSaverProResponse(result)
            setShowDutyCalcForm(false)
            setShowDutyCalcResult(false)
            setshowDutySaverProResult(true)
        } catch (error) {
            setLoading(false)
            setError(error.message);
        }
    }

    const handleClose = () => {
        setOpenConfirmationModel(false)
    }

    const handleOpenTradflowModel = () => setTradflowModelModel(true);
    const handleCloseTradflowModel = () => setTradflowModelModel(false);
    const handleSaveTradflow = () => {
        handleCloseTradflowModel();
    };
    const handleDutyPointDeductionClose = () => {
        setOpenDutyPointDeductionModel(false)
    }

    const handleSwitchChange = (event) => {
        setTradeFlow(event.target.checked ? event.target.value : '');
    };

    const handleDutyDeductionModel = () => {

        if (!productValue || !selectedCurrency.value || !exportingCountry?.value || !hsCodeDetails?.hsCode || !payloadData?.mode ||
            !incoTerm) {
            setError("Please Ensure All Fields Are Completed.")
            setOpenErrorModel(true)
            return
        }
        setOpenDutyPointDeductionModel(true)
    }
    const handleCloseErrorModel = () => {
        setOpenErrorModel(false)
    }


    const handleModify = () => {
        setShowDutyCalcForm(false)
        setShowDutyCalcResult(true)
        setshowDutySaverProResult(false)
    }
    return (
        <>
            {
                showDutyCalResult &&
                <Container sx={{ marginTop: "25px" }}>
                    <div container className="duty-calculator-page-main-container">
                        <Box p={3} boxShadow={3} borderRadius={5} className="duty-calculator-section1" sx={{
                            backgroundColor: colors.primary[400]
                        }}>
                            <Box sx={{ width: "100%" }}>
                                <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                                    Shipment Details
                                </Typography>
                                <Typography gutterBottom>
                                    Who are you?
                                </Typography>
                            </Box>

                            <div className="selectContainer">
                                <Typography className="duty-calc-label" >I am</Typography>
                                <select id="importExportSelect" value={tradeFlow} onClick={handleOpenTradflowModel}
                                    readOnly required>
                                    <option value="select"> Select</option>
                                    <option value="importer">Importer</option>
                                    <option value="exporter">Exporter</option>
                                </select>

                                <Modal
                                    open={tradflowModelModel}
                                    onClose={handleCloseTradflowModel}
                                    aria-labelledby="import-export-modal"
                                    aria-describedby="select-importer-or-exporter"
                                >
                                    <div style={{ padding: 20, backgroundColor: colors.primary[400], margin: '10% auto', width: 300, borderRadius: '7px' }}>
                                        <Typography variant="h6" sx={{ textAlign: 'center', width: '100%' }}>Who are you?</Typography>
                                        <div className="switch-option">
                                            <FormControlLabel
                                                label="Importer"
                                                control={<Switch checked={tradeFlow === 'importer'} onChange={handleSwitchChange} value="importer" />}
                                                labelPlacement="start"
                                                sx={{
                                                    justifyContent: "space-between",
                                                }}
                                            />
                                            <FormControlLabel
                                                label="Exporter"
                                                control={<Switch checked={tradeFlow === 'exporter'} onChange={handleSwitchChange} value="exporter" />}
                                                labelPlacement="start"
                                                sx={{
                                                    justifyContent: "space-between",
                                                }}
                                            />
                                        </div>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                textAlign: 'center', width: '100%', backgroundColor: 'var(--intgrow-primary-color)',
                                                '&:hover': { backgroundColor: 'var(--intgrow-primary-color) !important' }

                                            }}
                                            onClick={handleSaveTradflow}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Modal>
                            </div>
                            <div className="selectContainer">
                                <Typography className="label">Importing Country</Typography>
                                <CountrySelect
                                    placeholder="Select Country"
                                    type="importing_country"
                                    setSelectedCountry={setImportingCountry}
                                    selectedCountry={importingCountry}
                                />
                            </div>
                            <div className="selectContainer">
                                <Typography className="label">Exporting Country</Typography>
                                <CountrySelect
                                    placeholder="Select Country"
                                    type="exporting_country"
                                    setSelectedCountry={setExportingCountry}
                                    selectedCountry={exportingCountry}
                                    selectedImp={importingCountry}

                                />
                            </div>
                        </Box>
                        <Box p={3} boxShadow={3} borderRadius={5} className="duty-calculator-section2" sx={{
                            backgroundColor: colors.primary[400]
                        }}>
                            <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', width: "100%" }}>
                                Products Details
                            </Typography>
                            <Typography variant='h6' style={{ width: "100%" }}> Product Name or Hash Code</Typography>
                            <BrowseHsCode2 importingCountry={importingCountry} exportingCountry={exportingCountry} />

                            <Grid container spacing={2} justifyContent="space-between">
                                <Grid item xs={12} md={5.5}>
                                    <Typography variant='h6'> Currency</Typography>
                                    <SelectCurrency inputPadding={"16px 14px"} selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} imp={importingCountry?.value} />
                                </Grid>
                                <Grid item xs={12} md={5.5}>
                                    <Typography variant='h6'>Value of Product</Typography>
                                    <TextField
                                        placeholder="Enter Value"
                                        type="number"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        onChange={(e) => setProductValue(e.target.value)}
                                    />
                                </Grid>

                                {(urlInputResponse && urlInputResponse.length > 0) && urlInputResponse.map((attr, index) => {
                                    const isRequired = attr.is_required ? true : false;
                                    const type = attr.type || 'none';
                                    const selectedField = fields.find(item => item.key === attr.field);
                                    const selectedValue = selectedField ? selectedField.val : '';
                                    const selectedLabel = selectedField ? selectedField.txt : '';
                                    return (
                                        <Grid item xs={12} md={5.5} key={index}>
                                            <Box className="form-group pre-fill-igst" sx={{ mb: 2 }}>
                                                <Typography className="duty-calc-label" sx={{ textWrap: "nowrap", overflow: "hidden" }}>
                                                    {attr.label}-
                                                    {attr.pre_fix && <span className="userinput-align">(in {attr.pre_fix})</span>}
                                                </Typography>
                                                <Box className="btn-group col" sx={{ display: 'flex', alignItems: 'center' }}>
                                                    {type === 'none' || type === 'text' || type === 'number' ? (
                                                        <TextField
                                                            id={attr.field}
                                                            type={type === 'number' ? 'number' : 'text'}
                                                            label={attr.label}
                                                            required={isRequired}
                                                            value={selectedValue}
                                                            variant="outlined"
                                                            fullWidth
                                                            onChange={(e) => handleDynamicInputChange(attr, e.target.value)}
                                                        />
                                                    ) : type === 'droplist' ? (
                                                        <TextField
                                                            variant="outlined"
                                                            required={isRequired}
                                                            fullWidth
                                                            value={selectedLabel}
                                                            onClick={(e) => openDynamicModal(attr, e.target.value)}
                                                            readOnly
                                                            InputProps={{ style: { cursor: 'pointer' } }}
                                                            placeholder={`Select ${attr.label}`}
                                                        />
                                                    ) : null}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                                {currentAttr && (
                                    <CustomModal
                                        open={openDynamicModal}
                                        onClose={closeDynamicModal}
                                        attr={currentAttr}
                                        selectedValue={currentSelectedValue}
                                        handleSelectChange={handleSelectChange}
                                    />
                                )}
                            </Grid>
                        </Box>
                    </div>
                    <Box boxShadow={3} className="duty-calculator-section3" sx={{
                        backgroundColor: colors.primary[400]
                    }}>
                        <Typography variant='h4' style={{ fontWeight: 'bold' }}>Shipping Details</Typography>
                        <Grid container spacing={2} justifyContent="space-between">
                            <DutyCalculationShipingDetails productValue={productValue} payloadData={payloadData} setPayloadData={setPayloadData} setIncoTerm={setIncoTerm} incoTerm={incoTerm} />

                            <Grid item xs={12} md={4}>
                                <Typography variant="h6"></Typography>
                                <Button variant="contained" sx={{
                                    backgroundColor: 'var(--intgrow-primary-color)', marginTop: '22px', width: "100%", padding: '15px',
                                    '&:hover': { backgroundColor: 'var(--intgrow-primary-color) !important' }
                                }}
                                    onClick={handleDutyDeductionModel}
                                >Calculate Duty</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            }
            {
                showDutyCalcForm &&
                <DisplaySaveDuty handleDutySaverProResultPage={handleDutySaverProResultPage} handleModify={handleModify} setShowDutyCalcForm={setShowDutyCalcForm} setShowDutyCalcResult={setShowDutyCalcResult} getDutyResponse={response} inputData={InputData} exportingCountry={exportingCountry} importingCountry={importingCountry} currencyResponse={currencyResponse} />
            }
            {showDutySaverProResult && <DutySaverStripe handleModify={handleModify} inputData={InputData} saveDutyResponse={saverProResponse} currencyResponse={currencyResponse} />}

            <Dialog open={openConfirmationModel} onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        maxWidth: 'fit-content',
                        backgroundColor: colors.primary[400],
                        backgroundImage: "none !important"
                    }
                }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <img src="/assets/images/duty_saver_msg2.png" alt="icon" width={100} height={100} />
                    <img src="/assets/images/duty_saver_msg1.png" alt="icon" width={100} height={100} />
                </div>
                <DialogTitle sx={{ padding: "10px 24px" }}>
                    <div className='save-huge-msg-section'>
                        <h4 className='unlock-huge-saving' style={{color:colors.primaryColor[100]}}>Unlock Huge Savings! upto {saveUpto} % Import tax.</h4>
                        <p className='save-upto' style={{color:colors.primaryColor[100]}}>Save upto  {ftaPoint && formatValue(ftaPoint?.save_upto)} {currencyResponse.currency} ( {formatValue(currencyConvert(ftaPoint?.save_upto, cynRate))}) {cyn} on your transactions !</p>
                        <p className="proceed-now" style={{color:colors.primaryColor[100]}}>Proceed now to maximize your profits</p>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className="duty-saver-points-deduction-notes">
                            <Typography className='notes'>Notes</Typography>
                            <p className='point-deduction-msg' style={{ padding: "10px" ,color:colors.primaryColor[100]}}>A minimum of <span className='highlight-msg'>{ftaPoint?.charge} credit </span> will be deduct from your <span className='highlight-msg'>Duty Calculator</span>credits. </p>
                        </div>
                        <p className='point-want-to-proceed' style={{color:colors.primaryColor[100]}}> Do you want to proceed?</p>
                    </DialogContentText>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Button onClick={handleConfirm} className='point-deduction-yes-btn' autoFocus>
                            Yes
                        </Button>
                        <Button onClick={handleClose} className='point-deduction-cancel-btn'>
                            Cancel
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={openDutyPointDeductionModel} onClose={handleDutyPointDeductionClose}
               sx={{
                '& .MuiPaper-root': {
                    maxWidth: 'fit-content',
                    backgroundColor: colors.primary[400],
                    backgroundImage: "none !important"
                }
            }}>
                <DialogTitle>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src="/assets/images/point_deduction.png" alt="point deduction" width={150} height={150} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <p className='point-deduction-msg'>If you proceed <span className='highlight-msg'>1 points</span> will be deduct from your <span className='highlight-msg'>Duty Calculator </span> credits. </p>
                        <p className='point-want-to-proceed'> Do you want to proceed?</p>
                    </DialogContentText>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Button onClick={handleDutyCalculation} className='point-deduction-yes-btn' autoFocus>
                            Yes
                        </Button>
                        <Button onClick={handleDutyPointDeductionClose} className='point-deduction-cancel-btn'>
                            Cancel
                        </Button>
                    </div>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>

            <Dialog open={openErrorModel} onClose={handleCloseErrorModel}
             sx={{
                '& .MuiPaper-root': {
                    backgroundColor: colors.primary[400],
                    backgroundImage: "none !important"
                }
            }}>
                <DialogContent>
                    <DialogContentText>
                        <p>{error}</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseErrorModel} className='point-deduction-cancel-btn'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={loading} onClose={() => setLoading(false)}
                 sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: colors.primary[400],
                        backgroundImage: "none !important"
                    }
                }}>
                <DialogContent>
                    <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DutySaverPro;
