import React, { useState, useEffect } from 'react';
import '../../../components/CommingSoon/createBlog/createblogimage/outletform/supportview.css';
import { tokens } from '../../../theme'
import { Modal, Box, Button, Typography, TextField, useTheme } from '@mui/material';
import { baseUrl } from '../../../utils/baseURL';

const GlossaryList = () => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const colors = tokens(mode)

  const [glossaryList, setGlossaryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [selectedGlossary, setSelectedGlossary] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [glossaryDetails, setGlossaryDetails] = useState({});

  const [heading, setHeading] = useState('');
  const [subheading, setSubheading] = useState('');
  const [definition, setDefinition] = useState('');
  
  const fetchData = async () => {
    const token = window.localStorage.getItem('access_token');
    try {
      const response = await fetch((process.env.REACT_APP_API_KEY || "https://intgrow.co")+'/api/v1/glossary/list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "x-api-key":token
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setGlossaryList(data?.glossary);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (glossaryDetails) {
      setHeading(glossaryDetails.heading || '');
      setSubheading(glossaryDetails.subheading || '');
      setDefinition(glossaryDetails.definition || '');
    }
  }, [glossaryDetails]);

  const handleOpenModal = (id) => {
    const fetchGlossaryDetails = async () => {
      const token = window.localStorage.getItem('access_token');
      try {
        const response = await fetch(`${baseUrl}/glossary/details/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            "x-api-key":token
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setGlossaryDetails(data?.glossary);
        setSelectedGlossary(data?.glossary);
        setOpenModal(true);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchGlossaryDetails();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedGlossary(null);
    setGlossaryDetails({});
  };

  const handleUpdateSubmit = async () => {
    if (!selectedGlossary) return;

    try {
      const token = window.localStorage.getItem('access_token');
      const response = await fetch(`${baseUrl}/glossary/update-by-id/${selectedGlossary.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          "x-api-key":token
        },
        body: JSON.stringify({ heading, subheading, definition })
      });

      if (response.ok) {
        setGlossaryList((prev) =>
          prev.map((item) => (item.id === selectedGlossary.id ? { ...item, heading, subheading, definition } : item))
        );
        handleCloseModal();
      } else {
        console.error('Failed to update glossary');
      }
    } catch (error) {
      console.error('Failed to update glossary', error);
    }
  };


  const handleDelete = async (id) => {

    try {
      const token = window.localStorage.getItem('access_token');
      const response = await fetch(`${baseUrl}/glossary/delete-by-id/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "x-api-key":token
        },
      });

      if (response.ok) {
        fetchData()
      } else {
        console.error('Failed to update glossary');
      }
    } catch (error) {
      console.error('Failed to update glossary', error);
    }
  };

  const handleChangePage = (newPage) => setCurrentPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const currentItems = glossaryList.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="view-section-container">
      <h1>Glossary</h1>
      <div className="support-container" style={{
        backgroundColor: colors.primary[400],
      }}>
        <div className="support-table-wrapper">
          <table className="support-table">
            <thead>
              <tr>
                <th>S.no.</th>
                <th>Heading</th>
                <th>Sub Heading</th>
                <th>Definition</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={item.id} style={{ borderBottom: '1px solid #dadada' }}>
                  <td style={{padding:"1px 5px"}}>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                  <td>{item.heading}</td>
                  <td>{item.subheading}</td>
                  <td>{item.definition}</td>
                  <td style={{ display: "flex" }}>
                    <button className="view-button" onClick={() => handleOpenModal(item.id)}>
                      Update
                    </button>
                    <button className="view-button" onClick={() => handleDelete(item.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="pagination">
        <div className="rows-per-page">
          <span>
            Rows per page:
            <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
            </select>
          </span>
        </div>
        <div className="pagination-buttons">
          <button
            className="previous-button"
            onClick={() => handleChangePage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className="next-button"
            onClick={() => handleChangePage(currentPage + 1)}
            disabled={currentPage * rowsPerPage >= glossaryList.length}
          >
            Next
          </button>
        </div>
      </div>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
          p: 4,
          borderRadius: 2,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          height: "450px",
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          maxWidth: 800,
          width: '100%',
          boxShadow: 3,
          bgcolor: colors.primary[400],
        }}>
          <Box sx={{
            width: "100%",
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}>
            <Typography variant="h5" component="h2" gutterBottom>
              Update Glossary
            </Typography>

            <TextField
              label="Heading"
              variant="outlined"
              fullWidth
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
            />
            <TextField
              label="Sub Heading"
              variant="outlined"
              fullWidth
              value={subheading}
              onChange={(e) => setSubheading(e.target.value)}
            />
            <TextField
              label="Definition"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={definition}
              onChange={(e) => setDefinition(e.target.value)}
            />

            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2
            }}>
              <Button variant="contained" color="primary"
                 sx={{
                  backgroundColor: "var(--intgrow-bg)",
                  "&:hover": {
                    backgroundColor: "var(--intgrow-bg)"
                  }
                }}
               onClick={handleUpdateSubmit}>
                Save Changes
              </Button>
              <Button variant="outlined" color="primary" 
                 sx={{
                  backgroundColor: "var(--intgrow-bg)",
                  "&:hover": {
                    backgroundColor: "var(--intgrow-bg)"
                  }
                }}onClick={handleCloseModal}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default GlossaryList;
