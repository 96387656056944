const hostname = (process.env.REACT_APP_API_URL || "https://intgrow.co")+"/api/v1";
// const hostname = "http://localhost:3001/api/v1"

exports.findHSCode = async (ele = '') => {
    try {
        const response = await fetch(`${hostname}/hs_code/details?hs=${ele}`);

        const data = await response.json();
        if (!response.ok) {
            // console.log('Error in fetch hscode');
            throw new Error('Error in fetch hscode');
        }
        return data;
    } catch (error) {
        console.error('An error occurred:', error);
    }
}

exports.getUserData = async () => {
    try {
        const token = window.localStorage.getItem('access_token');
        const url = `${hostname}/auth/me`;
        const response = await fetch(url, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                "x-api-key":token
            },   
        });
        const data = await response.json();
        const value = {
            first_name: data.result.first_name,
            last_name: data.result.last_name,
            email: data?.result.email,
            mobile: data?.result.mobile,
            photo_url: data.result.photo_url
        };
        return value;
    } catch (error) {
        console.error('Error:', error);
        return {
            name: '',
            email: '',
            photo_url: ''
        };
    }
};

const toTitleCase = (str = null) => {
    if (!str) return str;
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

exports.totalSavedDuty = (num) => {
    num = (parseFloat(num || 0)).toLocaleString('en-US', { notation: 'compact', compactDisplay: "long" });
    if ((num + "").indexOf(' ') > -1) {
        const _num = num.split(' ');
        num = _num[0] + ` <small>${toTitleCase(_num[1])}</small>`
    }
    return num;
}

exports.fetchCountry = async () => {
    try {
        const response = await fetch(`${hostname}/country/search`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const data = await response.json();
        if (!response.ok) {
            // console.log('Error in fetch igst description');
            throw new Error('Error in fetch igst description');
        }
        // console.log("history", data)
        window.localStorage.setItem("saveCountryList", JSON.stringify(data))
        return data;
    } catch (error) {
        console.error('An error occurred:', error);
    }
}

exports.fetchDutySaverHistoryRecords = async (page) => {
    try {
        const token = window.localStorage.getItem("access_token")
        const response = await fetch(`${hostname}/logs/fta/${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "x-api-key":token
            },
        })
        const data = await response.json();
        if (!response.ok) {
            // console.log('Error in fetch igst description');
            throw new Error('Error in fetch igst description');
        }
        // console.log("history", data)
        return data;
    } catch (error) {
        console.error('An error occurred:', error);
    }
}

exports.fetchDutySaverHistoryRecordDetails = async (id) => {
    try {
        const token = window.localStorage.getItem("access_token")
        const response = await fetch(`${hostname}/logs_response_by_id/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "x-api-key":token
            },
        })
        const data = await response.json();
        if (!response.ok) {
            // console.log('Error in fetch igst description');
            throw new Error('Error in fetch igst description');
        }
        // console.log("history", data)
        return data;
    } catch (error) {
        console.error('An error occurred:', error);
    }
}

exports.fetchAllHistoryRecords = async () => {
    try {
        const token = window.localStorage.getItem("access_token")
        const response = await fetch(`${hostname}/logs/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "x-api-key":token
            },
        })
        const data = await response.json();
        if (!response.ok) {
            // console.log('Error in fetch igst description');
            throw new Error('Error in fetch igst description');
        }
        // console.log("history", data)
        return data;
    } catch (error) {
        console.error('An error occurred:', error);
    }
}

exports.fetchLogData = async (logId) => {
    const token=window.localStorage.getItem("access_token")
    try {
      const response = await fetch(`${hostname}/logs_response_by_id/${logId}`, {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Origin": window.location.hostname,
          "x-api-key":token
        }
      });
      if (!response.ok) {
        const msg = `Error in fetch ${response.status}`;
        throw new Error(msg);
      }
      const data = response.status !== 204 ? await response.json() : [];
    //   console.log("data",data)
      const landedCost = data;
      return landedCost;
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }


//   hs code

exports.fetchHSCodeHistoryRecords = async (page) => {
    try {
        const token = window.localStorage.getItem("access_token")
        const response = await fetch(`${hostname}/logs/hs_code/${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "x-api-key":token
            },
        })
        const data = await response.json();
        if (!response.ok) {
            // console.log('Error in fetch igst description');
            throw new Error('Error in fetch igst description');
        }
        // console.log("history", data)
        return data;
    } catch (error) {
        console.error('An error occurred:', error);
    }
}                  

exports.fetchDutyCalcHistoryRecords = async (page) => {
    try {
        const token = window.localStorage.getItem("access_token")
        const response = await fetch(`${hostname}/logs/duty/${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "x-api-key":token
            },
        })
        const data = await response.json();
        if (!response.ok) {
            // console.log('Error in fetch igst description');
            throw new Error('Error in fetch igst description');
        }
        // console.log("history", data)
        return data;
    } catch (error) {
        console.error('An error occurred:', error);
    }
} 