import React, { useState } from 'react';
import { Button, Box, Typography, Select, MenuItem, InputLabel, FormControl,CircularProgress } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { baseUrl } from '../../../utils/baseURL';

const UploadMedia = () => {
  const [media, setMedia] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [mediaType, setMediaType] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);


  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/gif': [],
      'video/mp4': []
    },
    onDrop: (acceptedFiles) => {
      setMedia(acceptedFiles[0]);
      setError('');
    },
    onDropRejected: () => {
      setError('Only MP4, GIF, PNG, and JPEG files are allowed');
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!media) {
      setError('Please select a media file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('media', media);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('media_type', mediaType);

    const fileType = media.type;
    let valid = false;
    
    if (mediaType === "banner" || mediaType === "gif") {
      valid = fileType === "image/jpeg" || fileType === "image/png" || fileType === "image/gif";
    } else if (mediaType === "video") {
      valid = fileType === "video/mp4";
    }
      
    if(!valid){
      return setError("Please select valid media.")
    }
    setLoading(true);
    try {
      const response = await fetch(`${baseUrl}/upload-media`, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        // console.log('File uploaded successfully');
        setMedia(null);
        setTitle('');
        setDescription('');
        setError('');
      } else {
        console.error('Failed to upload file');
        setError('Failed to upload file');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Error uploading file');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        maxWidth: 600,
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
        bgcolor: 'background.paper',
        margin: "20px auto"
      }}
    >
      <Typography variant="h5" component="h2" gutterBottom>
        Upload Media (Size should be less than 10MB)
      </Typography>
      <Box
        {...getRootProps()}
        sx={{
          p: 2,
          border: '1px dashed',
          borderRadius: 2,
          textAlign: 'center',
          cursor: 'pointer',
          bgcolor: 'background.default'
        }}
      >
        <input {...getInputProps()} />
        <Typography variant="body1" color="text.secondary">
          Drag and drop a file here, or click to select one
        </Typography>
        {media && <Typography variant="body2" color="text.primary">File: {media.name}</Typography>}
        {error && <Typography variant="body2" color="error">{error}</Typography>}
      </Box>

      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc', marginTop: '10px' }}
      />

      <input
        type="text"
        placeholder="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc', marginTop: '10px' }}
      />

      <FormControl fullWidth>
        <InputLabel id="media-type-label">Media Type</InputLabel>
        <Select
          labelId="media-type-label"
          id="media-type-select"
          value={mediaType}
          label="Media Type"
          onChange={(e) => setMediaType(e.target.value)}
          style={{ marginTop: '10px' }}
        >
          <MenuItem value="video">Video</MenuItem>
          <MenuItem value="gif">GIF</MenuItem>
          <MenuItem value="banner">Banner</MenuItem>
        </Select>
      </FormControl>

      <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!media || loading}
        sx={{
          backgroundColor: "var(--intgrow-bg)",
          "&:hover": {
            backgroundColor: "var(--intgrow-bg)"
          }
        }}>
        {loading ? <CircularProgress size={24} /> : 'Upload'}
      </Button>
    </Box>
  );
};

export default UploadMedia;
