import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { useTheme, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import OtpInput from './OTPInput';
import { baseUrl } from '../../utils/baseURL';
import { useNavigate } from 'react-router-dom';
import { fetchCountry } from '../../utils/Api';
const VerifyOtpAndRecaptcha = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState("")

  useEffect(() => {
    const mobile = window.localStorage.getItem('mobile');
    setNumber(mobile)
  }, [])

  const formik = useFormik({ 
    initialValues: {
      otp: '',
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .length(6, 'OTP must be 6 digits')
        .required('OTP is required'),
    }),
    onSubmit: async (values, helpers) => {
      setLoading(true);
      const mobile = window.localStorage.getItem('mobile');
      const orderId = window.localStorage.getItem('orderId');

      // console.log("otp", values.otp, mobile)
      try {
        const response = await fetch(`${baseUrl}/auth/mobile-otp-signin/verification`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...values, mobile, orderId }),
        });
        if (!response.ok) {
          const responseData = await response.json();
          const loginError = new Error(responseData.error || 'An error occurred');
          loginError.status = response.status;
          setLoading(false);
          throw loginError;
        }

        const responseData = await response.json();
        window.localStorage.setItem('authenticated', 'true');
        window.localStorage.setItem("isAuthenticated", true)
        window.localStorage.setItem('access_token', responseData.data.token);
        window.localStorage.removeItem('mobile');
        window.localStorage.removeItem('orderId')
        const user = {
          id: responseData.data.id,
          name: responseData.data.first_name,
          email: responseData.data.email,
          first_name:responseData.data.first_name,
          last_name:responseData.data?.last_name,
          photo_url:responseData.data?.photo_url
        };
        fetchCountry()

        window.localStorage.setItem("userInfo", JSON.stringify(user))
        const continueUrl = localStorage.getItem("continueUrl");
        if (continueUrl) {
          localStorage.removeItem("continueUrl");
          navigate(continueUrl);
        } else {
          navigate("/dashboard");
        }
      } catch (error) {
        setLoading(false);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: error.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleOtpChange = (otp) => {
    formik.setFieldValue('otp', otp);
  };

  const resendOtp = async () => {
    try {
      setLoading(true);
      const mobile = window.localStorage.getItem('mobile');
      const orderId = window.localStorage.getItem('orderId');
      const response = await fetch(`${baseUrl}/auth/mobile-signin/resend-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify({ mobile, orderId}),
      });
      if (!response.ok) {
        const responseData = await response.json();
        const loginError = new Error(responseData.error || 'An error occurred');
        loginError.status = response.status;
        toast.error(responseData?.message);
        setLoading(false);
        throw loginError;
      }
      const responseData = await response.json();
      // console.log("mobile", responseData)
      toast.success("OTP Send Successfully");
      setLoading(false);

    } catch (err) {
      toast.error(err.message);
      setLoading(false);

    }

  };
  return (
    <section className='Otp-verification-section'>
      <ToastContainer />
      <div className='otp-main-container'>
        <div className='input-Otp-title'>
          <h2>OTP Verification</h2>
          <p>
            <label htmlFor="otp">Enter 6 digit OTP send to your mobile number xxxxxxx{number && number.length > 0 && number?.slice(7,)} </label>
          </p>
        </div>
        <form onSubmit={formik.handleSubmit} className="otp-form">
          <div className="form-group">
            <OtpInput
              value={formik.values.otp}
              valueLength={6}
              onChange={handleOtpChange}
            />
            {formik.touched.otp && formik.errors.otp ? (
              <div className="recaptcha-error">{formik.errors.otp}</div>
            ) : null}
          </div>
          <div className="form-group">
            <p>Do not receive otp?<span className='resend-otp' onClick={() => resendOtp()}> resend</span></p>
          </div>
          <button type="submit" className={theme.palette.mode === "dark" ? "btn btn-secondary" : "btn btn-primary"}>Verify</button>
        </form>
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
      </div >

    </section>
  );
};

export default VerifyOtpAndRecaptcha;
