import React, { useEffect, useState } from 'react'
import DutyCalculator from '../../../adminPanel/DutyCalculator/DutyCalculator'
import { Box, Typography } from '@mui/material'
import { Helmet } from 'react-helmet';
import DisclaimerStrip from '../../../components/Disclaimer/DisclaimerStripe';
import CommonFAQAccordionSection from '../../../components/AccordionSection/CommonFAQAccordian';

const DutyCalculatorPage = () => {
    const [scrollToTop, setScrollToTop] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0);
        // console.log("scroll to top 12")

    }, [scrollToTop])
    return (
        <>
            <Helmet>
                <title>Duty Calculator | IntGrow Corp.</title>
            </Helmet>

            <Box m="20px">
                <Box display="flex" justifyContent="space-between" alignItems="center"
                    sx={{
                        marginBottom: "7px"
                    }}>
                    <Typography variant="h1" component="h2"
                        sx={{
                            fontWeight: "700",
                            fontSize: "48px"
                        }}>Duty Calculator</Typography>
                </Box>
                <DutyCalculator setScrollToTop={setScrollToTop} />
                <CommonFAQAccordionSection section="Duty Calculation" />
                <DisclaimerStrip />
            </Box>

        </>
    )
}

export default DutyCalculatorPage
