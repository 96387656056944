import React, { useState, useEffect } from 'react'
import EmailIcon from '@mui/icons-material/Email';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box, Button, Stack, TextField,
  Typography, Checkbox, FormControlLabel, InputAdornment, IconButton, useTheme
} from '@mui/material';
import { baseUrl } from '../../utils/baseURL';
import { useNavigate, Link } from 'react-router-dom';
import LoginWithMobile from './LoginWithMobile';
import ContinueWith from '../Layout/ContinueWith';
import LoginWithGoogle from '../../SSO/LoginWithGoogle';
import LoginWithLinkedIn from '../../SSO/LoginWithLinkedIn';
import './auth-index.style.css';
import { fetchCountry } from '../../utils/Api';
const LoginForm = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  const mode = theme.palette.mode
  const [turnstileToken, setTurnstileToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberPassword, setRememberPassword] = useState(false)
  const [loading, setLoading] = useState(false);
  const [loginWith, setLoginWith] = useState("Email");


  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      window.onloadTurnstileCallback = function () {
        window.turnstile.render('#turnstile-element', {
          sitekey: process.env.REACT_APP_TURNSTILE_SITE_KEY || "0x4AAAAAAAesvKZR6huAwJ93",
          callback: (token) => setTurnstileToken(token),
          'error-callback': () => setTurnstileToken(null),
        });
      };
      if (window.onloadTurnstileCallback) {
        window.onloadTurnstileCallback();
      }
    };
    document.body.appendChild(script);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: window.localStorage.getItem('remembered_email') || '',
      password: window.localStorage.getItem('remembered_password') || '',
      submit: null
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup
        .string()
        .max(255)
        .required('Password is required')
    }),

    onSubmit: async (values, helpers) => {
      setLoading(true);
      try {

        if (!turnstileToken) {
          alert('Please complete the Turnstile verification');
          setLoading(false);
          return;
        }
        // console.log("turnstileToken", turnstileToken)

        const response = await fetch(`${baseUrl}/auth/signIn`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...values, turnstileToken }),
        });
        if (!response.ok) {
          const responseData = await response.json();
          const loginError = new Error(responseData.error || 'An error occurred');
          loginError.status = response.status;
          setLoading(false);
          throw loginError;
        }

        const responseData = await response.json(); 
        window.localStorage.setItem('authenticated', 'true');
        window.localStorage.setItem("isAuthenticated", true)
        window.localStorage.setItem('access_token', responseData.result.token);
        window.localStorage.setItem('showNavbar', true);

        if (rememberPassword) {
          window.localStorage.setItem('remembered_email', values.email);
          window.localStorage.setItem('remembered_password', values.password);
        } else {
          window.localStorage.removeItem('remembered_email');
          window.localStorage.removeItem('remembered_password');
        }
        const user = {
          id: responseData.result.id,
          name: responseData.result.first_name,
          email: responseData.result.email,
          first_name:responseData.result.first_name,
          last_name:responseData.result.last_name,
          photo_url:responseData.result.photo_url
        };
        window.localStorage.setItem("userInfo", JSON.stringify(user))

        setLoading(false);
        fetchCountry()
        const continueUrl = localStorage.getItem("continueUrl");
        if (continueUrl) {
          localStorage.removeItem("continueUrl");
          navigate(continueUrl);
        } else {
          navigate("/dashboard");
        }
      } catch (error) {
        setLoading(false);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: error.message });
        helpers.setSubmitting(false);
      }
    }
  });


  const handleRememberPasswordChange = () => {
    setRememberPassword(!rememberPassword);
  };

  const handleLoginMethod = (method) => {
    setLoginWith(method) 
  }
  return (
    <div className='login-form-section'>
      <ContinueWith />
      <div className='social-login-section'>
        <LoginWithGoogle />
        <LoginWithLinkedIn />
        <button className='Mobile-login-btn' onClick={() => handleLoginMethod("Mobile")}>
          <img src='/assets/logos/mobile.png' alt='mobile-login' />
        </button>
      </div>
      <div className='seperate-method'>
        <p className={`${mode === "dark" ? "light-divider" : "dark-divider"}`}></p>
        <p>OR</p>
        <p className={`${mode === "dark" ? "light-divider" : "dark-divider"}`}></p>
      </div>

      {
        loginWith === "Email" ?
          <form
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <Stack spacing={3}
              sx={{ color: "#444B59", margin: "0px" }}>
              <TextField
                error={!!(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email Address"
                name="email"
                id="outlined-required"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                value={formik.values.email}
                sx={{
                  color: "#444B59",
                  padding: "2px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: mode === "dark" ? "#a19191" : "#a19191",
                    },
                    "&:hover fieldset": {
                      borderColor: mode === "dark" ? "#a19191" : "#a19191",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: mode === "dark" ? "#a19191" : "#a19191",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: mode === "dark" ? "#fff" : "#0E588C",
                  },
                  "& .MuiInputAdornment-root svg": {
                    color: mode === "dark" ? "#fff" : "#0E588C",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "10px",
                  }
                }}
                InputLabelProps={{
                  style: { color: mode === "dark" ? "#fff" : "#0E588C", }
                }}
                InputProps={{
                  style: {
                    color: mode === "dark" ? "#fff" : "#0E588C",
                    fontSize: "medium",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    width: "100%",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailIcon sx={{ color: "#0E588C", marginTop: "0px" }} />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                error={!!(formik.touched.password && formik.errors.password)}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Password"
                id="outlined-required2"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                sx={{
                  color: "#444B59",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: mode === "dark" ? "#a19191" : "#a19191",
                    },
                    "&:hover fieldset": {
                      borderColor: mode === "dark" ? "#a19191" : "#a19191",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: mode === "dark" ? "#a19191" : "#a19191",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: mode === "dark" ? "#fff" : "#0E588C",
                  },
                  "& .MuiInputAdornment-root svg": {
                    color: mode === "dark" ? "#fff" : "#0E588C",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "10px",
                  }
                }}
                InputLabelProps={{
                  style: { color: mode === "dark" ? "#fff" : "#0E588C", }
                }}
                InputProps={{
                  style: {
                    color: mode === "dark" ? "#fff" : "#0E588C",
                    fontSize: "medium",
                    fontStyle: "normal",
                    fontWeight: 400,
                    width: "100%",
                    lineHeight: "normal",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        sx={{ color: "#0E588C" }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div id="turnstile-element" />

              {formik.errors.turnstileToken && <p style={{ color: "#D14343" }}>Please complete the verification</p>}

              <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
              }}>
                <FormControlLabel
                  control={<Checkbox name="rememberPassword"
                    checked={rememberPassword}
                    onChange={handleRememberPasswordChange}
                    style={{ color: mode === "dark" ? "#fff" : "#0E588C" }}

                  />}
                  label="Remember Password"
                  sx={{
                    color: "#0E588C", width: "max-content",
                    "& .MuiFormControlLabel-label": {
                      color: mode === "dark" ? "#fff" : "#0E588C"
                    }
                  }}
                  style={{ width: "max-content !important" }}

                />
                <Button sx={{
                  color: "white", textTransform: "capitalize",
                  backgroundColor: "transparent",
                  fontSize: "0.8571428571428571rem",
                  "& a": {
                    color: mode === "dark" ? "#fff" : "#0E588C",
                    backgroundColor: "transparent"
                  },
                  "&:hover": {
                    backgroundColor: "transparent"
                  },
                }}>
                  <Link
                    to="/auth/forgot-password"
                    underline="hover"
                  >
                    Forgot Password
                  </Link>
                </Button>
              </Box>
              {formik.errors.submit && (
                <Typography
                  color="error"
                  variant="body2"
                >
                  {formik.errors.submit}
                </Typography>
              )}
              {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
              )}
            </Stack>
            <button className='normal-login-btn' type='submit' style={{ color: "#ffffff" }}>Login</button>
            <p className='if-dont-have-acc'>If you don't have an account<Link to="/auth/signup" style={{
              marginInlineStart: 6,
              color: mode === "dark" ? "#fff" : "#0E588C",
            }}>Register Here </Link></p>

          </form> :
          <LoginWithMobile />
      }
    </div>
  )
}

export default LoginForm
