import React, { useState, useEffect } from 'react';
import {FormControl, InputLabel, Select, MenuItem, Button, Box, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { baseUrl } from '../../utils/baseURL';

const UploadFile = () => {
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTables = async () => {
      try {
        const response = await fetch(`${baseUrl}/tables`);
        const data = await response.json();
        setTables(data?.tableNames);
      } catch (error) {
        console.error('Error fetching table list:', error);
      }
    };

    fetchTables();
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.csv, .xlsx',
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      setError('');
    },
    onDropRejected: () => {
      setError('Only CSV and Excel files are allowed');
    }
  });

  const handleTableChange = (e) => {
    setSelectedTable(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedTable || !file) {
      setError('Please select a table and upload a file');
      return;
    }

    const formData = new FormData();
    formData.append('table', selectedTable);
    formData.append('file', file);

    try {
      const response = await fetch(`${baseUrl}/upload/csv-file`, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        // console.log('File uploaded successfully');
        setSelectedTable('');
        setFile(null);
        setError('');
      } else {
        console.error('Failed to upload file');
        setError('Failed to upload file');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Error uploading file');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        maxWidth: 600,
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
        bgcolor: 'background.paper',
        margin: "20px auto"
      }}
    >
      <Typography variant="h5" component="h2" gutterBottom>
        Upload File and Select Table
      </Typography>

      <FormControl fullWidth>
        <InputLabel id="table-label">Select Table</InputLabel>
        <Select
          labelId="table-label"
          id="table"
          value={selectedTable}
          label="Select Table"
          onChange={handleTableChange}
        >
          {tables.map((table) => (
            <MenuItem key={table} value={table.Tables_in_ig_alpha_db}>
              {table.Tables_in_ig_alpha_db}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box
        {...getRootProps()}
        sx={{
          p: 2,
          border: '1px dashed',
          borderRadius: 2,
          textAlign: 'center',
          cursor: 'pointer',
          bgcolor: 'background.default'
        }}
      >
        <input {...getInputProps()} />
        <Typography variant="body1" color="text.secondary">
          Drag and drop a file here, or click to select one
        </Typography>
        {file && <Typography variant="body2" color="text.primary">File: {file.name}</Typography>}
        {error && <Typography variant="body2" color="error">{error}</Typography>}
      </Box>

      <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!file || !selectedTable}
        sx={{
          backgroundColor: "var(--intgrow-bg)",
          "&:hover": {
            backgroundColor: "var(--intgrow-bg)"
          }
        }}>
        Upload
      </Button>
    </Box>
  );
};

export default UploadFile;
