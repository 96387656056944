import React from 'react'
import { Box,Typography,useTheme } from '@mui/material';
import { tokens } from "../../theme";

const formatCurrencyValue = (value) => {
    const [beforeDecimal, afterDecimal] = value.toString().split(".");
    return { beforeDecimal, afterDecimal };
  };
  
const RenderFormatedValue = ({value,originaFontSize,convertedFontSize}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [digit,currency]=value.split(' ')
        const { beforeDecimal, afterDecimal } = formatCurrencyValue(digit);
        const beforeDecimalValue = {
            color: colors.primaryColor[300],
            fontSize: originaFontSize || "0.75rem",
            fontWeight: "600"
          }

   const afterDecimalValue = {
            color: colors.primaryColor[300],
            fontSize:convertedFontSize || "0.55rem",
            fontWeight: "600"
          }
        return (
          <Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' ,...beforeDecimalValue}}>
              {beforeDecimal}.
              <Typography component="span" sx={{ fontWeight: 'normal', fontSize: '0.8rem',...afterDecimalValue }}>
                {afterDecimal?afterDecimal:0} {currency ? currency:""}
              </Typography>
            </Typography>
          </Box>
        );      
}

export default RenderFormatedValue