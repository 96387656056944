import { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store/dutyCalculatorStore';
import Dashboard from "./scenes/dashboard";
import { Box, CssBaseline, Grid, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
// import Home from "./scenes/pages/Home";
import HomePage from "./scenes/pages/Home/index";
import Login from "./scenes/auth/Login";
import Signup from "./scenes/auth/Signup";
import ForgotPassword from "./scenes/auth/ForgotPassword";
import ResetPassword from "./scenes/auth/ResetPassword";
import ResendEmailVerification from "./scenes/auth/ResendEmailVerification";
import ContactUs from "./scenes/pages/ContactUs";
import Privacy from "./scenes/pages/Privacy";
import TermsAndConditions from "./scenes/pages/TermsAndConditions";
import Glossary from "./components/Glossary/Glossary";
import OTPVerification from "./scenes/auth/OTPVerification";
import styles from './App.module.scss'    
import EmailVerificationPage from "./components/auth/EmailVerification";
import Pricing from "./scenes/pages/Pricing";
import { AuthProvider } from "./context/AuthContext";
import CookieConsent from "./components/CookieConsent/CookieConsent";
import { IoMoonOutline } from "react-icons/io5";
import { MdOutlineLightMode } from "react-icons/md";
import HSCodeFinder from "./scenes/dashboard/HSCodeFinder";
import DashboardLayout from "./layout/DashboardLayout";
import { GoogleOAuthProvider } from '@react-oauth/google';
import MyProfile from "./scenes/dashboard/MyProfile";
import Cookie from "./scenes/pages/Cookie";
import FAQPage from "./scenes/pages/FAQPage";
import Support from "./adminPanel/support/Support";
import Profile from "./adminPanel/profile/Profile";
import CurrentPlan from "./adminPanel/CurrentPlan/CurrentPlan";
import Help from "./adminPanel/Help/Help";
import CurrentPlanType from "./scenes/dashboard/MyPlan";
import DutyCalculatorPage from "./scenes/dashboard/DutyCalculator/DutyCalculator";
import BlogPage from "./scenes/pages/BlogPage";
// import ComingSoon from "./components/CommingSoon/CommingSoon";
import LinkedInRedirect from './SSO/LinkedInRedirect'
import { HsCodeProvider } from "./context/HSCodeContext";
import DutySaverProPage from "./scenes/dashboard/DutySaverPro/DutySaverPro";
import HsCodeHistory from "./scenes/dashboard/history/HsCodeHistory";
import DutySaverProHistory from "./scenes/dashboard/history/DutySaverProHistory";
import DutyCalcHistory from "./scenes/dashboard/history/DutyCalcHistory";
// import ProtectedRoute from "./context/ProtectedRoute";
import { WalletProvider } from './context/WalletsContext';
import CreateBlog from "./components/CommingSoon/createBlog/CreateBlog";
import Disclaimer from "./components/CommingSoon/createBlog/Disclaimer/Disclaimer";
import CMSDashboardLayout from "./layout/CMSLayout";
import SupportRequest from "./scenes/cms/Support";
import CreateNewBlog from "./scenes/cms/Blog/CreateBlog";
import CreateNewBlog2 from "./scenes/cms/Blog/CreateBlog2";
import AllBlogList from "./scenes/cms/Blog/BlogList";
import UploadFile from "./scenes/cms/UploadFile";
import AddFAQ from "./scenes/cms/Faq/AddFAQ";
import FAQList from "./scenes/cms/Faq/FAQList";
import AddGlossary from "./scenes/cms/Glossary/AddGlossary";
import GlossaryList from "./scenes/cms/Glossary/GlossaryList";
import BlogPost from "./scenes/cms/Blog/ViewBlog";
import Welcome from "./scenes/pages/WelcomeMsg";
import UpdateBlog from "./scenes/cms/Blog/UpdateBlog";
import ApiKeyPage from "./scenes/APIDashboard/APIKeyPage";
import UploadLatestUpdatesFile from "./scenes/cms/LatestUpadates";
import UploadMedia from "./scenes/cms/Media/UploadMedia";
import MediaList from "./scenes/cms/Media/AllUploadedMedia";
import Calculator from "./scenes/Offline/OfflineCalculator";


function App() {
  const [theme, colorMode] = useMode();
  const [show, setShow] = useState(false)
  const location = useLocation();

  const dashboardPaths = [
    "/dashboard",
    "/offline-calculator",
    "/hs-code-finder",
    "/duty-saver-pro",
    "/duty-calculator",
    "/profile",
    "/account",
    "/profile-setting",
    "/current-plan",
    "/upgrade-plan",
    "/faq",
    "/help",
    "/support",
    "/duty-calculator-history",
    "/hs-code-finder-history",
    "/duty-saver-pro-history",
    "/cms",
    "/apis",
    "/welcome"
  ];

  const isDashboardRoute = dashboardPaths.some(path => location.pathname.startsWith(path));



  return (
    <HsCodeProvider>
      <WalletProvider>
        <Provider store={store}>
          <Grid className={styles.appContainer} >
            <ColorModeContext.Provider value={colorMode}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                  <Routes>
                    <Route path="/linkedin" element={<LinkedInRedirect />} />
                    <Route path="/blog" element={<BlogPage />} />
                    <Route path="/createblog" element={<CreateBlog />} />
                    <Route path="/disclaimer" element={<Disclaimer />} />
                    <Route path="/apis" element={<AuthProvider><ApiKeyPage /></AuthProvider>} />

                    {/* <Route path="/auth/login" element={<Login />} /> */}
                    {/* <Route path="/home" element={<HomePage />} /> */}

                    <Route path="/" element={<HomePage />} />  
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/terms-and-condition" element={<TermsAndConditions />} />
                    <Route path="/glossary" element={<Glossary />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/auth/signup" element={<Signup />} />
                    <Route path="/auth/login" element={<Login />} />
                    <Route path="/auth/verify-otp" element={<OTPVerification />} />
                    <Route path="/auth/forgot-password" element={<ForgotPassword />} />
                    <Route path="/auth/reset-password/:token" element={<ResetPassword />} />
                    <Route path="/auth/resend-email-verification-link" element={<ResendEmailVerification />} />
                    <Route path="/auth/email-verification/:token" element={<EmailVerificationPage />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/cookie-policy" element={<Cookie />} />
                    <Route path="/welcome" element={<Welcome/>} />

                    <Route element={
                      <AuthProvider>
                        <DashboardLayout />
                      </AuthProvider>}>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/hs-code-finder" element={<HSCodeFinder />} />
                      <Route path="/duty-saver-pro" element={<DutySaverProPage />} />
                      <Route path="/duty-calculator" element={<DutyCalculatorPage />} />


                      <Route path="/offline-calculator" element={<Calculator />} />
                      <Route path="/profile" element={<MyProfile />} />
                      <Route path="/account" element={<Profile />} />
                      <Route path="/current-plan" element={<CurrentPlan />} />
                      <Route path="/upgrade-plan" element={<CurrentPlanType />} />
                      <Route path="/faq" element={<FAQPage />} />
                      <Route path="/help" element={<Help />} />
                      <Route path="/support" element={<Support />} />

                      <Route path="/duty-calculator-history" element={<DutyCalcHistory />} />
                      <Route path="/hs-code-finder-history" element={<HsCodeHistory />} />
                      <Route path="/duty-saver-pro-history" element={<DutySaverProHistory />} />
                    </Route>
                    <Route path="cms" element={<CMSDashboardLayout />} >
                      <Route path="support" element={<SupportRequest/>} />
                      <Route path="blog/create" element={<CreateNewBlog2/>} />
                      <Route path="blog-update/:blogId" element={<UpdateBlog/>} />
                      <Route path="blog/create2" element={<CreateNewBlog/>} />
                      <Route path="blog/post" element={<BlogPost/>} />
                      <Route path="blog/list" element={<AllBlogList/>} />
                      <Route path="upload-file" element={<UploadFile />}/>
                      <Route path="upload-media" element={<UploadMedia />}/>
                      <Route path="uploaded-media-list" element={<MediaList />}/>
                      <Route path="faq/add" element={<AddFAQ />} />
                      <Route path="faq/list" element={<FAQList />} />
                      <Route path="glossary/add" element={<AddGlossary />} />
                      <Route path="glossary/list" element={<GlossaryList />} />
                      <Route path="upload/latest-updates" element={<UploadLatestUpdatesFile/>} />
                    </Route>
                  </Routes>
                </GoogleOAuthProvider>
              </ThemeProvider>
            </ColorModeContext.Provider>
            <CookieConsent />
            {!isDashboardRoute && (
              <Grid className={styles.theme}>
                <Box sx={{ cursor: "pointer" }} onClick={() => { colorMode.toggleColorMode(); setShow(prevShow => !prevShow) }}>
                  {show === true ? <IoMoonOutline fontSize={25} /> : <MdOutlineLightMode fontSize={25} />}
                </Box>
              </Grid>
            )}
          </Grid>
        </Provider>
      </WalletProvider>
    </HsCodeProvider>
  );
}

export default App



