import React, { useState, useEffect } from 'react';
import '../../../components/CommingSoon/createBlog/createblogimage/outletform/supportview.css';
import { formattedDate } from '../../../utils/utils';
import { Modal, Box, Button, Typography, Select, MenuItem,FormControl, InputLabel, TextField, useTheme } from '@mui/material';
import { baseUrl } from '../../../utils/baseURL';
import { tokens } from '../../../theme'

const FAQList = () => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const colors = tokens(mode)

  const [faqList, setFAQList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [selectedFaqList, setSelectedFaqList] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [section, setSection] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [faqDetails, setFAQDetails] = useState({});
  const [selectedFAQ, setSelectedFAQ] = useState(null);

  const fetchData = async () => {
    const token = window.localStorage.getItem('access_token');
    try {
      const response = await fetch((process.env.REACT_APP_API_KEY || "https://intgrow.co")+'/api/v1/faq/all-list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "x-api-key":token
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setFAQList(data?.faqs);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (faqDetails) {
      setSection(faqDetails.section || '');
      setQuestion(faqDetails.question || '');
      setAnswer(faqDetails.answer || '');
    }
  }, [faqDetails]);

  const handleOpenModal = (id) => {
    const fetchFAQDetails = async () => {
      const token = window.localStorage.getItem('access_token');
      try {
        const response = await fetch(`${baseUrl}/faq/details/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key':token
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setFAQDetails(data?.faqs);
        setSelectedFAQ(data?.faqs);
        setOpenModal(true);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchFAQDetails();
  };


  const handleUpdateSubmit = async () => {
    if (!selectedFAQ) return;

    try {
      const token = window.localStorage.getItem('access_token');
      const response = await fetch(`${baseUrl}/faq/update-by-id/${selectedFAQ.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          "x-api-key":token
        },
        body: JSON.stringify({ section, question, answer })
      });

      if (response.ok) {
        fetchData()
        handleCloseModal();
      } else {
        console.error('Failed to update glossary');
      }
    } catch (error) {
      console.error('Failed to update glossary', error);
    }
  };


  const handleDeleteFAQ = async (id) => {
    try {
      const token = window.localStorage.getItem('access_token');
      const response = await fetch(`${baseUrl}/faq/delete-by-id/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "x-api-key":token
        },
      });

      if (response.ok) {
        fetchData()
      } else {
        console.error('Failed to update glossary');
      }
    } catch (error) {
      console.error('Failed to update glossary', error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedFaqList(null);
  };

 
  const handleChangePage = (newPage) => setCurrentPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const currentItems = faqList.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="view-section-container">
      <h1>Tickets</h1>
      <div className="support-container">
        <div className="support-table-wrapper">
          <table className="support-table">
            <thead>
              <tr>
                <th>S.no.</th>
                <th>Section</th>
                <th>Question</th>
                <th>Answer</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={item.id} style={{ borderBottom: '1px solid #dadada' }}>
                  <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                  <td>{item.section}</td>
                  <td>{item.question}</td>
                  <td>{item.answer.slice(0, 80) + "..."}</td>
                  <td style={{ display: "flex" }}>
                    <button className="view-button" onClick={() => handleOpenModal(item.id)}>
                      Update
                    </button>
                    <button className="view-button" onClick={() => handleDeleteFAQ(item.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="pagination">
        <div className="rows-per-page">
          <span>
            Rows per page:
            <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
            </select>
          </span>
        </div>
        <div className="pagination-buttons">
          <button
            className="previous-button"
            onClick={() => handleChangePage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className="next-button"
            onClick={() => handleChangePage(currentPage + 1)}
            disabled={currentPage * rowsPerPage >= faqList.length}
          >
            Next
          </button>
        </div>
      </div>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
          p: 4,
          borderRadius: 2,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          height: "450px",
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          maxWidth: 800,
          width: '100%',
          boxShadow: 3,
          bgcolor: colors.primary[400],
        }}>
          <Box sx={{
            width: "100%",
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}>
            <Typography variant="h5" component="h2" gutterBottom>
              Update Glossary
            </Typography>

            <FormControl fullWidth>
              <InputLabel id="section-label">Section</InputLabel>
              <Select
                labelId="section-label"
                id="section"
                value={section}
                label="Section"
                onChange={(e) => setSection(e.target.value)}
              >
                <MenuItem value="Home">Home</MenuItem>
                <MenuItem value="HS Code Finder">HS Code Finder</MenuItem>
                <MenuItem value="Duty Calculator">Duty Calculator</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Question"
              variant="outlined"
              fullWidth
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
            <TextField
              label="Answer"
              variant="outlined"
              fullWidth
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            />
         
            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2
            }}>
              <Button variant="contained" color="primary"
                sx={{
                  backgroundColor: "var(--intgrow-bg)",
                  "&:hover": {
                    backgroundColor: "var(--intgrow-bg)"
                  }
                }}
                onClick={handleUpdateSubmit}>
                Save Changes
              </Button>
              <Button variant="outlined" color="primary"
                sx={{
                  backgroundColor: "var(--intgrow-bg)",
                  "&:hover": {
                    backgroundColor: "var(--intgrow-bg)"
                  }
                }} onClick={handleCloseModal}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default FAQList;
