import React, { useState} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import EmailIcon from '@mui/icons-material/Email';
import { Box, Button, Stack, TextField, Typography, InputAdornment, useTheme } from '@mui/material';
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../Layout/Logo';
import { baseUrl } from '../../utils/baseURL';
import CircularProgress from '@mui/material/CircularProgress';

const ResendEmailVerificationForm = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const formik = useFormik({
    initialValues: {
      email: '',
      submit: null
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required')
    }),
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        const response = await fetch(`${baseUrl}/auth/resend/verificationEmail`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...values }),
        });
        if (!response.ok) {
          const responseData = await response.json();
          // console.log(responseData)
          const loginError = new Error(responseData.message || 'An error occurred');
          loginError.status = response.status;
          setLoading(false);
          throw loginError;
        }

        const responseData = await response.json();
        setLoading(false);
        helpers.resetForm();
        toast.success('Verify email link send to your Email');
      } catch (err) {
        toast.error(err.message ||'An error occurred. Please try again.')
        // console.log("Error in sending forgot verification link");
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        setLoading(false);

      }
    }
  });
  return (
    <div>
      <ToastContainer />

      <Stack sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            flex: '1 1 auto',
            alignItems: 'center',
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            justifyContent: 'center',
            py: 3,
            gap: "5%"
          }}
        >
          <Box sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}>
            <Box>
            </Box>
            <Box>
              {/* <img src="/assets/images/imp.png" */}
              <img src="/assets/images/intgrow-transpernt-logo.png"
                alt="resend-email" width={300} />
            </Box>

          </Box>
          <Box
            sx={{
              maxWidth: 550,
              px: 3,
              py: '100px',
              width: '100%',
            }}
          >
            <div>
              <Stack
                spacing={1}
                sx={{ mb: 3 }}
              >
                <Typography variant="body2">
                  <Logo logo={"/assets/images/intgrow-transpernt-logo.png"} alt="email verification" />

                </Typography>
                <Typography variant="h4"
                  color="#19508a">
                  Resend Email Verification Link
                </Typography>

              </Stack>

              <form
                noValidate
                onSubmit={formik.handleSubmit}
              >
                <Stack spacing={3}
                  sx={{ color: "white" }}>
                  <TextField
                    error={!!(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    label="Enter Your Email"
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="email"
                    value={formik.values.email}
                    sx={{
                      color: "#444B59",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: theme.palette.mode === "dark" ? "#a19191" : "#a19191",
                        },
                        "&:hover fieldset": {
                          borderColor: theme.palette.mode === "dark" ? "#a19191" : "#a19191",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: theme.palette.mode === "dark" ? "#a19191" : "#a19191",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: theme.palette.mode === "dark" ? "#fff" : "#0E588C",
                      },
                      "& .MuiInputAdornment-root svg": {
                        color: theme.palette.mode === "dark" ? "#fff" : "#0E588C",

                      },
                    }}
                    InputProps={{
                      style: { color: "#000", alignItems: "center" },
                      endAdornment: (
                        <InputAdornment position="start"
                          sx={{ marginRight: "10px" }}>
                          <EmailIcon color="white" />
                        </InputAdornment>
                      ),
                    }}
                  />


                </Stack>

                {formik.errors.submit && (
                  <Typography
                    color="error"
                    sx={{ mt: 3 }}
                    variant="body2"
                  >
                    {formik.errors.submit}
                  </Typography>
                )}
                <Button
                  fullWidth
                  size="large"
                  sx={{
                    mt: 3, color: "orange", backgroundColor: "#19508a",
                    '&:hover': {
                      backgroundColor: "#19508a",
                    },
                  }}
                  type="submit"
                  variant="contained"
                >
                  Send
                </Button>

              </form>
              {/* {error && <span>{error}</span>} */}
              {data && <span>Login successful.</span>}
              <Typography
                color="#19508a"
                variant="body2"
                sx={{mt:3}}
              >
                Don&apos;t have an account?
                &nbsp;
                <Link to="/auth/signup"
                  style={{ color: "#19508a" }}
                >
                  Create Account
                </Link>
              </Typography>
            </div>
          </Box>

        </Box>

      </Stack >
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  )
}

export default ResendEmailVerificationForm
