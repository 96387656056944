import React, {useState, useEffect, useContext, useCallback,} from 'react';
import {
    Grid, useTheme, Box, Typography, TextField, CircularProgress, Modal, Switch, FormControlLabel,
} from '@mui/material';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './dutycalculator.style.css';
import CustomModal from './CustomModel';
import BrowseHsCode2 from './BrowseHsCode2';
import DutyCalcDetailsTable from './DutyCalcDetailsTable';
import DutyCalculationShipingDetails from './ShipingDetails';
import CountrySelect from '../Select/SelectCountry';
import SelectCurrency from '../Select/SelectCurrency';
import DutySaverStripe from '../DutySaverPro/DutySaverStripe';
import {tokens} from '../../theme'
import {baseUrl} from '../../utils/baseURL';
import {useWallet} from "../../context/WalletsContext";
import {HsCodeContext} from '../../context/HSCodeContext';
import {setFormData} from '../../store/dutyCalculatorSlice';
import {currencyConvert, calculateValues} from '../../utils/utils';

const formatValue = (value) => {
    const [integerPart, decimalPart] = value?.toString()?.split('.');
    const formateNumber = new Intl.NumberFormat('en-US').format(integerPart);
    return (<>
        <span style={{fontWeight: 'bold', fontSize: '1.2rem'}}>{formateNumber}</span>
        {decimalPart && (<span style={{fontWeight: 'normal', fontSize: '0.9rem'}}>{'.' + decimalPart.slice(0, 3).padEnd(3, '0')}</span>)}
    </>);
}

function debounce(fn, delay) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => fn(...args), delay);
    };
}

function getCurrencyValue(currencyResponse) {
    const currency = currencyResponse.find(c => c.unit === c.value);
    if (currency) {
        return currency.currency;
    }
    return null;
}


const DutyCalculator = ({setScrollToTop}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const theme = useTheme()
    const mode = theme.palette.mode
    const colors = tokens(mode)
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const {fetchWallets} = useWallet();
    const [saveUpto, setSaveUpto] = useState()
    const [currentAttr, setCurrentAttr] = useState(null);
    const [currentSelectedValue, setCurrentSelectedValue] = useState('');
    const {hsCodeDetails, setHsCodeDetails} = useContext(HsCodeContext);
    const [hscode, setHSCode] = useState()
    const [importingCountry, setImportingCountry] = useState("");
    const [exportingCountry, setExportingCountry] = useState("");
    const [productValue, setProductValue] = useState("")
    const [selectedCurrency, setSelectedCurrency] = useState("null");
    const [urlInputResponse, setUrlInputResponse] = useState('');
    const [tradeFlow, setTradeFlow] = useState('select');
    const [currencyResponse, setCurrencyResponse] = useState([])
    const [showDutyCalResult, setShowDutyCalcResult] = useState(true)
    const [incoTerm, setIncoTerm] = useState('');
    const [openConfirmationModel, setOpenConfirmationModel] = useState(false);
    const [ftaPoint, setFtaPoint] = useState(null);
    const [openDutyPointDeductionModel, setOpenDutyPointDeductionModel] = useState(false)
    const [openErrorModel, setOpenErrorModel] = useState(false)
    const [tradflowModelModel, setTradflowModelModel] = useState(false)
    const [fields, setFields] = useState([]);
    const [saverProResponse, setSaverProResponse] = useState([])
    const [showDutySaverProResult, setshowDutySaverProResult] = useState(false)
    const [showDutyCalcForm, setShowDutyCalcForm] = useState(false)
    const [payloadData, setPayloadData] = useState({
        cifValue: "",
        fobValue: "",
        exwValue: "",
        mode: "",
        originCharges: "",
        exwIntFreight: "",
        originFreight: "",
        exwInsuranceCharges: "",
        cifIntFreight: "",
        cifInsuranceCharges: "",
        fobIntFreight: "",
        fobInsuranceCharges: ""
    });
    const InputData = {
        selectedCurrency,
        importingCountry,
        exportingCountry,
        productValue
    };
    const cyn = selectedCurrency?.currency;
    const cynRate = selectedCurrency?.value;

    const debouncedFetch = useCallback(debounce((hsCode, importingCountry) => {
        getIGSTDesc(hsCode, importingCountry);
    }, 1000), []);

    const handleDynamicInputChange = (attr, value) => {
        const label = attr?.values?.find(item => item.value === value) || {label: '', value: ''};
        setFields(prevFields => {
            const fieldIndex = prevFields.findIndex(item => item.key === attr.field);
            if (fieldIndex !== -1) {
                const updatedFields = [...prevFields];
                updatedFields[fieldIndex] = {
                    key: attr.field,
                    lbl: attr.label,
                    selected: true,
                    txt: label.label,
                    val: value
                };
                return updatedFields;
            } else {
                return [
                    ...prevFields,
                    {
                        key: attr.field,
                        lbl: attr.label,
                        selected: true,
                        txt: label.label,
                        val: value
                    }
                ];
            }
        });
    };

    const handleSelectChange = (attr, value) => {
        const label = attr?.values?.find(item => item.value === value) || {label: '', value: ''};
        setFields(prevFields => {
            const fieldIndex = prevFields.findIndex(item => item.key === attr.field);
            if (fieldIndex !== -1) {
                const updatedFields = [...prevFields];
                updatedFields[fieldIndex] = {
                    key: attr.field,
                    lbl: attr.label,
                    selected: true,
                    txt: label.label,
                    val: value
                };
                return updatedFields;
            } else {
                return [
                    ...prevFields,
                    {
                        key: attr.field,
                        lbl: attr.label,
                        selected: true,
                        txt: label.label,
                        val: value
                    }
                ];
            }
        });
    };

    const openDynamicModal = (attr, selectedValue) => {
        const selectedLabel = attr.values.find(v => v.value === selectedValue)?.label || '';
        setCurrentAttr(attr);
        setCurrentSelectedValue(selectedValue);
    };

    const closeDynamicModal = () => {
        setCurrentAttr(null);
        setCurrentSelectedValue('');
    };

    const toParseInt = (jsonString = {} || '0') => {
        //iterate through the object and parse int
        if (jsonString && typeof jsonString === 'object') {
            for (let key in jsonString) {
                if (jsonString.hasOwnProperty(key) && jsonString[key] && !isNaN(jsonString[key])) {
                    jsonString[key] = parseFloat(jsonString[key]);
                }
            }
        } else if (jsonString && typeof jsonString === 'string' && !isNaN(jsonString)) {
            jsonString = parseFloat(jsonString);
        }
        return jsonString;
    };

    const getIGSTDesc = async (hsCode, importingCountry) => {
        try {
            if (!hsCode || !importingCountry?.value) {
                return;
            }
            const response = await fetch(`${baseUrl}/getUserInput?hs=${hsCode}&imp=${importingCountry?.value}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();

            if (!response.ok) {
                // console.log('Error in fetch igst description');
                throw new Error('Error in fetch igst description');
            }

            setUrlInputResponse(data);
            return data;
        } catch (error) {
            setUrlInputResponse("")
            // setError("An error occurred:change HS code.")
            // setOpenErrorModel(true)
            console.error('An error occurred:', error);
        }
    };

    const getCurrency = async () => {
        if (!importingCountry?.value) {
            return;
        }
        try {

            const response = await fetch(`${baseUrl}/country/currency?imp=${importingCountry?.value}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            const data = await response.json();
            // console.log("data", data)
            if (!response.ok) {
                // console.log('Error in fetch igst description');
                throw new Error('Error in fetch igst description');
            }

            setCurrencyResponse(data)
            return data;
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleDutyCalculation = async () => {

        const cyn = selectedCurrency.currency;
        const cynRate = toParseInt(selectedCurrency.value);
        const impRate = 1;
        const transformedFields = fields.reduce((acc, field) => {
            acc[field.key] = field.val;
            return acc;
        }, {});

        let originCharges = parseFloat(payloadData.originCharges) || 0,
            exwInsuranceCharges = parseFloat(payloadData.exwInsuranceCharges) || 0,
            cifInsuranceCharges = parseFloat(payloadData.cifInsuranceCharges) || 0,
            fobInsuranceCharges = parseFloat(payloadData.fobInsuranceCharges) || 0,

            exwIntFreight = parseFloat(payloadData.exwIntFreight) || 0,
            cifIntFreight = parseFloat(payloadData.cifIntFreight) || 0,
            fobIntFreight = parseFloat(payloadData.fobIntFreight) || 0,

            originFreight = parseFloat(payloadData.originFreight) || 0,
            productVal = parseFloat(payloadData.productValue) || 0;


        const {newExwValue, newFobValue, newCifValue} = calculateValues(incoTerm, productValue, payloadData);

        const data = {
            hscode: hsCodeDetails?.hsCode,
            import_country: importingCountry?.value,
            export_country: exportingCountry?.value,
            CIFVALUE: (newCifValue * cynRate),
            CIF: Number(productValue),
            FOBVALUE: (newFobValue * cynRate),
            cyn: cyn,
            cyn_rate: cynRate,
            extras: fields,
            imp_cyn: getCurrencyValue(currencyResponse).toLowerCase(),
            info: {
                CIFVALUE: (newCifValue * cynRate),
                CIF: (newCifValue * cynRate),
                FOBVALUE: (newFobValue * cynRate),
                cifInsuranceCharges: cifInsuranceCharges || "",
                cifIntFreight: cifIntFreight || "",
                tradeFlow: tradeFlow || "Importer",
                import_country: (importingCountry?.value || "").toUpperCase(),
                export_country: (exportingCountry?.value || "").toUpperCase(),
                hscode: Number(hsCodeDetails?.hsCode),
                findHSN: "",
                cyn: selectedCurrency?.country,
                exwInsuranceCharges: exwInsuranceCharges || "",
                exwIntFreight: exwIntFreight || "",
                fobInsuranceCharges: fobInsuranceCharges || "",
                fobIntFreight: fobIntFreight || "",
                inco_term: incoTerm || "CIF",
                mode: payloadData?.mode || "Sea",
                originCharges: originCharges || "",
                originFreight: originFreight || "",
                "popup-confirm-duty-calc": "",
                productValue: Number(productValue),
                ...transformedFields

            },
            mode: payloadData?.mode || "Sea",
            ...transformedFields
        };


        try {
            const token = window.localStorage.getItem("access_token")
            const response = await fetch(`${baseUrl}/dutyCalculator/getDuty`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "x-api-key": token
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                const responseData = await response.json()
                throw new Error('Network response was not ok ' + responseData?.message);
            }

            const result = await response.json();
            // console.log("result", result)
            setOpenDutyPointDeductionModel(false)
            setResponse(result);
            setShowDutyCalcResult(true)
            setShowDutyCalcResult(false)

            setShowDutyCalcForm(true)

            fetchWallets()
        } catch (error) {
            setError(error.message);
            setOpenDutyPointDeductionModel(false)
            setOpenErrorModel(true)
        }
    };

    const handleDutySaverProResultPage = async () => {

        if (response?.hasFta === true) {

            setLoading(true)
            const transformedFields = fields.reduce((acc, field) => {
                acc[field.key] = field.val;
                return acc;
            }, {});

            const {newExwValue, newFobValue, newCifValue} = calculateValues(incoTerm, productValue, payloadData);

            let originCharges = parseFloat(payloadData.originCharges) || 0,
                exwInsuranceCharges = parseFloat(payloadData.exwInsuranceCharges) || 0,
                cifInsuranceCharges = parseFloat(payloadData.cifInsuranceCharges) || 0,
                fobInsuranceCharges = parseFloat(payloadData.fobInsuranceCharges) || 0,

                exwIntFreight = parseFloat(payloadData.exwIntFreight) || 0,
                cifIntFreight = parseFloat(payloadData.cifIntFreight) || 0,
                fobIntFreight = parseFloat(payloadData.fobIntFreight) || 0,

                originFreight = parseFloat(payloadData.originFreight) || 0,
                productVal = parseFloat(payloadData.productValue) || 0;

            const cyn = selectedCurrency.currency;
            const cynRate = toParseInt(selectedCurrency.value);
            const impRate = 1;

            const data = {
                hscode: hsCodeDetails?.hsCode,
                import_country: importingCountry?.value,
                export_country: exportingCountry?.value,
                CIFVALUE: (newCifValue * cynRate),
                CIF: Number(productValue),
                FOBVALUE: (newFobValue * cynRate),
                cyn: cyn,
                cyn_rate: cynRate,
                extras: fields,
                imp_cyn: getCurrencyValue(currencyResponse).toLowerCase(),
                info: {
                    "": "",
                    CIFVALUE: (newCifValue * cynRate),
                    CIF: (newCifValue * cynRate),
                    FOBVALUE: (newFobValue * cynRate),
                    cifInsuranceCharges: cifInsuranceCharges || "",
                    cifIntFreight: cifIntFreight || "",
                    tradeFlow: tradeFlow || "Importer",
                    import_country: (importingCountry?.value || "").toUpperCase(),
                    export_country: (exportingCountry?.value || "").toUpperCase(),
                    hscode: Number(hsCodeDetails?.hsCode),
                    findHSN: "",
                    cyn: selectedCurrency?.country,
                    exwInsuranceCharges: exwInsuranceCharges || "",
                    exwIntFreight: exwIntFreight || "",
                    fobInsuranceCharges: fobInsuranceCharges || "",
                    fobIntFreight: fobIntFreight || "",
                    inco_term: incoTerm || "CIF",
                    mode: payloadData?.mode || "Sea",
                    originCharges: originCharges || "",
                    originFreight: originFreight || "",
                    "popup-confirm-duty-calc": "",
                    productValue: Number(productValue),
                    ...transformedFields
                },
                mode: payloadData?.mode || "Sea",
                ...transformedFields


            };

            try {
                const token = window.localStorage.getItem("access_token")
                // const token = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpZCI6ODQsImVtYWlsIjoibmFyZW5kcmFvdHVzb25lQGdtYWlsLmNvbSIsImZpcnN0X25hbWUiOiJOYXJlbmRyYSIsImxhc3RfbmFtZSI6IlNpbmdoIiwicGhvdG9fdXJsIjoiaHR0cHM6Ly9wbGFjZWhvbGQuY28vMTAweDEwMC8wMDAwMDAvRkZGRkZGPyZmb250PXJvYm90byZ0ZXh0PW51Iiwicm9sZSI6ImFkbWluIiwiaWF0IjoxNzIzNDQ1MzAwLCJleHAiOjE3MjM1MzE3MDB9.1D7tpZV1cALC1RFKjvzDxSWKT98Ra58Ke_qGiDLObxjbQ89d4L7mHIIUsc6vrdBdqaIcoLbvVbXUZtWKWYy9Rw"

                const response = await fetch(`${baseUrl}/dutyCalculator/getFTAPoints`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "x-api-key": token
                    },
                    body: JSON.stringify(data)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }

                const result = await response.json();
                // console.log("result", result)
                setFtaPoint(result);
                let save = result?.save_upto;
                let total_duty = result?.total_import_duty - result?.info?.CIFVALUE;
                let save_per = ((save / total_duty) * 100).toFixed(2);
                setSaveUpto(save_per)
                setOpenConfirmationModel(true)
                setLoading(false)

            } catch (error) {
                setError(error.message);
                setLoading(false)

            }
        } else {
            setSaveUpto(0);
            setFtaPoint({save_upto: 0, charge: 1});
            setOpenConfirmationModel(true)
            setLoading(false)
        }
    };

    const handleConfirm = async () => {
        setLoading(true)
        const transformedFields = fields.reduce((acc, field) => {
            acc[field.key] = field.val;
            return acc;
        }, {});
        const cyn = selectedCurrency.currency;
        const cynRate = toParseInt(selectedCurrency.value);
        const impRate = 1;

        const {newExwValue, newFobValue, newCifValue} = calculateValues(incoTerm, productValue, payloadData);

        let originCharges = parseFloat(payloadData.originCharges) || 0,
            exwInsuranceCharges = parseFloat(payloadData.exwInsuranceCharges) || 0,
            cifInsuranceCharges = parseFloat(payloadData.cifInsuranceCharges) || 0,
            fobInsuranceCharges = parseFloat(payloadData.fobInsuranceCharges) || 0,

            exwIntFreight = parseFloat(payloadData.exwIntFreight) || 0,
            cifIntFreight = parseFloat(payloadData.cifIntFreight) || 0,
            fobIntFreight = parseFloat(payloadData.fobIntFreight) || 0,

            originFreight = parseFloat(payloadData.originFreight) || 0,
            productVal = parseFloat(payloadData.productValue) || 0;


        const data = {
            hscode: hsCodeDetails?.hsCode,
            import_country: importingCountry?.value,
            export_country: exportingCountry?.value,
            CIFVALUE: (newCifValue * cynRate),
            CIF: Number(productValue),
            FOBVALUE: (newFobValue * cynRate),
            cyn: cyn,
            cyn_rate: cynRate,
            extras: fields,
            fta_key: ftaPoint.fta_key,
            imp_cyn: getCurrencyValue(currencyResponse).toLowerCase(),
            info: {
                "": "",
                CIFVALUE: (newCifValue * cynRate),
                CIF: (newCifValue * cynRate),
                FOBVALUE: (newFobValue * cynRate),
                cifInsuranceCharges: cifInsuranceCharges || "",
                cifIntFreight: cifIntFreight || "",
                tradeFlow: tradeFlow || "Importer",
                import_country: (importingCountry?.value || "").toUpperCase(),
                export_country: (exportingCountry?.value || "").toUpperCase(),
                hscode: Number(hsCodeDetails?.hsCode),
                findHSN: "",
                cyn: selectedCurrency?.country,
                exwInsuranceCharges: exwInsuranceCharges || "",
                exwIntFreight: exwIntFreight || "",
                fobInsuranceCharges: fobInsuranceCharges || "",
                fobIntFreight: fobIntFreight || "",
                inco_term: incoTerm || "CIF",
                mode: payloadData?.mode || "Sea",
                originCharges: originCharges || "",
                originFreight: originFreight || "",
                "popup-confirm-duty-calc": "",
                productValue: Number(productValue),
                ...transformedFields
            },
            mode: payloadData?.mode || "Sea",
            ...transformedFields
        };
        try {
            const token = window.localStorage.getItem("access_token")
            // const token = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpZCI6ODQsImVtYWlsIjoibmFyZW5kcmFvdHVzb25lQGdtYWlsLmNvbSIsImZpcnN0X25hbWUiOiJOYXJlbmRyYSIsImxhc3RfbmFtZSI6IlNpbmdoIiwicGhvdG9fdXJsIjoiaHR0cHM6Ly9wbGFjZWhvbGQuY28vMTAweDEwMC8wMDAwMDAvRkZGRkZGPyZmb250PXJvYm90byZ0ZXh0PW51Iiwicm9sZSI6ImFkbWluIiwiaWF0IjoxNzIzNDQ1MzAwLCJleHAiOjE3MjM1MzE3MDB9.1D7tpZV1cALC1RFKjvzDxSWKT98Ra58Ke_qGiDLObxjbQ89d4L7mHIIUsc6vrdBdqaIcoLbvVbXUZtWKWYy9Rw"

            const response = await fetch(`${baseUrl}/dutyCalculator/getFTA`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "x-api-key": token
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            const result = await response.json();
            // console.log("result", result)
            setFtaPoint(result);
            fetchWallets()
            dispatch(setFormData({formData: result, otherData: {exportingCountry, selectedCurrency, productValue}}));
            setSaverProResponse(result)
            setShowDutyCalcForm(false)
            setShowDutyCalcResult(false)
            setshowDutySaverProResult(true)
            setLoading(false)
            setOpenConfirmationModel(false)

            // setTimeout(() => {
            //     navigate("/duty-saver-pro");
            // }, 2000);
        } catch (error) {
            setLoading(false)
            setError(error.message);
        }
    };

    const handleClose = () => {
        setOpenConfirmationModel(false)
    }

    const handleDutyPointDeductionClose = () => {
        setOpenDutyPointDeductionModel(false)
    };

    const handleDutyDeductionModel = () => {
        const missingFields = [];

        if (tradeFlow !== "importer" && tradeFlow !== "exporter") missingFields.push('Trade flow (Who are you?)');
        if (!importingCountry?.value) missingFields.push('Importing Country');
        if (!exportingCountry?.value) missingFields.push('Exporting Country');
        if (!hsCodeDetails?.hsCode) missingFields.push('Product Name or HS Code');
        if (!selectedCurrency?.value) missingFields.push('Currency');
        if (!productValue) missingFields.push('Product Value');
        urlInputResponse && urlInputResponse.forEach(attr => {
            const field = fields.find(item => item.key === attr.field);
            if (!field) missingFields.push(attr.label);
        });
        if (!incoTerm) missingFields.push('INCO Term');

        if (incoTerm === 'EXW' && (payloadData.originCharges === "" || payloadData.originCharges < 0)) {
            missingFields.push(`Origin Charges`);
        }
        if (incoTerm === 'EXW' && (payloadData.originFreight === "" || payloadData.originFreight < 0)) {
            missingFields.push(`Origin Freight`);
        }
        if (incoTerm === 'EXW' && (payloadData.exwIntFreight === "" || payloadData.exwIntFreight < 0)) {
            missingFields.push(`International Freight`);
        }
        if (incoTerm === 'EXW' && (payloadData.exwInsuranceCharges === "" || payloadData.exwInsuranceCharges < 0)) {
            missingFields.push(`Insurance Charges`);
        }
        if (incoTerm === 'CIF' && (payloadData.cifIntFreight === "" || payloadData.cifIntFreight < 0)) {
            missingFields.push(`International Freight`);
        }
        if (incoTerm === 'CIF' && (payloadData.cifInsuranceCharges === "" || payloadData.cifInsuranceCharges < 0)) {
            missingFields.push(`Insurance Charges`);
        }
        if (incoTerm === 'FOB' && (payloadData.fobIntFreight === "" || payloadData.fobIntFreight < 0)) {
            missingFields.push(`International Freight`);
        }
        if (incoTerm === 'FOB' && (payloadData.fobInsuranceCharges === "" || payloadData.fobInsuranceCharges < 0)) {
            missingFields.push(`Insurance Charges`);
        }
        if (!payloadData?.mode) missingFields.push('Mode of Transport');

        if (missingFields.length > 0) {
            let list = missingFields.join('</li><li>')
            list = `<ul><li>${list}</li></ul>`
            setError(`<span>Please ensure the following fields are completed: ${list}</span>`);
            setOpenErrorModel(true);
            return;
        }

        setOpenDutyPointDeductionModel(true);
    };

    const handleCloseErrorModel = () => {
        setOpenErrorModel(false)
    };

    const handleOpenTradflowModel = () => setTradflowModelModel(true);

    const handleCloseTradflowModel = () => setTradflowModelModel(false);

    const handleSaveTradflow = () => {
        handleCloseTradflowModel();
    };

    const handleSwitchChange = (event) => {
        setTradeFlow(event.target.checked ? event.target.value : '');
    };

    const handleModify = () => {
        setShowDutyCalcForm(false)
        setshowDutySaverProResult(false)
        setShowDutyCalcResult(true)
    };

    useEffect(() => {
        if (productValue && incoTerm && selectedCurrency) {
            setProductValue("")
            setHsCodeDetails({
                importingCountry: '',
                exportingCountry: '',
                hsCode: '',
            });
            setExportingCountry("")
            setSelectedCurrency("")
            setUrlInputResponse("")
            setPayloadData("")
            setTradeFlow("")
            setIncoTerm("")
            setPayloadData({
                cifValue: "",
                fobValue: "",
                exwValue: "",
                mode: "",
                originCharges: "",
                exwIntFreight: "",
                originFreight: "",
                exwInsuranceCharges: "",
                cifIntFreight: "",
                cifInsuranceCharges: "",
                fobIntFreight: "",
                fobInsuranceCharges: ""
            });
            setFields([]);
        }


    }, [importingCountry.value]);

    useEffect(() => {
        if (hsCodeDetails?.hsCode && importingCountry?.value) {
            debouncedFetch(hsCodeDetails.hsCode, importingCountry);
        }
    }, [hsCodeDetails?.hsCode, importingCountry?.value]);

    useEffect(() => {
        getCurrency()
    }, [importingCountry?.value]);

    useEffect(() => {
        if (hsCodeDetails.importingCountry) {
            // console.log("hsCodeDetails", hsCodeDetails)
            setImportingCountry(hsCodeDetails.importingCountry)
            setExportingCountry(hsCodeDetails.exportingCountry)
            setHSCode(hsCodeDetails?.hsCode)
            setProductValue(hsCodeDetails?.productValue)
            setSelectedCurrency(hsCodeDetails?.selectedCurrency)
        }
    }, []);

    return (
        <>
            {
                showDutyCalResult &&
                <Box>
                    <div container className="duty-calculator-page-main-container">
                        <Box p={3} boxShadow={3} borderRadius={5} className="duty-calculator-section1" sx={{
                            backgroundColor: colors.primary[400]
                        }}>
                            <Box sx={{width: "100%"}}>
                                <Typography variant="h3" style={{fontWeight: '700'}}>
                                    Shipment Details
                                </Typography>
                                <Typography gutterBottom>
                                    Who are you?
                                </Typography>
                            </Box>

                            <div className="selectContainer">
                                <Typography className="duty-calc-label">I am</Typography>
                                <select id="importExportSelect" value={tradeFlow} onClick={handleOpenTradflowModel}
                                        readOnly required>
                                    <option value="select"> Select</option>
                                    <option value="importer">Importer</option>
                                    <option value="exporter">Exporter</option>
                                </select>

                                <Modal
                                    open={tradflowModelModel}
                                    onClose={handleCloseTradflowModel}
                                    aria-labelledby="import-export-modal"
                                    aria-describedby="select-importer-or-exporter"
                                >
                                    <div style={{padding: 20, backgroundColor: colors.primary[400], margin: '10% auto', width: 300, borderRadius: '7px'}}>
                                        <Typography variant="h6" sx={{textAlign: 'center', width: '100%'}}>Who are you?</Typography>
                                        <div className="switch-option">
                                            <FormControlLabel
                                                label="Importer"
                                                control={<Switch checked={tradeFlow === 'importer'} onChange={handleSwitchChange} value="importer"/>}
                                                labelPlacement="start"
                                                sx={{
                                                    justifyContent: "space-between"
                                                }}
                                            />
                                            <FormControlLabel
                                                label="Exporter"
                                                control={<Switch checked={tradeFlow === 'exporter'} onChange={handleSwitchChange} value="exporter"/>}
                                                labelPlacement="start"
                                                sx={{
                                                    justifyContent: "space-between"
                                                }}
                                            />
                                        </div>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                textAlign: 'center', width: '100%', backgroundColor: 'var(--intgrow-primary-color)',
                                                '&:hover': {backgroundColor: 'var(--intgrow-primary-color) !important'}

                                            }}
                                            onClick={handleSaveTradflow}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Modal>
                            </div>
                            <div className="selectContainer">
                                <Typography className="duty-calc-label">Importing Country</Typography>
                                <CountrySelect
                                    placeholder="Select Country"
                                    type="importing_country"
                                    setSelectedCountry={setImportingCountry}
                                    selectedCountry={importingCountry}
                                    padding="15px 5px"
                                />
                            </div>
                            <div className="selectContainer">
                                <Typography className="duty-calc-label">Exporting Country</Typography>
                                <CountrySelect
                                    placeholder="Select Country"
                                    type="exporting_country"
                                    setSelectedCountry={setExportingCountry}
                                    selectedCountry={exportingCountry}
                                    selectedImp={importingCountry}
                                    padding="15px 5px"
                                />
                            </div>
                        </Box>
                        <Box p={3} boxShadow={3} borderRadius={5} className="duty-calculator-section2" sx={{
                            backgroundColor: colors.primary[400]
                        }}>
                            <Typography variant="h3" style={{fontWeight: '700', width: "100%", margin: "0"}}>
                                Products Details
                            </Typography>
                            <Typography gutterBottom style={{width: "100%"}}>
                                What's my product code?
                            </Typography>
                            <Typography className="duty-calc-label" style={{width: "100%"}}> Product Name or HS Code</Typography>
                            <BrowseHsCode2 key={importingCountry.value} importingCountry={importingCountry} exportingCountry={exportingCountry} hscode={hscode}/>

                            <Grid container spacing={2} justifyContent="space-between">
                                <Grid item xs={12} md={5.5}>
                                    <Typography className="duty-calc-label"> Currency</Typography>
                                    <SelectCurrency inputPadding={"16px 14px"} selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} imp={importingCountry?.value}/>
                                </Grid>
                                <Grid item xs={12} md={5.5}>
                                    <Typography className="duty-calc-label">Value of Product</Typography>
                                    <TextField
                                        placeholder="Enter Value"
                                        type="number"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        value={productValue}
                                        onChange={(e) => setProductValue(e.target.value)}
                                    />
                                </Grid>

                                {(urlInputResponse && urlInputResponse.length > 0) && urlInputResponse.map((attr, index) => {
                                    const isRequired = attr.is_required ? true : false;
                                    const type = attr.type || 'none';
                                    const selectedField = fields.find(item => item.key === attr.field);
                                    const selectedValue = selectedField ? selectedField.val : '';
                                    const selectedLabel = selectedField ? selectedField.txt : '';
                                    return (
                                        <Grid item xs={12} md={5.5} key={index}>
                                            <Box className="form-group pre-fill-igst" sx={{mb: 2}}>
                                                <Typography className="duty-calc-label" sx={{textWrap: "nowrap", overflow: "hidden"}}>
                                                    {attr.label}-
                                                    {attr.pre_fix && <span className="userinput-align">(in {attr.pre_fix})</span>}
                                                </Typography>
                                                <Box className="btn-group col" sx={{display: 'flex', alignItems: 'center'}}>
                                                    {type === 'none' || type === 'text' || type === 'number' ? (
                                                        <TextField
                                                            id={attr.field}
                                                            type={type === 'number' ? 'number' : 'text'}
                                                            // label={attr.label}
                                                            required={isRequired}
                                                            value={selectedValue}
                                                            variant="outlined"
                                                            fullWidth
                                                            onChange={(e) => handleDynamicInputChange(attr, e.target.value)}
                                                        />
                                                    ) : type === 'droplist' ? (
                                                        <TextField
                                                            variant="outlined"
                                                            required={isRequired}
                                                            fullWidth
                                                            value={selectedLabel}
                                                            onClick={(e) => openDynamicModal(attr, e.target.value)}
                                                            readOnly
                                                            InputProps={{style: {cursor: 'pointer'}}}
                                                            placeholder={`Select ${attr.label}`}
                                                        />
                                                    ) : null}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                                {currentAttr && (
                                    <CustomModal
                                        open={openDynamicModal}
                                        onClose={closeDynamicModal}
                                        attr={currentAttr}
                                        selectedValue={currentSelectedValue}
                                        handleSelectChange={handleSelectChange}
                                    />
                                )}

                            </Grid>
                        </Box>
                    </div>
                    <Box boxShadow={3} className="duty-calculator-section3" sx={{
                        backgroundColor: colors.primary[400]
                    }}>
                        <Typography variant='h3' style={{fontWeight: '700'}}>Shipping Details</Typography>
                        <Typography gutterBottom>
                            Enter the shipping Details
                        </Typography>
                        <Grid container spacing={2} justifyContent="space-between">
                            <DutyCalculationShipingDetails productValue={productValue} payloadData={payloadData} setPayloadData={setPayloadData} setIncoTerm={setIncoTerm} incoTerm={incoTerm}/>
                            <Grid item xs={12} md={4}>
                                <Typography className="duty-calc-label" sx={{visibility: "hidden"}}>Calculate Duty</Typography>
                                <Button variant="contained" sx={{
                                    backgroundColor: 'var(--intgrow-primary-color)', width: "100%", padding: '15px',
                                    fontWeight: "700",
                                    '&:hover': {backgroundColor: 'var(--intgrow-primary-color) !important'}
                                }}
                                        onClick={handleDutyDeductionModel}
                                >Calculate Duty</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            }

            {/* <DutyCalcDetailsTable handleDutySaverProResultPage={handleDutySaverProResultPage} setShowDutyCalcResult={setShowDutyCalcResult} handleModify={handleModify} getDutyResponse={response} inputData={InputData} exportingCountry={exportingCountry} importingCountry={importingCountry} currencyResponse={currencyResponse} /> */}
            {
                showDutyCalcForm &&
                <DutyCalcDetailsTable setScrollToTop={setScrollToTop} handleDutySaverProResultPage={handleDutySaverProResultPage} setShowDutyCalcResult={setShowDutyCalcResult} handleModify={handleModify} getDutyResponse={response} inputData={InputData} exportingCountry={exportingCountry} importingCountry={importingCountry} currencyResponse={currencyResponse}/>
            }
            {showDutySaverProResult && <DutySaverStripe handleModify={handleModify} inputData={InputData} saveDutyResponse={saverProResponse} currencyResponse={currencyResponse}/>}

            <Dialog open={openConfirmationModel} onClose={handleClose}
                    sx={{
                        '& .MuiPaper-root': {
                            maxWidth: 'fit-content',
                            backgroundColor: colors.primary[400],
                            backgroundImage: "none !important"
                        }
                    }}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <img src="/assets/images/duty_saver_msg2.png" alt="icon" width={100} height={100}/>
                    <img src="/assets/images/duty_saver_msg1.png" alt="icon" width={100} height={100}/>
                </div>

                <DialogTitle sx={{padding: "10px 24px"}}>
                    {ftaPoint?.save_upto > 0 ?
                        <div className='save-huge-msg-section'>
                            <h4 className='unlock-huge-saving' style={{color: colors.primaryColor[100]}}>Unlock Huge Savings! upto {saveUpto} % Import tax.</h4>
                            <p className='save-upto' style={{color: colors.primaryColor[100]}}>Save upto {ftaPoint?.save_upto && formatValue(ftaPoint?.save_upto)} {getCurrencyValue(currencyResponse)?.toUpperCase()} ( {formatValue(currencyConvert(ftaPoint?.save_upto, cynRate))}) {cyn} on your transactions !</p>
                            <p className="proceed-now" style={{color: colors.primaryColor[100]}}>Proceed now to maximize your profits</p>
                        </div> : <div className='save-huge-msg-section'>
                            <h4 className='unlock-huge-saving' style={{color: colors.primaryColor[100]}}>
                                Sorry, no import tax savings available for this transaction !
                            </h4>
                        </div>}
                </DialogTitle>
                <DialogContent>
                    {ftaPoint?.save_upto > 0 && <DialogContentText>
                        <div className="duty-saver-points-deduction-notes" style={{color: colors.primaryColor[100]}}>
                            <Typography className='notes'>Notes</Typography>
                            <p className='point-deduction-msg' style={{padding: "10px", color: colors.primaryColor[100]}}>A minimum of <span className='highlight-msg'>{ftaPoint?.charge} credit </span> will be deduct from your <span className='highlight-msg'>Duty Calculator</span>credits. </p>
                        </div>
                        <p className='point-want-to-proceed' style={{color: colors.primaryColor[100]}}> Do you want to proceed?</p>
                    </DialogContentText>}

                    <div style={{display: "flex", flexDirection: "column"}}>
                        {ftaPoint?.save_upto > 0 && <Button onClick={handleConfirm} className='point-deduction-yes-btn' autoFocus>Yes</Button>}
                        <Button onClick={handleClose} className='point-deduction-cancel-btn'>Cancel</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={openDutyPointDeductionModel} onClose={handleDutyPointDeductionClose}
                    sx={{
                        '& .MuiPaper-root': {
                            maxWidth: 'fit-content',
                            backgroundColor: colors.primary[400],
                            backgroundImage: "none !important"
                        }
                    }}>
                <DialogTitle>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <img src="/assets/images/point_deduction.png" alt="point deduction" width={150} height={150}/>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <p className='point-deduction-msg'>If you proceed <span className='highlight-msg'>1 points</span> will be deduct from your <span className='highlight-msg'>Duty Calculator </span> credits. </p>
                        <p className='point-want-to-proceed'> Do you want to proceed?</p>
                    </DialogContentText>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <Button onClick={handleDutyCalculation} className='point-deduction-yes-btn' autoFocus>
                            Yes
                        </Button>
                        <Button onClick={handleDutyPointDeductionClose} className='point-deduction-cancel-btn'>
                            Cancel
                        </Button>
                    </div>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>

            <Dialog open={openErrorModel} onClose={handleCloseErrorModel}
                    sx={{
                        '& .MuiPaper-root': {
                            backgroundColor: colors.primary[400],
                            backgroundImage: "none !important"
                        }
                    }}>
                <DialogContent>
                    <DialogContentText>
                        <p dangerouslySetInnerHTML={{__html: error}}></p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseErrorModel} className='point-deduction-cancel-btn'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={loading} onClose={() => setLoading(false)}
                    sx={{
                        '& .MuiPaper-root': {
                            backgroundColor: colors.primary[400],
                            backgroundImage: "none !important"
                        }
                    }}>
                <DialogContent>
                    <DialogContentText sx={{display: "flex", justifyContent: "center"}}>
                        <CircularProgress/>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DutyCalculator;
