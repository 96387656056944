const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

const formatNumberWithRegex = (_number, decimalPlaces = 5) => {
    try {
        if (_number.toString().includes(".")) {
            const regx = new RegExp(`(?<!\\.)\\d+(\\.\\d{0,${decimalPlaces}})?`);
            return _number.toString().match(regx)[0];
        } else {
            return _number.toString();
        }
    } catch (e) {
        console.error("formatNumberWithRegex", e);
        return _number.toString();
    }
}

const roundToNearestInteger = (value) => {
    try {
        value = value && !isNaN(value) && parseFloat(value) || 0;
    } catch (e) {
        console.error("roundToNearestInteger", e);
    }
    return formatNumberWithRegex(value);
}



const formatPrice = (amount) => {
    try {
        const roundedAmount = roundToNearestInteger(amount);
        if (isNaN(roundedAmount)) {
            throw new Error('Invalid input');
        }
        return roundedAmount.toLocaleString();
    } catch (error) {
        console.error('Error in formatPrice ', error);
        return error.message;
    }
}

const currencyConvert = (val, cynRate) => {
    try {
        const total = Math.round((roundToNearestInteger(val) / cynRate) * 100) / 100;
        return formatNumberWithRegex(total || 0);
    } catch (e) {
        console.error('Error in currencyConvert => ', e);
        return val;
    }
}

const currencyConvert2 = (val, cynRate) => {
    try {
        const total = Math.round((roundToNearestInteger(val) * cynRate) * 100) / 100;
        return formatNumberWithRegex(total || 0);
    } catch (e) {
        console.error('Error in currencyConvert => ', e);
        return val;
    }
}

const local = {
    "mel": "es-ES",
    "ceu": "es-ES",
    "afg": "prs-AF",
    "alb": "sq-AL",
    "ata": "en-US",
    "dza": "ar-DZ",
    "asm": "en-US",
    "and": "ca-AD",
    "ago": "pt-AO",
    "atg": "en-AG",
    "aze": "az-AZ",
    "arg": "es-AR",
    "aus": "en-AU",
    "aut": "de-AT",
    "bhs": "en-BS",
    "bhr": "ar-BH",
    "bgd": "bn-BD",
    "arm": "hy-AM",
    "brb": "en-BB",
    "bel": "nl-BE",
    "bmu": "en-BM",
    "btn": "dz-BT",
    "bol": "es-BO",
    "bih": "bs-BA",
    "bwa": "en-BW",
    "bra": "pt-BR",
    "blz": "en-BZ",
    "slb": "en-SB",
    "vgb": "en-VG",
    "brn": "ms-MY",
    "bgr": "bg-BG",
    "mmr": "my-MM",
    "bdi": "fr-BI",
    "blr": "be-BY",
    "khm": "km-KH",
    "cmr": "en-CM",
    "can": "en-CA",
    "cpv": "pt-CV",
    "cym": "en-KY",
    "caf": "fr-CF",
    "lka": "en-LK",
    "tcd": "fr-TD",
    "chl": "es-CL",
    "chn": "zh-CN",
    "twn": "en-TW",
    "col": "es-CO",
    "com": "ar-KM",
    "myt": "fr-YT",
    "cog": "fr-CD",
    "cod": "fr-CG",
    "cok": "en-CK",
    "cri": "es-CR",
    "hrv": "hr-HR",
    "cub": "es-CU",
    "cyp": "el-GR",
    "cze": "cs-CZ",
    "ben": "fr-BJ",
    "dnk": "da-DK",
    "dma": "en-DM",
    "dom": "es-DO",
    "ecu": "es-EC",
    "slv": "es-SV",
    "gnq": "es-GQ",
    "eth": "am-ET",
    "eri": "en-ER",
    "est": "et-EE",
    "fro": "fo-FO",
    "flk": "en-FK",
    "fin": "fi-FI",
    "fra": "fr-FR",
    "guf": "fr-GF",
    "pyf": "fr-PF",
    "dji": "ar-DJ",
    "gab": "fr-GA",
    "geo": "ka-GE",
    "gmb": "en-GM",
    "pse": "ar-PS",
    "deu": "de-DE",
    "gha": "en-GH",
    "gib": "en-GI",
    "kir": "en-KI",
    "grc": "el-GR",
    "grl": "kl-GL",
    "grd": "en-GD",
    "glp": "fr-GP",
    "gum": "en-GU",
    "gtm": "es-GT",
    "gin": "fr-GN",
    "guy": "en-GY",
    "hti": "ht-HT",
    "vat": "it-VA",
    "hnd": "es-HN",
    "hkg": "en-HK",
    "hun": "hu-HU",
    "isl": "is-IS",
    "ind": "en-IN",
    "idn": "id-ID",
    "irn": "fa-IR",
    "irq": "ar-IQ",
    "irl": "en-IE",
    "isr": "en-IL",
    "ita": "it-IT",
    "civ": "fr-CI",
    "jam": "en-JM",
    "jpn": "ja-JP",
    "kaz": "kk-KZ",
    "jor": "ar-JO",
    "ken": "en-KE",
    "prk": "ko-KP",
    "kor": "en-KR",
    "kwt": "ar-KW",
    "kgz": "ky-KG",
    "lao": "lo-LA",
    "lbn": "ar-LB",
    "lso": "en-LS",
    "lva": "lv-LV",
    "lbr": "en-LR",
    "lby": "ar-LY",
    "lie": "de-LI",
    "ltu": "lt-LT",
    "lux": "lb-LU",
    "mac": "zh-MO",
    "mdg": "mg-MG",
    "mwi": "en-MW",
    "mys": "ms-MY",
    "mdv": "dv-MV",
    "mli": "fr-ML",
    "mlt": "en-MT",
    "mtq": "fr-MQ",
    "mrt": "ar-MR",
    "mus": "en-MU",
    "mex": "es-MX",
    "mco": "fr-MC",
    "mng": "mn-MN",
    "mda": "ro-MD",
    "mne": "sr-ME",
    "msr": "en-MS",
    "mar": "ar-MA",
    "moz": "pt-MZ",
    "omn": "ar-OM",
    "nam": "en-NA",
    "nru": "en-NR",
    "npl": "ne-NP",
    "nld": "nl-NL",
    "cuw": "nl-CW",
    "abw": "nl-AW",
    "sxm": "nl-SX",
    "bes": "nl-BQ",
    "ncl": "fr-NC",
    "vut": "en-VU",
    "nzl": "en-NZ",
    "nic": "es-NI",
    "ner": "fr-NE",
    "nga": "en-NG",
    "nfk": "en-NF",
    "nor": "no-NO",
    "mnp": "en-MP",
    "umi": "en-US",
    "fsm": "en-FM",
    "mhl": "mh-MH",
    "plw": "en-PW",
    "pak": "en-PK",
    "pan": "es-PA",
    "png": "en-PG",
    "ant": "nl-AN",
    "pry": "es-PY",
    "per": "es-PE",
    "phl": "en-PH",
    "pcn": "en-PN",
    "pol": "pl-PL",
    "prt": "pt-PT",
    "gnb": "pt-GW",
    "tls": "pt-TL",
    "pri": "en-PR",
    "qat": "ar-QA",
    "reu": "fr-RE",
    "rou": "ro-RO",
    "rus": "ru-RU",
    "rwa": "en-RW",
    "blm": "fr-BL",
    "kna": "en-KN",
    "aia": "en-AI",
    "lca": "en-LC",
    "spm": "fr-PM",
    "vct": "en-VC",
    "smr": "it-SM",
    "stp": "pt-ST",
    "fji": "en-FJ",
    "sau": "ar-SA",
    "sen": "fr-SN",
    "srb": "sr-RS",
    "syc": "en-SC",
    "sle": "en-SL",
    "sgp": "en-SG",
    "svk": "sk-SK",
    "vnm": "vi-VN",
    "svn": "sl-SI",
    "som": "so-SO",
    "zaf": "en-ZA",
    "zwe": "en-ZW",
    "esp": "es-ES",
    "ssd": "en-SS",
    "sdn": "en-SD",
    "sur": "nl-SR",
    "swz": "en-SZ",
    "swe": "sv-SE",
    "che": "de-CH",
    "syr": "ar-SY",
    "tjk": "tg-TJ",
    "tha": "th-TH",
    "tgo": "fr-TG",
    "ton": "en-TO",
    "tto": "en-TT",
    "are": "en-AE",
    "tun": "ar-TN",
    "tur": "tr-TR",
    "tkm": "tk-TM",
    "tca": "en-TC",
    "tuv": "en-TV",
    "uga": "en-UG",
    "ukr": "uk-UA",
    "mkd": "mk-MK",
    "egy": "ar-EG",
    "gbr": "en-GB",
    "tza": "en-TZ",
    "usa": "en-US",
    "vir": "en-VI",
    "bfa": "fr-BF",
    "ury": "es-UY",
    "uzb": "uz-UZ",
    "ven": "es-VE",
    "wlf": "fr-WF",
    "wsm": "en-WS",
    "yem": "ar-YE",
    "zmb": "en-ZM",
    "xxk": "sq-XK"
};
const getLocal = (cyn) => local[cyn] || "us-EN";

Object.defineProperty(String.prototype, 'btrim', {
    value: function (char = '') { // Function takes an optional character to trim
        if (char === '') {
            return this.trim(); // Use built-in trim if no character provided
        }
        let start = 0;
        let end = this.length - 1;
        while (this[start] === char) {
            start++;
        }
        while (this[end] === char) {
            end--;
        }
        return this.substring(start, end + 1);
    },
    enumerable: true,
});

const splitAmount = (amount) => {
    // Split the amount at the decimal point
    const parts = amount.toString().split('.');

    // Check if there are decimal places
    if (parts.length === 1) {
        return amount; // No decimals, return the original amount
    }

    const wholePart = parts[0];
    const decimalPart = parts[1];//.padEnd(2, '0'); // Ensure two decimal places

    // Wrap the whole part with a span element
    const wholePartSpan = wholePart;

    // Wrap the decimal part with a small element within a span
    const decimalPartSpan = decimalPart;

    // Combine the formatted parts
    return wholePartSpan + "." + decimalPartSpan;

}

const igToLocale = (num, cyn = 'ind', showCurrencySymbol = null) => {
    // console.log("num",num,cyn)
    cyn = cyn || 'ind';
    let opt = {currency: cyn};
    let result = "";
    if (typeof showCurrencySymbol === 'boolean' && showCurrencySymbol) {
        opt = {style: 'currency', currency: cyn};
    }
    result = splitAmount(new Intl.NumberFormat(getLocal(cyn), opt).format(num));
    if (typeof showCurrencySymbol === 'string' && showCurrencySymbol) {
        result += " " + (showCurrencySymbol + "").toUpperCase();
    }
    // console.log("result",result)
    return result;
}

const formattedDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const monthName = monthNames[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    return `${day} ${monthName}, ${year}`;
}

function formatDateString(dateString) {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
}

const getCountryNameLabel = (value) => {
    const countries = JSON.parse(window.localStorage.getItem("saveCountryList"))
    const country = countries.find(country => country.value === value);
    return country ? country.label : null;
}

const getCountryFlagCode = (label) => {
    const countries = JSON.parse(window.localStorage.getItem("saveCountryList"))
    const country = countries.find(country => country.label === label);
    return country ? country.flag : null;
}


const toNum = (num) => {
    if (num === null || num === undefined) {
        return 0;
    } else if (typeof num === 'number') {
        return num;
    } else if (typeof num === 'string') {
        return parseFloat(num.replace(/,/g, ''));
    } else {
        return 0;
    }

}
const calculateValues = (incoTerm, productValue, payloadData) => {
    let {originCharges = 0, exwInsuranceCharges = 0, cifInsuranceCharges = 0, fobInsuranceCharges = 0,
        exwIntFreight = 0, cifIntFreight = 0, fobIntFreight = 0, originFreight = 0} = payloadData;
    let newExwValue = 0, newFobValue = 0, newCifValue = 0;

    productValue = toNum(productValue);
    originCharges = toNum(originCharges);
    exwInsuranceCharges = toNum(exwInsuranceCharges);
    cifInsuranceCharges = toNum(cifInsuranceCharges);
    fobInsuranceCharges = toNum(fobInsuranceCharges);
    exwIntFreight = toNum(exwIntFreight);
    cifIntFreight = toNum(cifIntFreight);
    fobIntFreight = toNum(fobIntFreight);
    originFreight = toNum(originFreight);

    switch (incoTerm) {
        case 'EXW':
            newExwValue = productValue;
            newFobValue = newExwValue + originCharges + originFreight;
            newCifValue = newFobValue + exwIntFreight + exwInsuranceCharges;
            break;
        case 'CIF':
            newCifValue = productValue;
            newFobValue = newCifValue - (cifIntFreight + cifInsuranceCharges);
            newExwValue = newCifValue - (originCharges + originFreight + cifIntFreight + cifInsuranceCharges);
            break;
        case 'FOB':
            newFobValue = productValue;
            newCifValue = newFobValue + (fobIntFreight + fobInsuranceCharges);
            newExwValue = newFobValue - (fobIntFreight + fobInsuranceCharges);
            break;
        default:
            break;
    }

    return {newExwValue, newFobValue, newCifValue};
};

module.exports = {
    currencyConvert: currencyConvert,
    currencyConvert2: currencyConvert2,
    formatPrice: formatPrice,
    roundToNearestInteger: roundToNearestInteger,
    formatNumberWithRegex: formatNumberWithRegex,
    igToLocale: igToLocale,
    getLocal: getLocal,
    formattedDate: formattedDate,
    formatDateString: formatDateString,
    getCountryNameLabel: getCountryNameLabel,
    getCountryFlagCode: getCountryFlagCode,
    calculateValues: calculateValues
}