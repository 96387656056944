// exports.baseUrl="http://localhost:3001/api/v1"
// exports.baseUrl="https://api.intgrow.co/api/v1"
exports.baseUrl= (process.env.REACT_APP_API_URL || "https://intgrow.co")+"/api/v1";


// {path: "/api/v1/upload-media", method: "post", handler: [ upload.single("media"),ctrl.uploadMedia]},
   
// {path: "/api/v1/uploaded-media-list", method: "get", handler: [ctrl.getMediaList]},

// {path: "/api/v1/latest-media", method: "get", handler: [ctrl.getLatestMedia]},

// {path: "/api/v1/uploaded-media/:id", method: "get", handler: [ctrl.getMediaById]},
// {path: "/api/v1/delete/uploaded-media/:id", method: "delete", handler: [ctrl.deleteMedia]},
