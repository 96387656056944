import { Box, Typography, Modal, Button, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import CountryFlag from 'react-country-flag';
import { useEffect, useState } from "react";
import { fetchDutySaverHistoryRecordDetails, fetchDutyCalcHistoryRecords } from "../../utils/Api";
import { formatDateString, getCountryNameLabel, getCountryFlagCode, igToLocale,currencyConvert } from "../../utils/utils";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DutyCalcHistoryModel from "./DutyCalcHistoryModel";
import DisclaimerStrip from "../../components/Disclaimer/DisclaimerStripe";
import RenderFormatedValue from "../dashboard/RenderFormatedValue";

const DutyCalcHistoryTable = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [modalOpen, setModalOpen] = useState(false);
    const [recordDetails, setRecordDetails] = useState(null);
    const [dutyCalcData, setDutyCalcData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [countryData, setCountryData] = useState("")

    const currencyConvertedValue = {
        color: colors.primaryColor[300],
        fontSize: "0.75rem",
        fontWeight: "600"
    }



    useEffect(() => {
        const getDutyHistory = async () => {
            const result = await fetchDutyCalcHistoryRecords(page, pageSize);

            const formattedData = result.map(attr => {
                const { response } = attr;
                const { userLog } = attr;

                const imp_cyn = response?.imp_cyn;
                const cyn = response?.cyn;

                const hs_code = response.hscode;
                const date = formatDateString(attr.iso_date);
                const id = attr.id;
                const export_country = getCountryNameLabel(response.export_country);
                const import_country = getCountryNameLabel(response.import_country);
                const value_of_product = response.CIFVALUE;
       
                const value_of_product_original = igToLocale(value_of_product || 0, imp_cyn, imp_cyn);
                const value_of_product_converted = igToLocale(currencyConvert(value_of_product,response.cyn_rate), cyn, cyn);

                const import_duty_original = igToLocale(response?.import_duty_original || 0, imp_cyn, imp_cyn);
                const import_duty_converted = igToLocale(response?.import_duty_converted || 0, cyn, cyn);

                const landed_cost_original = igToLocale(response?.landed_cost_original || 0, imp_cyn, imp_cyn);
                const landed_cost_converted = igToLocale(response?.landed_cost_converted || 0, cyn, cyn);
                const point = attr.point;
                return { hs_code, id, date, export_country, import_country, value_of_product , point, import_duty_original, import_duty_converted, landed_cost_original, landed_cost_converted, value_of_product_converted, value_of_product_original };
            });

            setDutyCalcData(formattedData);
        };
        getDutyHistory();
    }, [page, pageSize]);

    const handleViewHistoryModal = async (id, import_country, export_country) => {
        const details = await fetchDutySaverHistoryRecordDetails(id);
        setRecordDetails(details);
        setCountryData({
            imp_country: import_country,
            exp_country: export_country
        });
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setRecordDetails(null);
    };

    const renderCountryWithFlag = (params) => {
        const countryName = params.value;
        const countryCode = getCountryFlagCode(countryName);

        return (
            <Box display="flex" alignItems="center">
                <CountryFlag countryCode={countryCode} svg style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }} />
                <Typography sx={{ fontWeight: "600", fontSize: "0.75rem", lineHeight: "1.43" }}>
                    {countryName}
                </Typography>
            </Box>
        );
    };

    const renderLandedCost = (params) => {
        return (
            <Box>
                <RenderFormatedValue value={params.row.landed_cost_original} />
                <RenderFormatedValue value={params.row.landed_cost_converted} />
                {/* <Typography sx={currencyConvertedValue}>{params.row.landed_cost_original}</Typography>
                <Typography sx={currencyConvertedValue}>{params.row.landed_cost_converted}</Typography> */}
            </Box>
        );
    };

    const renderImportDuty = (params) => {
        return (
            <Box>
                  <RenderFormatedValue value={params.row.import_duty_original} />
                  <RenderFormatedValue value={params.row.import_duty_converted} />
                {/* <Typography sx={currencyConvertedValue}>{params.row.import_duty_original}</Typography>
                <Typography sx={currencyConvertedValue}>{params.row.import_duty_converted}</Typography> */}
            </Box>
        );
    };

    const renderProductValue = (params) => {
        return (
            <Box>
                <RenderFormatedValue value={params.row.value_of_product_original} />
                <RenderFormatedValue value={params.row.value_of_product_converted} />
                {/* <Typography sx={currencyConvertedValue}>{params.row.value_of_product_original}</Typography>
                <Typography sx={currencyConvertedValue}>{params.row.value_of_product_converted}</Typography> */}
            </Box>
        );
    };

    const columns = [
        { field: "date", headerName: "Date" },
        {
            field: "import_country",
            headerName: "Import Country",
            flex: 1,
            renderCell: renderCountryWithFlag,
        },
        {
            field: "export_country",
            headerName: "Export Country",
            flex: 1,
            renderCell: renderCountryWithFlag,
        },
        {
            field: "hs_code",
            headerName: "HS Code",
            flex: 1,
        },
        {
            field: "value_of_product",
            headerName: "Value Of Product",
            renderCell: renderProductValue,
            flex: 1,
        },
        {
            field: "import_duty",
            headerName: "Import Duty",
            renderCell: renderImportDuty,
            flex: 1,
        },
        {
            field: "landed_cost",
            headerName: "Landed Cost",
            renderCell: renderLandedCost,
            flex: 1,
        },
        {
            field: "point",
            headerName: "Points",
            flex: 1,
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                const { id, row } = params;
                return (
                    <div
                        className={`status-cell`}
                        onClick={() => handleViewHistoryModal(id, row.import_country, row.export_country)}
                    >
                        View
                    </div>
                );
            },
        },
    ];

    const handleNextPage = () => {
        if (dutyCalcData.length === pageSize) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <Box
            height="90%"
            width="100%"
            padding="16px"
        >
            <Typography
                sx={{
                    backgroundColor: "#1994b7",
                    padding: "10px",
                    color: "#fff",
                    borderRadius: "7px",
                    margin: "20px 0",
                    fontSize: "18px",
                }}>Duty Calculator Search History</Typography>
            <Box
                boxShadow={3}
                borderRadius={5}
                bgcolor="background.paper">
                <DataGrid
                    rows={dutyCalcData}
                    columns={columns}
                    disableSelectionOnClick
                    autoHeight
                    components={{ Toolbar: GridToolbar }}
                    sx={{
                        overflow: "auto",
                        "& .status-cell": {
                            padding: "5px",
                            borderRadius: "5px",
                            fontWeight: "bolder",
                            display: "inline-block",
                            cursor: "pointer",
                            color: colors.blueAccent[400],
                        },
                        "& .MuiDataGrid-root": {
                            border: "none",
                            boxShadow: 3,
                            borderRadius: 2,
                            minWidth: "1000px",
                        },
                        "& .MuiDataGrid-cellContent": {
                            fontWeight: "600",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "800",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.table[200],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            display: "none",
                        },
                        "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`,
                        },
                        "& .MuiDataGrid-container--top [role=row]": {
                            backgroundColor: `${colors.grey[500]} !important`,
                        },
                    }}
                />
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px">
                <IconButton onClick={handlePreviousPage} disabled={page === 1}>
                    <ArrowBackIosIcon />
                </IconButton>
                <Typography>Page {page}</Typography>
                <IconButton onClick={handleNextPage} disabled={dutyCalcData?.length < pageSize}>
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>


            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 1000,
                    height: "90vh",
                    overflow: "auto",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: "7px",
                }}>

                    {recordDetails ? (
                        <Box id="modal-description" sx={{ mt: 2 }}>
                            <DutyCalcHistoryModel product={recordDetails} countryData={countryData} />
                        </Box>
                    ) : (
                        <Typography id="modal-description" sx={{ mt: 2 }}>
                            Loading...
                        </Typography>
                    )}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={handleCloseModal}
                            variant="contained"
                            sx={{
                                backgroundColor: "var(--intgrow-bg)",
                                color: "#fff",

                                "&:hover": {
                                    backgroundColor: "var(--intgrow-bg)"
                                }
                            }}>Close</Button>
                    </Box>
                </Box>
            </Modal>

            <Box>
                <DisclaimerStrip />
            </Box>
        </Box>
    );
};

export default DutyCalcHistoryTable;
