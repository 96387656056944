import { Box, Stack, Button, SvgIcon, IconButton, Typography, useTheme, Avatar, DialogTitle, DialogActions, DialogContentText, DialogContent, Dialog, Badge } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { AccountPopover } from "../../components/Layout/AccountPopOver";
import { usePopover } from "../../hooks/usePopover";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CalculateIcon from '@mui/icons-material/Calculate';
import PersonIcon from '@mui/icons-material/Person';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useNavigate } from "react-router-dom";
import { useWallet } from "../../context/WalletsContext";
import axios from 'axios'; // Make sure axios is imported
import io from 'socket.io-client';
import { baseUrl } from "../../utils/baseURL";
import DoneIcon from '@mui/icons-material/Done';

const AdminPanelTopbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const mode = theme.palette.mode;
  const colors = tokens(mode);
  const colorMode = useContext(ColorModeContext);
  const accountPopover = usePopover();
  const [userInfo, setUserInfo] = useState({});
  const [insufficientBalanceModel, setInsufficientBalanceModel] = useState(false);
  const [notificationModel, setNotificationModel] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const { userWallets, fetchWallets } = useWallet();

  useEffect(() => {
    fetchWallets();
  }, []);

  useEffect(() => {
    if (userWallets?.hs_code <= 15 || userWallets?.duty_calc <= 5) {
      setInsufficientBalanceModel(true);
    }
  }, [userWallets]);

  useEffect(() => {
    const token = window.localStorage.getItem('access_token');
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    if (!userInfo) {
      navigate("/auth/login");
      return;
    }
    const user = {
      id: userInfo.id,
      name: userInfo?.first_name,
      email: userInfo?.email,
      role: userInfo?.role,
      photo_url: userInfo?.photo_url
    };
    setUserInfo(user);

    // const socket = io('http://localhost:3001');
    const socket = io(process.env.REACT_APP_API_URL || 'https://intgrow.co/');

    // Listen for new notifications from socket
    socket.on('newNotification', (notification) => {
      setNotifications((prev) => [...prev, notification]);
      setUnreadCount((prevCount) => prevCount + 1);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const fetchUnreadCount = async () => {
      try {
        const token = window.localStorage.getItem('access_token');
        const response = await axios.get(`${baseUrl}/get-unread-notification-count`, {
          headers: {
            'x-api-key': token
          }
        });
        setUnreadCount(response.data.unreadCount);
      } catch (error) {
        console.error('Error fetching unread notification count:', error);
      }
    };

    fetchUnreadCount();
  }, []);

  const fetchNotification = async () => {
    try {
      const token = window.localStorage.getItem('access_token');
      const response = await axios.get(`${baseUrl}/my-notifications`, {
        headers: {
          'x-api-key': token
        }
      });
      setNotifications(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleNotificationClick = () => {
    fetchNotification();
    setNotificationModel(true);
  };

  const markNotificationRead = async (notificationId) => {
    try {
      const token = window.localStorage.getItem('access_token');
      await axios.patch(`${baseUrl}/mark-notification-read`, { notificationId }, {
        headers: {
          'x-api-key': token
        }
      });
      setUnreadCount((prevCount) => prevCount - 1);
      // Optionally refetch notifications to update the list
      fetchNotification();
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" p={2} sx={{ position: "sticky", top: "0", backgroundColor: colors.themeColor[100], zIndex: "99", flexDirection: { xs: 'column', md: 'row' } }}>
        <Box>
          <Box display="flex">
            <IconButton onClick={colorMode.toggleColorMode}>
              {mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
            </IconButton>
            <IconButton onClick={handleNotificationClick}>
              <Badge badgeContent={unreadCount} color="error">
                <NotificationsOutlinedIcon />
              </Badge>
            </IconButton>
            <IconButton>
              <SettingsOutlinedIcon />
            </IconButton>
          </Box>
        </Box>

        {/* ICONS */}
        <Stack alignItems={{ xs: 'flex-start', md: 'center' }} direction={{ xs: 'row', md: 'row' }} sx={{ marginRight: '40px', cursor: 'pointer', flexDirection: { xs: 'column', md: 'row' } }}>
          <Stack direction="row" alignItems="center">
            <Avatar sx={{ cursor: 'pointer', height: 30, width: 30, background: 'none', color: `${mode === 'dark' ? "#fff" : "rgb(25, 80, 138)"}` }}>
              <SvgIcon fontSize='small'><ManageSearchIcon /></SvgIcon>
            </Avatar>
            <Typography variant="overline" sx={{ margin: '0 8px' }}>
              HS Code Points:<span style={{ marginLeft: "5px" }}>{userWallets?.hs_code}</span>
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center">
            <Avatar sx={{ color: `${mode === 'dark' ? "#fff" : "rgb(25, 80, 138)"}`, cursor: 'pointer', height: 30, width: 30, background: 'none' }}>
              <SvgIcon fontSize='small'><CalculateIcon /></SvgIcon>
            </Avatar>
            <Typography variant="overline" sx={{ margin: '0 8px' }}>
              Duty Calculation Points:<span style={{ marginLeft: "5px" }}>{userWallets?.duty_calc}</span>
            </Typography>
          </Stack>

          <Stack onClick={accountPopover.handleOpen} ref={accountPopover.anchorRef} direction="row" alignItems="center">
            <Avatar sx={{ color: `${mode === 'dark' ? "#fff" : "rgb(25, 80, 138)"}`, cursor: 'pointer', height: 30, width: 30, background: 'none' }}>
              <SvgIcon fontSize='small'><PersonIcon /></SvgIcon>
            </Avatar>

            <Typography color={mode === 'dark' ? "#fff" : "#000"} variant="overline" sx={{ margin: '0 8px', fontWeight: 900 }}>
              {userInfo?.name || "ADMIN"}
            </Typography>
          </Stack>
        </Stack>
        <AccountPopover anchorEl={accountPopover.anchorRef.current} open={accountPopover.open} onClose={accountPopover.handleClose} />
      </Box>

      <Dialog open={insufficientBalanceModel} onClose={() => setInsufficientBalanceModel(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Upgrade Your Membership"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Insufficient balance</DialogContentText>
        </DialogContent>
        <DialogActions><Button onClick={() => setInsufficientBalanceModel(false)} autoFocus>Got It</Button></DialogActions>
      </Dialog>

      <Dialog open={notificationModel} onClose={() => setNotificationModel(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"My Notification"}</DialogTitle>
        <DialogContent sx={{ minWidth: "650px" }}>
          {notifications && notifications.length > 0 ? (
            notifications.map(notification => (
              <Box key={notification.id} sx={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                <Typography variant="body1">{notification.message}</Typography>
                <Button
                  onClick={() => markNotificationRead(notification.id)}
                  endIcon={notification.isRead ? <DoneIcon style={{ color: 'green' }} /> : null}
                >
                  {notification.isRead ? <Typography variant="body1" sx={{ color: "green" }}>Read</Typography> : <Typography variant="body1">Mark as Read</Typography>}
                </Button>
              </Box>
            ))
          ) : (
            <Typography variant="body1">No notifications</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNotificationModel(false)}
                sx={{
                  backgroundColor: "var(--intgrow-bg)",
                  color:"#fff",
                  "&:hover": {
                      backgroundColor: "var(--intgrow-bg)"
                  }
              }}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminPanelTopbar;
