import React, { useState } from 'react';
import { Grid,Box, TextareaAutosize, MenuItem, FormControl, Select, Typography } from '@mui/material';
import './Support.css';
import CommonHeading from '../CommonHeading/CommonHeading';
import logo from '../../assest/Intgrow Logo Transpernt.png';
import img from '../../assest/admin_help.png';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';
import SimpleButton from '../../components/SimpleButton/SimpleButton';
import { Helmet } from 'react-helmet';
import InputField from '../../components/InputField/InputField';
import { baseUrl } from '../../utils/baseURL';

const Support = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [category, setCategory] = useState('');
    const [message, setMessage] = useState('');
    const [attachment, setAttachment] = useState("");

    const handleSupportSubmit = async () => {
        try {
            const token = window.localStorage.getItem('access_token');
            // console.log("tokken", token)
            const formData = new FormData();
            formData.append('category', category);
            formData.append('message', message);
            if (attachment) {
                formData.append('image', attachment);
            }
            const response = await fetch(`${baseUrl}/support`, {
                method: 'POST',
                headers: {
                    "x-api-key": token
                },
                body: formData,
            });

            if (response.ok) {
                alert("Your query has been submitted successfully");
                setCategory("")
                setMessage("")
                setAttachment("")
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting support request', error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Support | IntGrow Corp.</title>
            </Helmet>
            <Grid className='support-container'>
                <CommonHeading />
                <Grid className='support-banner'>
                    <Typography sx={{ color: "#ffffff" }} textAlign={"center"} variant='h4' fontSize={60} fontWeight={700}>
                        How can we Help?
                    </Typography>
                    <Typography sx={{ color: "#ffffff" }} textAlign={"center"} fontWeight={600}>
                        Submit your request. We will revert back to you with a solution.
                    </Typography>
                </Grid>
                <Box className='support-form' sx={{ backgroundColor: colors.primary[400] }}>
                    <Grid className='support-image'>
                        <Typography variant='h4' fontSize={21} fontWeight={600} sx={{ color: "#FD8406", marginBlockEnd: 1 }}>
                            Support
                        </Typography>
                        <img src={logo} width={200} alt="Intgrow Logo" />
                        <img src={img} alt="Support" />
                    </Grid>
                    <Grid className='support-field'>
                        <Typography marginBlockEnd={2} variant='h4' fontSize={25} fontWeight={700}>
                            How can we help
                        </Typography>
                        <Grid className="selectField">
                            <FormControl>
                                <Typography marginBlockEnd={0.8}>Category of support required</Typography>
                                <Select
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                >
                                    <MenuItem value="General">General</MenuItem>
                                    <MenuItem value="HS Code/Product Classification">HS Code/Product Classification</MenuItem>
                                    <MenuItem value="Duty Calculation">Duty Calculation</MenuItem>
                                    <MenuItem value="Payment/Subscription">Payment/Subscription</MenuItem>
                                    <MenuItem value="Credit balance">Credit balance</MenuItem>
                                    <MenuItem value="Others">Others</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Typography>Message</Typography>
                        <TextareaAutosize
                            minRows={6}
                            className='style-message'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <InputField
                            label={"Attachment"}
                            type={"file"}
                            onChange={(e) => setAttachment(e.target.files[0])}
                        />
                        <SimpleButton name={"Submit"} onClick={handleSupportSubmit} />
                    </Grid>
                </Box>
            </Grid>
        </>
    );
};

export default Support;
