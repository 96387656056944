import styled from '@emotion/styled';
import {
    useTheme,
    Table, TableBody, TableCell, TableContainer,
    TableRow, Typography, Paper
} from '@mui/material';
import {roundToNearestInteger, currencyConvert, currencyConvert2, getCountryNameLabel, formatPrice, igToLocale} from '../../utils/utils';
import {tokens} from '../../theme'
import FormatedCalcValue from './FormateCalcValue';


const ShipmentSummary = ({getDutyResponse, impCurrency}) => {
    const theme = useTheme()
    const thememode = theme.palette.mode
    const colors = tokens(thememode)
    let cynRate = getDutyResponse && getDutyResponse.cyn_rate;
    // let impCurrency = getDutyResponse && getDutyResponse.imp_cyn;
    // console.log("getDutyResponse", getDutyResponse)
    const {mode, cyn, CIF, CIFVALUE, CONVERTED_CIFVALUE} = getDutyResponse;
    const {inco_term, originCharges, originFreight, exwIntFreight, cifIntFreight, fobIntFreight, exwInsuranceCharges, cifInsuranceCharges, fobInsuranceCharges} = getDutyResponse?.info
    let internationalFreight = exwIntFreight || cifIntFreight || fobIntFreight || 0;
    let insuranceCharge = exwInsuranceCharges || cifInsuranceCharges || fobInsuranceCharges || 0;
    let getdutyTotal = roundToNearestInteger(getDutyResponse.total + getDutyResponse.CIFVALUE);
    // console.log("getdutyTotal", getdutyTotal)
    // console.log("cynRate", cynRate)
    let cynConvertDutyTotal = currencyConvert(getdutyTotal, cynRate);
    let totalDuty = currencyConvert(getDutyResponse.total, cynRate);

    const import_country = getCountryNameLabel(getDutyResponse?.import_country);
    const export_country = getCountryNameLabel(getDutyResponse?.export_country);

    const LeftCell = styled(TableCell)`
      font-size: 14px;
      font-weight: 600;
      width: 50%;
      color: ${colors.primaryColor[200]}
    `;

    const RightCell = styled(TableCell)`
      width: 50%;
      font-size: 14px;
      font-weight: 500;
      border-left: 1px solid #dadada;
      color: ${colors.primaryColor[200]}
    `;

    return (<TableContainer component={Paper} style={{width: 'fit-content', margin: 'auto', backgroundColor: colors.primary[400]}}>
        <Typography className='shipment-summary' align="center" gutterBottom>Your Shipment Summary</Typography>
        <Table sx={{backgroundColor: colors.primary[400]}}>
            <TableBody>
                <TableRow><LeftCell>Import Country:</LeftCell><RightCell>{import_country}</RightCell></TableRow>
                <TableRow><LeftCell>Export Country:</LeftCell><RightCell>{export_country}</RightCell></TableRow>
                <TableRow><LeftCell>Mode of Transport:</LeftCell><RightCell>{getDutyResponse?.mode}</RightCell></TableRow>
                <TableRow><LeftCell>Import HSN:</LeftCell><RightCell>{getDutyResponse?.hscode}</RightCell></TableRow>
                <TableRow><LeftCell>Currency:</LeftCell><RightCell>{cyn}</RightCell></TableRow>
                <TableRow>
                    <LeftCell>Currency Rate for 1 {cyn}:</LeftCell>
                    <RightCell><FormatedCalcValue value={cynRate} currency={impCurrency.toUpperCase()}
                                                  cssStyle={{fontWeight: '500', fontSize: '14px', fontSize2: "10px", color: colors.primaryColor[200]}}/>
                    </RightCell>
                </TableRow>
                <TableRow>
                    <LeftCell>CIF Value:</LeftCell>
                    <RightCell><FormatedCalcValue value={igToLocale(CONVERTED_CIFVALUE || CIF)} currency={cyn.toUpperCase()}
                                                  cssStyle={{fontWeight: '500', fontSize: '14px', fontSize2: "10px", color: colors.primaryColor[200]}}/>
                        {impCurrency?.toLowerCase() !== cyn?.toLowerCase() && (<>
                            <br/><FormatedCalcValue value={igToLocale(currencyConvert2(CONVERTED_CIFVALUE || CIF, cynRate))} currency={impCurrency.toUpperCase()}
                                                    cssStyle={{fontWeight: '500', fontSize: '14px', fontSize2: "10px", color: colors.primaryColor[200]}}/>
                        </>)}
                    </RightCell>
                </TableRow>
                <TableRow><LeftCell>Inco Term:</LeftCell><RightCell>{inco_term}</RightCell></TableRow>
                {originCharges && (<TableRow><LeftCell>Origin Charges:</LeftCell><RightCell>{originCharges}</RightCell></TableRow>)}
                {originFreight && (<TableRow><LeftCell>Origin Freight:</LeftCell><RightCell>{originFreight}</RightCell></TableRow>)}
                <TableRow><LeftCell>International Freight:</LeftCell><RightCell>{internationalFreight}</RightCell></TableRow>
                <TableRow><LeftCell>Insurance Charges:</LeftCell><RightCell>{insuranceCharge}</RightCell></TableRow>
                {getDutyResponse?.extras && getDutyResponse?.extras.length > 0 && (
                    getDutyResponse.extras.map(extra => (
                        <TableRow key={extra.key}>
                            <LeftCell>{extra?.lbl || extra?.key}</LeftCell>
                            <RightCell>{extra?.txt || extra?.val}</RightCell>
                        </TableRow>
                    ))
                )}
                <TableRow>
                    <LeftCell>Total Payable Duties and Taxes:</LeftCell>
                    <RightCell>
                        <FormatedCalcValue value={igToLocale(formatPrice(getDutyResponse?.total))} currency={getDutyResponse?.imp_cyn.toUpperCase()}
                                           cssStyle={{fontWeight: '500', fontSize: '14px', fontSize2: "10px", color: colors.primaryColor[200]}}/>
                        {impCurrency?.toLowerCase() !== cyn?.toLowerCase() && (<>
                            <br/><FormatedCalcValue value={igToLocale(currencyConvert(getDutyResponse?.total, cynRate), cyn)} currency={cyn.toUpperCase()}
                                                    cssStyle={{fontWeight: '500', fontSize: '14px', fontSize2: "10px", color: colors.primaryColor[200]}}/>
                        </>)}
                    </RightCell>
                </TableRow>
                <TableRow>
                    <LeftCell>Total Landed Cost:</LeftCell>
                    <RightCell><FormatedCalcValue value={igToLocale(getdutyTotal)} currency={impCurrency.toUpperCase()}
                                                  cssStyle={{fontWeight: '500', fontSize: '14px', fontSize2: "10px", color: colors.primaryColor[200]}}/>
                        {impCurrency?.toLowerCase() !== cyn?.toLowerCase() && (<>
                            <br/><FormatedCalcValue value={igToLocale(cynConvertDutyTotal)} currency={cyn.toUpperCase()}
                                                    cssStyle={{fontWeight: '500', fontSize: '14px', fontSize2: "10px", color: colors.primaryColor[200]}}/>
                        </>)}</RightCell>
                </TableRow>
                <TableRow><LeftCell>HSN Description:</LeftCell><RightCell>{getDutyResponse.des}</RightCell></TableRow>
            </TableBody>
        </Table>
    </TableContainer>);
};

export default ShipmentSummary;
