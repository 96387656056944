import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import { Button, Stack, Typography, Container, Box, Grid, IconButton, useMediaQuery, styled, Paper, Accordion, AccordionSummary, AccordionDetails, OutlinedInput, useTheme, TextField } from '@mui/material';
import Header from '../../../components/header/Header'
import logo from '../../../assest/Intgrow_logo.png'
import logoBlue from '../../../assest/logoBlue.png'
import logo_1 from '../../../assest/Intgrow_logo.png'
import heroImage from '../../../assest/LastHero.png'
import tabletImage from '../../../assest/tab.png'
import laptopImage from '../../../assest/laptop.png'
import mapLaptop from '../../../assest/mapLaptop.png'
import road from '../../../assest/road.png'
import plane from '../../../assest/laptop-world.png'
import mind from '../../../assest/mind.svg'
import bulb from '../../../assest/bulb.svg'
import hand from '../../../assest/hand.svg'
import browser from '../../../assest/browser.svg'
import { HsCodeContext } from '../../../context/HSCodeContext';
import CommonFAQAccordionSection from '../../../components/AccordionSection/CommonFAQAccordian';

import { BiUser } from "react-icons/bi";
import Footer from '../../../components/footer/Footer';
import VideoModal from '../../../components/modal/VideoModal';

import SelectCountryHome from '../../../adminPanel/Select/SelectCountryHome'
import SelectCurrency from '../../../adminPanel/Select/SelectCurrency'
import usePreventZoom from '../../../hooks/usePreventZoom';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import Subscribe from "../../../components/Subscribe/Subscribe";

const HomePage = () => {
  usePreventZoom()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate();
  const theme = useTheme();
  const { hsCodeDetails, setHsCodeDetails } = useContext(HsCodeContext);
  const downMD = useMediaQuery(theme.breakpoints.down('md'))
  const downSM = useMediaQuery(theme.breakpoints.down('sm'))
  const extraLarge = useMediaQuery("(min-width:1441px)");
  const upto1366 = useMediaQuery("(max-width:1367px)");
  const forPad = useMediaQuery("(max-width:1200px)");

  // console.log(`sm${downSM}`, `md${downMD}`, `1366${upto1366}`, `xl${extraLarge}`)
  // console.log(downSM, downMD, forPad);

  const [importingCountry, setImportingCountry] = useState("");
  const [exportingCountry, setExportingCountry] = useState("");
  const [productValue, setProductValue] = useState("")
  const [selectedCurrency, setSelectedCurrency] = useState("");


  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: false });
  const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: false });
  const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: false });
  const { ref: ref4, inView: inView4 } = useInView({ triggerOnce: false });

  const CardPaper = styled(Paper)(({ theme }) => ({
    position: 'relative',
    width: upto1366 ? '250px' : extraLarge ? '270px' : '270px',
    padding: "10px 20px",
    borderRadius: "15px",
    textAlign: 'left',
    outline: 'none',
    border: 'none',
    boxShadow: '4px 4px 12px -12px #000'
  }));

  const CardPaper2 = styled(Paper)(({ theme }) => ({
    position: 'relative',
    width: extraLarge ? '350px' : '320px',
    padding: "10px 20px",
    borderRadius: "10px",
    textAlign: 'left',
    outline: 'none',
    border: 'none',
    boxShadow: '5px 5px 20px -15px #000'
  }));


  const handleDutyCalculateRedirect = () => {
    if (!importingCountry || !exportingCountry || !selectedCurrency || !productValue) {
      return alert("All fields are required")
    }
    const isLoggedIn = window.localStorage.getItem('isAuthenticated') === 'true' && window.localStorage.getItem('access_token');
    if (!isLoggedIn) {
      localStorage.setItem("continueUrl", "/duty-calculator");
      navigate("/auth/login");
    } else {
      navigate("/duty-calculator");
    }
  }

  useEffect(() => {
    setHsCodeDetails(prev => ({
      ...prev,
      importingCountry: importingCountry,
      exportingCountry: exportingCountry,
      productValue: productValue,
      selectedCurrency: selectedCurrency
    }));
  }, [importingCountry, exportingCountry, productValue, selectedCurrency])

  return (<>
    <VideoModal open={open} handleClose={() => setOpen(false)} />
    <Helmet>
      <title>Home | IntGrow Corp.</title>
      <meta property="og:title" content="home" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="information" />
      <meta property="og:description" content="what we serve" />
      <meta property="og:image" content={logo} />
      <meta name="author" content="IntroGrownj" />
      <meta name="keywords" content="HS Code, duty calculator, duty saver " />
    </Helmet>
    <Container
      disableGutters
      maxWidth={"100%"}
      sx={{
        position: 'relative',
        // height: '100vh'
      }}>
      <Box sx={{
        position: 'fixed', top: 0, width: '100%', zIndex: 999
      }}>
        <Header
          logo={logo}
          logo_1={logoBlue}
          color={"#ffffff"}
        />
      </Box>

      <Grid
        spacing={4}
        container
        sx={{
          position: 'relative',
          backgroundImage: `url(${heroImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          // height: (downSM || downMD) ? '100%' : '100vh',
          px: '10%',
        }}>
        <Grid item sm={12} xs={12} md={12} xl={7} lg={6} sx={{ height: '90vh' }}>
          <Box sx={{ position: 'absolute', top: (downSM || downMD) ? '20%' : '30%', left: '15%', color: '#fff' }}>
            <Box mt={upto1366 ? '0' : extraLarge ? '20%' : '10%'}>
              <Typography sx={{ fontSize: downSM || downMD ? '18px' : '20px', fontWeight: 600, mb: '5%' }}>GLOBAL IMPORT</Typography>
              <Typography sx={{ fontSize: downSM || downMD ? '36px' : '48px', fontWeight: 800 }}> DUTY CALCULATOR</Typography>
              <Typography sx={{ fontSize: downSM || downMD ? '16px' : '18px', fontWeight: 400 }}>Smartly Calculate Duties and Taxes for Unbeatable Profits</Typography>
              <Stack display='flex' flexDirection={"row"} flexWrap={'wrap'} gap={3} my={'5%'} width={'100%'} ml={(downSM && downMD) ? 0 : (extraLarge ? '10%' : '10%')}>
                <Button
                  onClick={() => navigate("/auth/signup")}
                  sx={{ width: '151px', height: '43px', borderRadius: '25px', bgcolor: '#fff', border: 'none', '&:hover': { bgcolor: '#fff', border: 'none' } }}
                  variant="outlined" startIcon={<BiUser height={21} width={16} />}>
                  <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>Register</Typography>
                </Button>
                <Button
                  onClick={() => navigate("/auth/login")}
                  sx={{ width: '151px', height: '43px', borderRadius: '25px', bgcolor: '#fff', border: 'none', '&:hover': { bgcolor: '#fff', border: 'none' } }}
                  variant="outlined" startIcon={<BiUser height={21} width={16} />}>
                  <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>Login</Typography>
                </Button>
              </Stack>
              {/* <IconButton
                onClick={() => setOpen(true)}
                sx={{
                  ml: upto1366 ? '20%' : "22%",
                  color: '#fff'
                }}>
                <PlayCircleRoundedIcon sx={{ width: '100px', height: '100px', color: '#fff' }} />
              </IconButton> */}
            </Box>
          </Box>
        </Grid>
        <Grid item sm={12} xs={12} md={12} xl={5} lg={6}
          sx={{
            height: (downSM || downMD) ? '80vh' : '100vh',
            px: (downSM || downMD) ? "0px !important": (extraLarge ? '5%' : '2%'),
            py: (downSM || downMD) ? "0px !important" : "10px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            marginTop:"50px"
          }}>
          <Box
            component={'section'}
            sx={{
              width: downSM ? '95%' : upto1366 ? '70%' : extraLarge ? '90%' : '70%',
              backgroundImage: `url(${tabletImage})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: downSM || downMD ? '100% 100%' : 'contain',
              // backgroundSize: 'contain',
              // px: (downSM && downMD && forPad) ? '10%' : forPad ? '20%' : '12%',
              // py: (downSM || downMD) ? "10px" : "10px",
              height: downSM ? '100%' : upto1366 ? "100%" : extraLarge ? '90%' : '90%',
              // height: '100%',
              display: 'flex',
              justifyContent: 'center',


            }}>
            <Box sx={{
              py: (downSM || downMD) ? '10%' : '5%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              // minWidth:"300px",
              maxWidth:'300px',
              width:{
                md:'75%',
                sm:'75%'
              }
            }}>
              <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item xs={12}>
                  <Typography sx={{ color: "#fff", fontSize: '18px', fontWeight: 400 }}>Calculate</Typography>
                  <Typography sx={{ color: "#fff", fontSize: '21px', fontWeight: 800 }}>import duties & taxes</Typography>
                </Grid>
                <Grid item sx={{ position: "relative", display: "flex", flexDirection: 'column', justifyContent: 'space-evenly' }}>
                  <Grid item xs={12}>
                    <Typography sx={{ color: "#fff" }}>Select Importing Country</Typography>
                    <SelectCountryHome placeholder="Select Country" type="importing_country" setSelectedCountry={setImportingCountry} selectedCountry={importingCountry} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ color: "#fff" }}>Select Exporting Country</Typography>
                    <SelectCountryHome placeholder="Select Country" type="exporting_country" setSelectedCountry={setExportingCountry} selectedCountry={exportingCountry} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ color: "#fff" }}>Product Value</Typography>
                    <input type='number'
                      style={{
                        width: '100%',
                        height: '3em',
                        outline: 'none',
                        border: 'none',
                        borderRadius: '3px',
                        padding: '10px 5px',
                        // '&::placeholder': {
                        //   color: 'red'
                        // }
                      }}
                      placeholder='Product Value'
                      onChange={(e) => setProductValue(e.target.value)}
                      value={productValue}
                    />
                    {/* <OutlinedInput
                      sx={{

                        // '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#fff',
                        },
                        '&:hover fieldset': {
                          borderColor: '#fff',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#fff',
                        },
                        // },

                        height: '3em',
                        color: '#000',
                        width: '100%',
                        bgcolor: '#fff',
                        // border: 'none',
                        // outline: 'none',
                        // '&:hover': { bgcolor: '#fff', color: '#000', border: 'none', outline: 'none' },
                        // '&:focus': { bg: '#fff', color: '#000', border: 'none', outline: 'none' }
                      }}
                      id="outlined-adornment-product"
                      aria-describedby="outlined-product-helper-text"
                      inputProps={{
                        min: 0,
                      }}
                      name="productVal"
                      type='number'
                      placeholder='Product Value'
                      onChange={(e) => setProductValue(e.target.value)}
                      value={productValue}
                    /> */}
                  </Grid>
                  <Grid item xl={12}>
                    <Typography sx={{ color: "#fff" }}>Currency</Typography>
                    <SelectCurrency selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} imp={importingCountry?.value} />
                  </Grid>
                  <Button
                    sx={{
                      mt: '5%',
                      width: '100%', bgcolor: '#fff', color: '#000', py: '0.6rem', borderRadius: '25px',
                      '&:hover': { width: '100%', bgcolor: '#fff', color: '#000', py: '0.6rem', borderRadius: '25px' }
                    }}
                    onClick={() => handleDutyCalculateRedirect()}>Submit</Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container
        sx={{ px: '10%', height: '100%', position: 'relative', mb: upto1366 ? '1%' : 0 }}>
        <Box sx={{ position: 'absolute', bottom: '-10%', left: '-20%', width: '30rem', height: '30rem', borderRadius: '50%', bgcolor: '#0C98BC' }}></Box>
        <Box sx={{ position: 'absolute', bottom: '10%', right: '0%', width: '30rem', height: '30rem', borderRadius: '50%', bgcolor: '#0C98BC' }}></Box>
        <Box
          sx={{
            zIndex: '-1',
            position: 'absolute',
            top: '-10%',
            right: '40%',
            width: '200px',
            height: '200px',
            borderRadius: '50%',
            border: '50px solid #0C98BC',
            bgcolor: 'transparent',
            animation: 'imgAnimate10 4s infinite',
            "@keyframes imgAnimate10": {
              "0%": { top: "0px" },
              "25%": { top: "60px" },
              "50%": { top: "60px" },
              "75%": { top: "0" },
              "100%": { top: "0" }
            }
          }}></Box>
        <Box
          id="rightTop"
          sx={{
            zIndex: '-1',
            position: 'absolute',
            top: '0',
            right: '0',
            width: '128px',
            height: '128px',
            borderRadius: '50%',
            border: '30px solid #0C98BC',
            bgcolor: 'transparent',
            animation: 'imgAnimate9 4s infinite',
            "@keyframes imgAnimate9": {
              "0%": { top: "0px" },
              "25%": { top: "60px" },
              "50%": { top: "60px" },
              "75%": { top: "0" },
              "100%": { top: "0" }
            }
          }}></Box>
        <Box
          id="center"
          sx={{
            zIndex: '-1',
            position: 'absolute',
            bottom: '25%',
            right: '50%',
            width: '128px',
            height: '128px',
            borderRadius: '50%',
            border: '30px solid #0C98BC',
            bgcolor: 'transparent',
          }}></Box>

        <Grid item sm={12} xs={12} md={12} xl={6} lg={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              sx={{
                fontSize: { xs: '48px', sm: '48px', md: '68px' },
                fontWeight: 700,
                alignSelf: 'center',
                mb: '5%',
                mr: { xs: 0, sm: 0, md: '25%' },
                whiteSpace: 'nowrap'
              }}
            >
              Our Tools
            </Typography>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
              {sectionTwoCard.map((item, i) => {
                return (<CardPaper key={i}>
                  <Box
                    sx={{
                      '&:hover': {
                        bgcolor: '#0087af',
                        color: '#fff',
                      },
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      bgcolor: '#0087af',
                      color: '#fff',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '20px',
                      fontWeight: 700,
                      mb: '5%'
                    }}>
                    {(i + 1).toString().padStart(2, '0')}</Box>
                  <Box>
                    <Typography sx={{
                      fontSize: '18px',
                      fontWeight: 700
                    }}>{item.title}</Typography>
                    <Typography sx={{
                      fontSize: '14px',
                      fontWeight: 400
                    }}>{item.desc}</Typography>
                  </Box>
                </CardPaper>)
              })}
            </Box>
          </Box>
        </Grid>
        <Grid item sm={12} xs={12} md={12} xl={6} lg={6} sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: '50%',
              width: '100%',
              position: 'relative',
              backgroundImage: "url(/assest/images/laptop.png)",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              minHeight: "310px",
              // maxHeight:"350px",
              margin: "20px 0px"
            }}
          >
            {/* <img alt="" src={laptopImage} style={{ width: "120%", marginLeft: (downSM || downMD) ? 0 : '10%' }} /> */}
            {/* <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
            <video style={{ width: '100%', height: '60%', objectFit: 'fill', borderRadius: "7px" }} controls autoPlay loop muted>
              <source src={"/assets/video/intgrow_home2.mp4"} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box> */}

            <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
              <iframe
                width="100%"
                height="100%" // 100% height on small screens, 60% on medium and above
                src="https://www.youtube.com/embed/cf0hcMNPWS0?autoplay=1&mute=1&loop=1&playlist=cf0hcMNPWS0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                style={{ borderRadius: '7px' }}
              ></iframe>
            </Box>
          </Box>
        </Grid>

      </Grid>


      <Box sx={{
        bgcolor: '#0C98BC',
        mb: "5px",
        zIndex: '9',
        position: 'relative',
      }}>
        <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "5px" }}>
          <img src={logo_1} alt="" style={{ height: downSM ? '22px' : downMD ? '36px' : '50px' }} />
          <Typography sx={{
            fontSize: downSM ? '18px' : downMD ? '28px' : '48px',
            fontWeight: 700,
            fontFamily: "Orbitron, sans-serif",
            ml: 1,
            color: "#fff"
          }}>
            <span style={{ marginRight: "5px" }}>:</span> DUTY CALCULATOR<br />
          </Typography>
        </Grid>
        <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}> {/* Adjust margin-top for spacing between texts */}
          <Typography sx={{
            fontSize: downSM ? '12px' : downMD ? '14px' : '18px',
            fontWeight: 600,
            fontFamily: "Orbitron, sans-serif",
            color: "#fff"
          }}>
            Smartly Calculate Duties and Taxes for Unbeatable Profits
          </Typography>
        </Grid>
      </Box>



      <Grid id='nonish' container spacing={0} sx={{ px: forPad ? 0 : '10%', height: (downSM || downMD) ? '100%' : '100vh', position: 'relative' }}>
        <Box id="rightTop" sx={{
          position: 'absolute',
          bottom: '5%',
          left: '7%',
          width: '128px',
          height: '128px',
          borderRadius: '50%',
          border: '30px solid #0C98BC',
          bgcolor: 'transparent',
          animation: 'imgAnimate7 4s infinite',
          "@keyframes imgAnimate7": {
            "0%": { top: "0px" },
            "25%": { top: "60px" },
            "50%": { top: "60px" },
            "75%": { top: "0" },
            "100%": { top: "0" }
          }
        }}></Box>
        <Grid item sm={12} xs={12} md={4} xl={4} lg={4}
          sx={{
            display: "flex",
            flexDirection: (downMD && !downSM) ? 'row' : 'column',
            justifyContent: 'center',
            alignItems: (downSM) ? 'center' : 'flex-end',
            gap: '5%',
          }}>
          <CardPaper2>
            <Box textAlign={"center"}>
              <img alt='' src={bulb} style={{ width: '100px', height: '100px' }} />
              <Typography sx={{
                fontSize: '28px',
                fontWeight: 700,
                mb: '7%'

              }}>Smart Offerings</Typography>
              <Typography sx={{
                fontSize: '18px',
                fontWeight: 400,
                // mb: '20%'
              }}>Understand your competitors' landing costs in different countries to offer the most competitive prices to buyers, potentially increasing market share and sales..</Typography>
            </Box>
          </CardPaper2>
          <CardPaper2>
            <Box textAlign={"center"}>
              <img alt='' src={hand} style={{ width: '100px', height: '100px' }} />
              <Typography sx={{
                fontSize: '28px',
                fontWeight: 700,
                mb: '7%'

              }}>Value-Added Service</Typography>
              <Typography sx={{
                fontSize: '18px',
                fontWeight: 400,
                // mb: '20%'
              }}>Offer an additional layer of expertise by helping clients navigate complex global trade dynamics through insights into competitors' strategies.</Typography>
            </Box>
          </CardPaper2>
        </Grid>
        <Grid item sm={12} xs={12} md={4} xl={4} lg={4} rowSpacing={2}
          sx={{
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'center',
            py: "5%",
            alignItems: 'center',
            gap: '5%'
          }}>
          <CardPaper2>
            <Box textAlign={'center'}>
              <img alt='' src={browser} style={{ width: '100px', height: '100px' }} />
              <Typography sx={{
                fontSize: '28px',
                fontWeight: 700,
                mb: '7%'

              }}>Smart Sourcing</Typography>
              <Typography sx={{
                fontSize: '18px',
                fontWeight: 400,
                // mb: '20%'
              }}>Know your competitors' landing costs to negotiate better terms with suppliers, optimize procurement, and improve profitability.</Typography>
            </Box>
          </CardPaper2>
          <CardPaper2 sx={{ bgcolor: '#0088AF', color: '#fff' }}>
            <Box textAlign={"center"} >
              <Typography sx={{
                fontSize: '48px',
                fontWeight: 700
              }}>
                Key Features
              </Typography>
            </Box>
          </CardPaper2>
        </Grid>
        <Grid item sm={12} xs={12} md={4} xl={4} lg={4} sx={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-end', alignItems: (downSM || downMD) ? 'center' : 'flex-start' }}>
          <CardPaper2 sx={{ mb: '10%' }}>
            <Box textAlign={'center'}>
              <img alt='' src={mind} style={{ width: '100px', height: '100px' }} />
              <Typography sx={{
                fontSize: '28px',
                fontWeight: 700,
                mb: '7%'

              }}>Informed Decision</Typography>
              <Typography sx={{
                fontSize: '18px',
                fontWeight: 400,
                // mb: '20%'
              }}>Understand the cost breakdowns of competitors, allowing you to guide importers and exporters in making well-informed choices related to customs processes.</Typography>
            </Box>
          </CardPaper2>
        </Grid>
      </Grid>

      <Grid container sx={{ px: '10%', position: 'relative', height: (downSM || downMD) ? '50vh' : '30vh' }}>
        <Box id="center_4" sx={{
          zIndex: '-1',
          position: 'absolute',
          top: extraLarge ? '7%' : '10%',
          left: '40%',
          width: '128px',
          height: '128px',
          borderRadius: '50%',
          border: '30px solid #0C98BC',
          bgcolor: 'transparent',
          animation: 'imgAnimate5 4s infinite',
          "@keyframes imgAnimate5": {
            "0%": { top: "0px" },
            "25%": { top: "60px" },
            "50%": { top: "60px" },
            "75%": { top: "0" },
            "100%": { top: "0" }
          }
        }}></Box>
        <Box id="bottom_left_4" sx={{
          zIndex: '-1',
          position: 'absolute',
          bottom: '10%',
          left: '2%',
          width: extraLarge ? '240px' : '200px',
          height: extraLarge ? '240px' : '200px',
          borderRadius: '50%',
          border: `${extraLarge ? '60px' : '55px'} solid #0C98BC`,
          bgcolor: 'transparent',
          animation: 'imgAnimate4 4s infinite',
          "@keyframes imgAnimate4": {
            "0%": { top: "0px" },
            "25%": { top: "60px" },
            "50%": { top: "60px" },
            "75%": { top: "0" },
            "100%": { top: "0" }
          }
        }}></Box>
        {/* <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={FourMobile} alt="" style={{ width: '100%' }} />
        </Box> */}
      </Grid>

      <Grid sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={logoBlue} alt="" style={{ height: downSM ? '22px' : downMD ? '36px' : '50px' }} />
          <Typography sx={{
            fontSize: downSM ? '18px' : downMD ? '28px' : '48px', fontWeight: 700, fontFamily: "Orbitron, sans-serif"
          }}>
            : A Solution for Everyone
          </Typography>
        </Grid>

        <Grid item sx={{
          bgcolor: '#0C98BC',
          display: 'flex',
          flexDirection: (downSM || downMD) ? 'column' : 'row',
          justifyContent: 'space-evenly',
          color: '#fff',
          height: (downSM || downMD) ? '100%' : '210px',
          alignItems: 'center'
        }}>
          <Grid item sm={12} xs={12} md={12} xl={3} lg={3} textAlign={'center'}>
            <Typography
              ref={ref1}
              sx={{
                fontSize: '38px',
                fontWeight: 700
              }}
            >
              {inView1 && <CountUp start={0} end={11} duration={2} />}
            </Typography>
            <Typography sx={{
              fontSize: '19px',
              fontWeight: 700
            }}>
              Countries Tariffs, Duties, and Tax Covered
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12} md={12} xl={3} lg={3} textAlign={'center'}>
            <Typography
              ref={ref2}
              sx={{
                fontSize: '38px',
                fontWeight: 700
              }}
            >
              {inView2 && <CountUp start={0} end={24} duration={2} />}
            </Typography>
            <Typography sx={{
              fontSize: '19px',
              fontWeight: 700
            }}>
              Hours Update Frequency
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12} md={12} xl={3} lg={3} textAlign={'center'}>
            <Typography
              ref={ref3}
              sx={{
                fontSize: '38px',
                fontWeight: 700
              }}
            >
              {inView3 && <CountUp start={0} end={250} duration={2} />}
            </Typography>
            <Typography sx={{
              fontSize: '19px',
              fontWeight: 700
            }}>
              FTA/PTA
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12} md={12} xl={3} lg={3} textAlign={'center'}>
            <Typography
              ref={ref4}
              sx={{
                fontSize: '38px',
                fontWeight: 700
              }}
            >
              {inView4 && <CountUp start={0} end={11000} duration={2} />}
            </Typography>
            <Typography sx={{
              fontSize: '19px',
              fontWeight: 700
            }}>
              Products HSN Covered
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid sx={{ display: 'flex', flexDirection: 'column', px: '10%' }}>
        <Grid spacing={1} container sx={{
          display: 'flex', alignItems: 'center', height: downSM || downMD ? '100%' : '60vh', position: 'relative',
          py: downSM || downMD ? '10%' : 0
        }}>
          < Box id="left_5" sx={{
            position: 'absolute',
            top: '-15%',
            left: '-2%',
            width: '128px',
            height: '128px',
            borderRadius: '50%',
            border: '30px solid #0C98BC',
            bgcolor: 'transparent',
            zIndex: -1,
            animation: 'imgAnimate3 4s infinite',
            "@keyframes imgAnimate3": {
              "0%": { top: "0px" },
              "25%": { top: "20px" },
              "50%": { top: "20px" },
              "75%": { top: "0" },
              "100%": { top: "0" }
            }
          }}></Box>
          <Grid item sm={12} xs={12} md={8} lg={8} xl={8}>
            <Typography sx={{ fontSize: '21px', fontWeight: 400 }}>ARE YOU EXPORTING OR IMORTING?</Typography>
            <Typography sx={{ fontSize: '31px', fontWeight: 700, }}>Looking for new markets for your products?</Typography>
            <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>Make data-driven decisions when venturing into markets.</Typography>
            <ul style={{ fontSize: '16px', fontWeight: 400 }}>
              <li>Identify markets with lower taxation to reduce investment burdens and explore new
                opportunities.</li>
              <li>As a Seller, plan global shipments with our cost calculator, revealing potential hidden expenses for
                recipients upon delivery.</li>
              <li>As a buyer, import confidently with our cost calculator, ensuring transparency by revealing
                potential hidden duties and taxes upon delivery.</li>
            </ul>
          </Grid>
          <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: '100%', position: 'relative' }}>
              <img alt="" src={mapLaptop} style={{ width: "100%" }} />
            </Box>
          </Grid>
          <Box id="right_7"
            sx={{
              position: 'absolute',
              bottom: '-10%',
              right: '-2%',
              width: '128px',
              height: '128px',
              borderRadius: '50%',
              border: '30px solid #0C98BC',
              bgcolor: 'transparent',
              animation: 'imgAnimate1 4s infinite',
              zIndex: -1,
              "@keyframes imgAnimate1": {
                "0%": { top: "0px" },
                "25%": { top: "60px" },
                "50%": { top: "60px" },
                "75%": { top: "0" },
                "100%": { top: "0" }
              }
            }}></Box>
        </Grid>
        <Grid spacing={1}
          container
          sx={{
            display: 'flex',
            flexDirection: downSM || downMD ? 'row-reverse' : 'row',
            alignItems: 'center',
            height: downSM || downMD ? '100%' : '60vh',
            position: 'relative'
          }}>
          <Box id="left_6" sx={{
            position: 'absolute',
            bottom: '-10%',
            left: '-2%',
            width: '128px',
            height: '128px',
            borderRadius: '50%',
            border: '30px solid #0C98BC',
            bgcolor: 'transparent',
            animation: 'imgAnimate2 4s infinite',
            "@keyframes imgAnimate2": {
              "0%": { top: "0px" },
              "25%": { top: "60px" },
              "50%": { top: "60px" },
              "75%": { top: "0" },
              "100%": { top: "0" }
            }
          }}></Box>
          <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: '100%', position: 'relative' }}>
              <img alt="" src={road} style={{ width: "100%" }} />
            </Box>
          </Grid>
          <Grid item sm={12} xs={12} md={8} lg={8} xl={8}>
            <Typography sx={{ fontSize: '21px', fontWeight: 400 }}>FINDING NEW BUSINESS PARTNER?</Typography>
            <Typography sx={{ fontSize: '31px', fontWeight: 700, }}>Are you not getting new customers?</Typography>
            <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>Buyers wants efficient sourcing, less investment & doorstep delivery.</Typography>
            <ul style={{ fontSize: '16px', fontWeight: 400 }}>
              <li>Calculate landing cost of products upto destination and connect new buyers.</li>
              <li>Offer competitive prices to buyer by considering all duties and charges with accurate landed cost
                calculations.</li>
              <li>Understand competitors' landing costs to strengthen negotiations, procurement, and boost
                profitability.</li>
            </ul>
          </Grid>
        </Grid>
        <Grid spacing={1} container sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <Grid item sm={12} xs={12} md={6} lg={6} xl={6} sx={{ pr: '2%', height: '100%' }}>
            <Typography sx={{ fontSize: '21px', fontWeight: 400 }}>SELLING TO THE REST OF THE WORLD</Typography>
            <Typography sx={{ fontSize: '31px', fontWeight: 700, }}>Are you selling to customers World-wide?</Typography>
            <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>Break free from the shackles of additional compliance, duties, and cross-border tariffs! Tackle the tricky
              bits of international customs duties and import taxes with intgrow by your side. We've got your back,
              making global trade smoother for you.</Typography>
            <ul style={{ fontSize: '16px', fontWeight: 400 }}>
              <li>Correctly categorize your items and figure out taxes in advance by using automated HS code
                classification. It's like having a handy tool to make sure you get things right.</li>
              <li>Enhance your customers' experience and reduce surprises by displaying customs duties at the time
                of purchase. Provide a smoother and transparent tax information.</li>
              <li>Make smarter decisions with a single landing cost calculator that works seamlessly across different
                countries.</li>
            </ul>
          </Grid>
          <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: '100%', position: 'relative' }}>
              <img alt="" src={plane} style={{ width: "100%" }} />
            </Box>
          </Grid>
        </Grid>
      </Grid >

        <Subscribe />

      <Grid container sx={{ px: '10%', display: 'flex', alignItems: 'center', py: downSM ? '10%' : 0 }}>
        <Box sx={{ width: '100%' }}>
          <CommonFAQAccordionSection section="Home" />
        </Box>
      </Grid>

      <Box>
        <Footer />
      </Box>
    </Container >
  </>
  )
}

export default HomePage;

const sectionTwoCard = [
  {
    title: 'HS Code Finder',
    desc: 'Identify the correct HS code for your products in minutes. Compare exporting and importing countries national HS Codes'
  },
  {
    title: 'Duty Calculator',
    desc: 'Quickly and easily calculate the landingcost of your products in any market i.e Customs, Local taxes VAT, GST etc'
  },
  {
    title: 'DutySaver Pro',
    desc: 'Maximize your profits by taking advantage of Free Trade Agreements (FTAs) and Preferential Trade Agreements(PTAs). ie any other Tax exemption ordinances'
  },
]