import React, { useState, useContext, useEffect } from 'react';
import { tokens } from '../../theme';
import { useTheme, styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { HsCodeContext } from '../../context/HSCodeContext';
import './hscode.style.css';
import CountrySelect from '../Select/SelectCountry';
import HSCodeSelector from './HSCodeSelector';
import HSResultTable from './HSResultTable';
import { baseUrl } from '../../utils/baseURL';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    maxWidth: '30%',
  },
});

const HSCodeClassifier = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { hsCodeDetails, setHsCodeDetails } = useContext(HsCodeContext);

  const [isHSCodeTreeOpen, setIsHSCodeTreeOpen] = useState(false)
  const [isHSCodeResult, setIsHSCodeResult] = useState(false)
  const [importingCountry, setImportingCountry] = useState("");
  const [exportingCountry, setExportingCountry] = useState("");
  const [hscodeElm, setHscodeElm] = useState("");
  const [hsCodeError, setHsCodeError] = useState(false)
  const [error, setError] = useState("")
  const [hsCodeResult, setHSCodeResult] = useState([])
  const [selectedHSCode, setSelectedHSCode] = useState("")
  const [open, setOpen] = useState(false);

  const [selectedHSCodeLocal, setSelectedHSCodeLocal] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const styles = {
    container: {
      backgroundColor: colors.primary[400],
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      margin: '0 auto',
    },
    heading: {
      fontSize: '18px',
      display: "flex",
      alignIitems: "center",
    },
    section: {
      marginBottom: '20px'
    },
    label: {
      marginBottom: '5px',
      display: "flex",
      alignItems: "center",
      gap: "5px",
      padding: "10px 0"
    },

    getResultBtnSection: {
      display: "flex",
      justifyContent: "center"
    },
  };


  function createData(id, HSCode, Description) {
    return { id, HSCode, Description };
  }

  const getHSCodeResult = async () => {
    try {
      let error = "";
      if (!importingCountry) {
        error = "Select importing country first";
      } else if (!exportingCountry) {
        error = "Select exporting country first";
      } else if (!hscodeElm) {
        error = "Select HS code first";
      }

      if (error) {
        setError(error);
        setHsCodeError(true);
        return;
      }

      const response = await fetch(`${baseUrl}/hs_code/details?hs=${hscodeElm}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      }
      );
      const data = await response.json();

      if (!response.ok) {
        // console.log('Error in fetch hscode');
        throw new Error('No Relevant Matches Found; Please Provide Refine Text for Better Results.."');
      }

      setHsCodeError(false);
      setError("");
      const filterData = data?.map((h, index) => {
        const [HSCode, Description] = h.hs6.split(' - ');
        return createData(index + 1, HSCode, Description);
      }) || [];
      setHSCodeResult(filterData);
      // console.log("total data", data.length);
      setIsHSCodeResult(true);
      return data;
    } catch (error) {
      console.error('An error occurred:', error);
      setHsCodeError(true);
      setError(error.message || 'An error occurred');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();   
        getHSCodeResult(); 
    }
};
  const handleSelectClick = (row) => {   
    setSelectedHSCodeLocal(row.HSCode);
    setSelectedRowId(row.id);
    setOpen(true);
  };

  useEffect(() => {
    // console.log("selectedHSCode",selectedHSCode)
    setHsCodeDetails({
      importingCountry: importingCountry,
      exportingCountry: exportingCountry,
      hsCode:selectedHSCode
    });
  }, [importingCountry, exportingCountry, hscodeElm,selectedHSCode])

  
  return (

    <>
      {
        !isHSCodeTreeOpen ?
          <div style={styles.container}>
            <h1 style={styles.heading}>Classify products accurately and efficiently according to the HS code system.
              <Tooltip
                sx={{ maxWidth: "30%" }}
                title="Use our HSN database to find the correct HS Code for your product. Simply provide us the product name, and we will furnish you with the country-specific HS description
										for both the exporting and importing countries. This information is valuable for sellers and buyers
										alike, as it enables them to classify the actual product accurately based on their country-specific
										HS code. By doing so, they can avoid any hidden challenges and issues during Customs clearances. If
										you cannot find your product in the database, please don't hesitate to contact us for assistance.">
                <InfoIcon />
              </Tooltip>
            </h1>
            <div style={styles.section}>
              <label style={styles.label}>
                <span><ArrowCircleRightIcon /></span>
                Shipment Details
              </label>
              <div className='hs-divider'></div>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <label style={styles.label}>Select Importing Country</label>
                  <CountrySelect placeholder="Select Country" type="importing_country" setSelectedCountry={setImportingCountry} selectedCountry={importingCountry} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label style={styles.label}>Select Exporting Country</label>
                  <CountrySelect placeholder="Select Country" type="exporting_country" setSelectedCountry={setExportingCountry} selectedCountry={exportingCountry} selectedImp={importingCountry} />
                </Grid>
              </Grid>
            </div>
            <div style={styles.section}>
              <label style={styles.label}>
                <span><ArrowCircleRightIcon /></span>
                Product Details
              </label>
              <div className='hs-divider'></div>
              <input className='searchHsCodeinput'
                type="text"
                placeholder="Please provide the 2, 4 or 6-digit HS Code or type the product name..."
                value={hscodeElm}
                onChange={(e) => setHscodeElm(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div style={styles.getResultBtnSection}>
              <button className='button' onClick={getHSCodeResult}>Get Result</button>
            </div>
            {hsCodeError &&
              <p className="errorMsg">{error}</p>}

            {isHSCodeResult && <section className='hs-result-table'>
              <HSResultTable handleSelectClick={handleSelectClick} selectedHSCodeLocal={selectedHSCodeLocal} open={open} setOpen={setOpen} selectedRowId={selectedRowId} data={hsCodeResult} setIsHSCodeTreeOpen={setIsHSCodeTreeOpen} setIsHSCodeResult={setIsHSCodeResult} setSelectedHSCode={setSelectedHSCode} />
            </section>}
          </div> :
          <>
            <HSCodeSelector setIsHSCodeTreeOpen={setIsHSCodeTreeOpen} ImportingCountry={importingCountry} ExportingCountry={exportingCountry} hscode={selectedHSCode} />
          </>
      }

    </>
  );
};

export default HSCodeClassifier;
