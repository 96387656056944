import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {
    Table, useTheme, TableBody, TableCell, TableContainer,
    TableRow, Typography, Paper
} from '@mui/material';
import {roundToNearestInteger, currencyConvert, currencyConvert2, igToLocale, formatPrice} from '../../utils/utils';
import FormatedCalcValue from '../DutyCalculator/FormateCalcValue';
import {tokens} from '../../theme'


const DutyCalcShipmentSummary = ({countryData, product}) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)


    const LeftCell = styled(TableCell)`
      font-size: 14px;
      font-weight: 600;
      width: 50%;
      color: ${colors.primaryColor[200]}
    `;

    const RightCell = styled(TableCell)`
      width: 50%;
      font-size: 14px;
      font-weight: 500;
      border-left: 1px solid #dadada;
      color: ${colors.primaryColor[200]}
    `;

    const {exp_country, imp_country} = countryData;
    // console.log("product", product);
    const {cyn_rate, cyn, CIF, CIFVALUE, imp_cyn, CONVERTED_CIFVALUE} = product;
    let cynRate = cyn_rate && cyn_rate;

    const {inco_term, originCharges, originFreight, exwIntFreight, cifIntFreight, fobIntFreight, exwInsuranceCharges, cifInsuranceCharges, fobInsuranceCharges} = product?.info
    let internationalFreight = exwIntFreight || cifIntFreight || fobIntFreight || 0;
    let insuranceCharge = exwInsuranceCharges || cifInsuranceCharges || fobInsuranceCharges || 0;
    let getdutyTotal = roundToNearestInteger(product.total + product.CIFVALUE);
    let cynConvertDutyTotal = currencyConvert(getdutyTotal, cynRate);
    let totalDuty = currencyConvert(product.total, cynRate);

    return (
        <TableContainer component={Paper} style={{width: 'fit-content', margin: 'auto'}}>
            <Typography className='shipment-summary' align="center" gutterBottom>
                Your Shipment Summary
            </Typography>
            <Table>
                <TableBody>
                    <TableRow><LeftCell>Import country:</LeftCell><RightCell>{imp_country}</RightCell></TableRow>
                    <TableRow><LeftCell>Export Country:</LeftCell><RightCell>{exp_country}</RightCell></TableRow>
                    <TableRow><LeftCell>Mode of transport:</LeftCell><RightCell>{product?.mode}</RightCell></TableRow>
                    <TableRow><LeftCell>Import HSN:</LeftCell><RightCell>{product?.hscode}</RightCell></TableRow>
                    <TableRow><LeftCell>Currency:</LeftCell><RightCell>{cyn}</RightCell></TableRow>
                    <TableRow>
                        <LeftCell>Currency rate for 1 {cyn}:</LeftCell>
                        <RightCell><FormatedCalcValue value={cynRate} currency={imp_cyn.toUpperCase()} cssStyle={{fontWeight: '500', fontSize: '14px', fontSize2: "10px", color: colors.primaryColor[200]}}/></RightCell>
                    </TableRow>
                    <TableRow>
                        <LeftCell>CIF Value:</LeftCell>
                        <RightCell>
                            {/* {CIF} {cyn} */}
                            <FormatedCalcValue value={igToLocale(CONVERTED_CIFVALUE || CIF)} currency={cyn.toUpperCase()} cssStyle={{fontWeight: '500', fontSize: '14px', fontSize2: "10px", color: colors.primaryColor[200]}}/>
                            {imp_cyn?.toLowerCase() !== cyn?.toLowerCase() && (<>
                                <br/>
                                <FormatedCalcValue value={igToLocale(currencyConvert2(CONVERTED_CIFVALUE || CIF, cynRate))} currency={imp_cyn.toUpperCase()}
                                                   cssStyle={{fontWeight: '500', fontSize: '14px', fontSize2: "10px", color: colors.primaryColor[200]}}
                                />
                            </>)}
                        </RightCell>
                    </TableRow>
                    <TableRow> <LeftCell>Inco Term:</LeftCell> <RightCell>{inco_term}</RightCell> </TableRow>
                    {(originCharges !== null && originCharges !== undefined && originCharges > -1) && (
                        <TableRow> <LeftCell>Origin Charges:</LeftCell> <RightCell>{originCharges}</RightCell> </TableRow>
                    )}
                    {(originFreight !== null && originFreight !== undefined && originFreight > -1) && (
                        <TableRow> <LeftCell>Origin Freight:</LeftCell> <RightCell>{originFreight}</RightCell> </TableRow>
                    )}
                    <TableRow> <LeftCell>International Freight:</LeftCell> <RightCell>{internationalFreight}</RightCell> </TableRow>
                    <TableRow> <LeftCell>Insurance Charges:</LeftCell> <RightCell>{insuranceCharge}</RightCell> </TableRow>
                    {product?.extras && product?.extras.length > 0 && (
                        product.extras.map(extra => (
                            <TableRow key={extra.key}> <LeftCell>{extra?.lbl || extra?.key}</LeftCell> <RightCell>{extra?.txt || extra?.val}</RightCell> </TableRow>
                        ))
                    )}
                    <TableRow>
                        <LeftCell>Total payable duties and taxes:</LeftCell>
                        <RightCell>
                            {/* {formatPrice(product?.total)} {(product?.imp_cyn.toUpperCase())} */}
                            <FormatedCalcValue value={igToLocale(formatPrice(product?.total))} currency={imp_cyn.toUpperCase()}
                                               cssStyle={{fontWeight: '500', fontSize: '14px', fontSize2: "10px", color: colors.primaryColor[200]}}
                            />
                            {imp_cyn?.toLowerCase() !== cyn?.toLowerCase() && (<>
                                    <br/>
                                    <FormatedCalcValue value={igToLocale(currencyConvert(product?.total, cynRate), cyn)} currency={cyn.toUpperCase()}
                                                       cssStyle={{fontWeight: '500', fontSize: '14px', fontSize2: "10px", color: colors.primaryColor[200]}}
                                    />
                                </>
                            )}
                        </RightCell>
                    </TableRow>
                    <TableRow>
                        <LeftCell>Total Landed cost:</LeftCell>
                        <RightCell>
                            <FormatedCalcValue value={igToLocale(getdutyTotal)} currency={imp_cyn.toUpperCase()}
                                               cssStyle={{fontWeight: '500', fontSize: '14px', fontSize2: "10px", color: colors.primaryColor[200]}}
                            />
                            {imp_cyn?.toLowerCase() !== cyn?.toLowerCase() && (
                                <>
                                    <br/>
                                    <FormatedCalcValue value={igToLocale(cynConvertDutyTotal)} currency={cyn.toUpperCase()}
                                                       cssStyle={{fontWeight: '500', fontSize: '14px', fontSize2: "10px", color: colors.primaryColor[200]}}
                                    />
                                </>
                            )}
                        </RightCell>
                    </TableRow>
                    <TableRow> <LeftCell>HSN Description:</LeftCell> <RightCell>{product.des}</RightCell> </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DutyCalcShipmentSummary;
