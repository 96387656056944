import { useEffect, useState } from 'react';
import { Typography, Button, Box, Stack, CircularProgress, Paper, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import { baseUrl } from '../../utils/baseURL';

// Use `styled` from `@mui/system` for styling
const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
});

const EmailVerificationPage = () => {
    const navigate = useNavigate()
    const { token } = useParams()
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('loading');
    const [message, setMessage] = useState()

    useEffect(() => {
        if (token) {
            const verifyEmail = async () => {
                try {
                    setLoading(true);
                    const response = await fetch(`${baseUrl}/auth/verify/${token}`, {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    const responseData = await response.json();
                    // console.log(response)
                    // console.log("ok",response.ok)
                    // console.log("ok",response.ok===true)
                    if (response.ok) {
                        window.localStorage.setItem('isEmailVerified', responseData.user.isEmailVerified);
                        window.localStorage.setItem('showNavbar', true);
                        window.localStorage.setItem('authenticated', 'true');
                        window.localStorage.setItem("isAuthenticated", true)
                        window.localStorage.setItem('access_token', responseData.data.token);
                        setStatus('success');
                        setLoading(false)
                    }
           
                } catch (error) {
                    setStatus('error');
                    setLoading(false)
                }
            };

            verifyEmail();
        }

    }, [token]);

    return (
        <Container>
            {status === 'loading' && <CircularProgress />}
            {status === 'success' && (
                <>
                    <Paper>
                        <Typography variant="h4" color="success.main">
                            Email Verified Successfully!
                        </Typography>
                        <Button variant="contained" color="primary" onClick={() => navigate('/auth/login')}>
                            Continue to Login
                        </Button>
                    </Paper>
                </>
            )}
            {status === 'error' && (
                <>
                    <Stack sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Paper sx={{ border: "1px solid", padding: "50px" }}>
                            <Typography variant="h4" color="error.main">
                                {message.message}
                            </Typography>
                            <Button variant="contained" color="primary" onClick={() => navigate('/auth/login')}>
                                Back to login
                            </Button>
                        </Paper>
                    </Stack>
                </>
            )}
        </Container>
    );
};

export default EmailVerificationPage;
