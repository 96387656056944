import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    TextField, Button, Grid, Container, Paper, Typography, Select, MenuItem, InputLabel,
    FormControl,
} from '@mui/material';
import { baseUrl } from '../../../utils/baseURL';
import { useParams } from 'react-router-dom';

const initialValues = {
    name: '',
    short_name: '',
    title: '',
    type: '',
    url: '',
    description: '',
    keywords: '',
    tags: '',
    img_url: '',
    img_type: '',
    img_alt: '',
    video: '',
    related_blogs: [],
    content: '',
    image: null,
};

const UpdateBlog = () => {
    const { blogId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [blogDetails, setBlogDetails] = useState(initialValues);
    const [content, setContent] = useState('');
    const editorRef = useRef(null);

    const fetchBlogDetails = async () => {
        const token = window.localStorage.getItem('access_token');
        try {
            const response = await fetch(`${baseUrl}/blog/individual/${blogId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': token
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setBlogDetails({
                name: data.name,
                short_name: data.short_name,
                title: data.title,
                type: data.type,
                url: data.url,
                description: data.description,
                keywords: data.keywords,
                tags: data.tags,
                img_url: data.img_url,
                img_type: data.img_type,
                img_alt: data.img_alt,
                video: data.video,
                related_blogs: data.related_blogs ? data.related_blogs.split(',') : [],
                content: data.content,
                image: data.image,
            });
            setContent(data.content);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBlogDetails();
    }, []);


    // useEffect(() => {
    //     const loadSCEEditor = () => {
    //         if (window.sceditor) {
    //             // Initialize the editor
    //             const editor = window.sceditor.create(editorRef.current, {
    //                 format: 'bbcode',
    //                 style: 'https://cdn.jsdelivr.net/npm/sceditor@latest/minified/themes/content/default.min.css',
    //             });

    //             if (editor) {
    //                 // Set initial content
    //                 editor.val(content);

    //                 // Handle content change
    //                 editor.bind('valuechanged', () => {
    //                     setContent(editor.val());
    //                 });
    //             } else {
    //                 console.error('SCEditor instance creation failed.');
    //             }
    //         } else {
    //             console.error('SCEditor is not defined');
    //         }
    //     };

    //     // Load SCEditor if it's not already loaded
    //     if (!window.sceditor) {
    //         const script = document.createElement('script');
    //         script.src = 'https://cdn.jsdelivr.net/npm/sceditor@latest/minified/sceditor.min.js';
    //         script.onload = () => {
    //             // Load additional formats after SCEditor is loaded
    //             const bbcodeScript = document.createElement('script');
    //             bbcodeScript.src = 'https://cdn.jsdelivr.net/npm/sceditor@latest/minified/formats/bbcode.js';
    //             bbcodeScript.onload = () => {
    //                 const xhtmlScript = document.createElement('script');
    //                 xhtmlScript.src = 'https://cdn.jsdelivr.net/npm/sceditor@latest/minified/formats/xhtml.js';
    //                 xhtmlScript.onload = loadSCEEditor;
    //                 document.body.appendChild(xhtmlScript);
    //             };
    //             document.body.appendChild(bbcodeScript);
    //         };
    //         document.body.appendChild(script);
    //     } else {
    //         loadSCEEditor();
    //     }

    //     // Cleanup on unmount
    //     return () => {
    //         if (editorRef.current && window.sceditor) {
    //             const editorInstance = window.sceditor.instance(editorRef.current);
    //             if (editorInstance) {
    //                 editorInstance.destroy();
    //             }
    //         }
    //     };
    // }, [content]);

    const validationSchema = Yup.object().shape({
        related_blogs: Yup.array().required('Related Blogs are required'),
        name: Yup.string().required('Name is required'),
        short_name: Yup.string().required('Short name is required'),
        title: Yup.string().required('Title is required'),
        type: Yup.string().required('Type is required'),
        description: Yup.string().required('Description is required'),
        image: Yup.mixed().required('Image is required'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        // console.log("content", content);
        const token = window.localStorage.getItem('access_token');
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('short_name', values.short_name);
        formData.append('title', values.title);
        formData.append('type', values.type);
        formData.append('url', values.url);
        formData.append('description', values.description);
        formData.append('keywords', values.keywords);
        formData.append('tags', values.tags);
        formData.append('img_url', values.img_url);
        formData.append('img_type', values.img_type);
        formData.append('img_alt', values.img_alt);
        formData.append('video', values.video);
        formData.append('related_blogs', values.related_blogs);
        formData.append('content', content);
        formData.append('image', values.image);

        try {
            const response = await fetch(`${baseUrl}/blog/update/${blogId}`, {
                method: 'POST',
                headers: {
                    "x-api-key":token
                },
                body: formData,
            });

            if (response.ok) {
                toast.success('Blog updated successfully');
            } else {
                toast.error('Failed to update blog.');
            }
        } catch (error) {
            toast.error('Failed to update blog.');
        }

        setSubmitting(false);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <Container maxWidth="md">
            <ToastContainer />
            <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Update Blog
                </Typography>
                <Formik
                    initialValues={blogDetails}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        name="name"
                                        variant="outlined"
                                        value={blogDetails.name}
                                        onChange={(e) => setFieldValue('name', e.target.value)}
                                    />
                                    <ErrorMessage name="name" component="div" />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Short Name"
                                        name="short_name"
                                        variant="outlined"
                                        value={blogDetails.short_name}
                                        onChange={(e) => setFieldValue('short_name', e.target.value)}
                                    />
                                    <ErrorMessage name="short_name" component="div" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Title"
                                        name="title"
                                        variant="outlined"
                                        value={blogDetails.title}
                                        onChange={(e) => setFieldValue('title', e.target.value)}
                                    />
                                    <ErrorMessage name="title" component="div" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Type"
                                        name="type"
                                        variant="outlined"
                                        value={blogDetails.type}
                                        onChange={(e) => setFieldValue('type', e.target.value)}
                                    />
                                    <ErrorMessage name="type" component="div" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="URL"
                                        name="url"
                                        variant="outlined"
                                        value={blogDetails.url}
                                        onChange={(e) => setFieldValue('url', e.target.value)}
                                    />
                                    <ErrorMessage name="url" component="div" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Description"
                                        name="description"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        value={blogDetails.description}
                                        onChange={(e) => setFieldValue('description', e.target.value)}
                                    />
                                    <ErrorMessage name="description" component="div" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Keywords"
                                        name="keywords"
                                        variant="outlined"
                                        value={blogDetails.keywords}
                                        onChange={(e) => setFieldValue('keywords', e.target.value)}
                                    />
                                    <ErrorMessage name="keywords" component="div" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Tags"
                                        name="tags"
                                        variant="outlined"
                                        value={blogDetails.tags}
                                        onChange={(e) => setFieldValue('tags', e.target.value)}
                                    />
                                    <ErrorMessage name="tags" component="div" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Image URL"
                                        name="img_url"
                                        variant="outlined"
                                        value={blogDetails.img_url}
                                        onChange={(e) => setFieldValue('img_url', e.target.value)}
                                    />
                                    <ErrorMessage name="img_url" component="div" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Image Type"
                                        name="img_type"
                                        variant="outlined"
                                        value={blogDetails.img_type}
                                        onChange={(e) => setFieldValue('img_type', e.target.value)}
                                    />
                                    <ErrorMessage name="img_type" component="div" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Image Alt Text"
                                        name="img_alt"
                                        variant="outlined"
                                        value={blogDetails.img_alt}
                                        onChange={(e) => setFieldValue('img_alt', e.target.value)}
                                    />
                                    <ErrorMessage name="img_alt" component="div" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Video"
                                        name="video"
                                        variant="outlined"
                                        value={blogDetails.video}
                                        onChange={(e) => setFieldValue('video', e.target.value)}
                                    />
                                    <ErrorMessage name="video" component="div" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Related Blogs"
                                        name="related_blogs"
                                        variant="outlined"
                                        value={blogDetails.related_blogs.join(',')}
                                        onChange={(e) => setFieldValue('related_blogs', e.target.value.split(','))}
                                    />
                                    <ErrorMessage name="related_blogs" component="div" />
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Content"
                                        name="content"
                                        variant="outlined"
                                        multiline
                                        rows={6}
                                        value={content}
                                        onChange={(e) => setContent(e.target.value)}
                                    />
                                    <ErrorMessage name="content" component="div" />
                                </Grid> */}

                                <Grid item xs={12}>
                                    <textarea
                                        ref={editorRef}
                                        name="content"
                                        style={{ display: 'none' }}
                                    />
                                    <ErrorMessage name="content" component="div" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" component="label">
                                        Upload Image
                                        <input
                                            type="file"
                                            hidden
                                            onChange={(e) => setFieldValue('image', e.currentTarget.files[0])}
                                        />
                                    </Button>
                                    <ErrorMessage name="image" component="div" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                    >
                                        Update Blog
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Paper>
        </Container>
    );
};

export default UpdateBlog;
